import { createRouter, createWebHistory } from 'vue-router'
import PageLayout from '@/views/PageLayout.vue'
import PageLayout1 from '@/views/PageLayout1.vue'
import HomePage from '@/views/page/HomePage.vue'
// import MarketPage from '@/views/page/MarketPage.vue'
import SpotTrade from '@/views/page/SpotTrade.vue'
import LoginLayout from '@/views/LoginLayout.vue'
import LoginPage from '@/views/page/LoginPage.vue'
import RegisterPage from '@/views/page/RegisterPage.vue'

import { getUserInfo } from '@/apis'
import { message } from 'ant-design-vue'

const userStatus = (next) => {
  getUserInfo().then(res => {
    if (res.code === 200 && res.data) {
      if (Number(res.data.user_auth) === 3) {
        next()
        return
      }
      router.push({ name: 'userAuth' })
    }
  }).catch(() => {
    message.error("server error")
  })
}

const routes = [
  {
    path: '/',
    name: 'PageLayout1',
    redirect: '/home',
    meta: { login: false },
    component: PageLayout1,
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { login: false, header: 'home' },
        component: HomePage
      }
    ]
  },
  {
    path: '/markets/kline',
    name: 'PageLayout',
    redirect: '/markets/kline',
    meta: { login: false },
    component: PageLayout,
    children: [
      // {
      //   path: '/markets',
      //   name: 'market',
      //   meta: { login: false, header: 'market' },
      //   component: MarketPage
      // },
      {
        path: '/markets/kline',
        name: 'spotTrade',
        meta: { login: false, header: 'spotTrade' },
        component: SpotTrade
      },
      {
        path: '/contract',
        name: 'contract',
        meta: { login: false, header: 'contract' },
        component: () => import('@/views/page/ContractPage.vue')
      },
      {
        path: '/contract_sec',
        name: 'secContract',
        meta: { login: true, header: 'secContract' },
        component: () => import('@/views/page/SecContract.vue')
      },
      {
        path: '/ieo/:kline',
        name: 'ieoPage',
        meta: { login: true, header: 'ieo' },
        component: () => import('@/views/page/IEOPage.vue')
      },
      {
        path: '/user',
        name: 'user',
        redirect: '/user/info',
        meta: { login: true },
        component: () => import('@/views/UserLayout.vue'),
        children: [
          {
            path: '/user/info',
            name: 'userInfo',
            meta: { login: true, menu: 'userInfo' },
            component: () => import('@/views/page/user/UserInfo.vue')
          },
          {
            path: '/user/set',
            name: 'userSet',
            meta: { login: true, sub: 'set' },
            component: () => import('@/views/page/user/UserSet.vue')
          },
          {
            path: '/user/loginpwd',
            name: 'loginPwd',
            meta: { login: true, menu: 'userInfo' },
            component: () => import('@/views/page/user/LoginPwd.vue')
          },
          {
            path: '/user/securitypwd',
            name: 'securityPwd',
            meta: { login: true, menu: 'userInfo' },
            component: () => import('@/views/page/user/SecurityPwd.vue')
          },
          {
            path: '/user/emailset',
            name: 'emailSet',
            meta: { login: true, menu: 'userInfo' },
            component: () => import('@/views/page/user/EmailSet.vue')
          },
          {
            path: '/user/auth',
            name: 'userAuth',
            meta: { login: true, menu: 'userAuth' },
            component: () => import('@/views/page/user/UserAuth.vue')
          }
        ]
      },
      {
        path: '/order',
        name: 'order',
        redirect: '/order/spot-current',
        meta: { login: true, header: 'order' },
        component: () => import('@/views/OrderLayout.vue'),
        children: [
          {
            path: '/order/spot-current',
            name: 'spotCurrent',
            meta: { login: true, sub: 'spot', header: 'order' },
            component: () => import('@/views/page/order/SpotCurrent.vue')
          },
          {
            path: '/order/spot-history',
            name: 'spotHistory',
            meta: { login: true, sub: 'spot', header: 'order' },
            component: () => import('@/views/page/order/SpotHistory.vue')
          },
          {
            path: '/order/contract-current',
            name: 'contractCurrent',
            meta: { login: true, sub: 'contract', header: 'order' },
            component: () => import('@/views/page/order/ContractCurrent.vue')
          },
          {
            path: '/order/contract-history',
            name: 'contractHistory',
            meta: { login: true, sub: 'contract', header: 'order' },
            component: () => import('@/views/page/order/ContractHistory.vue')
          },
          {
            path: '/order/sec-current',
            name: 'secondCurrent',
            meta: { login: true, sub: 'second', header: 'order' },
            component: () => import('@/views/page/order/SecondCurrent.vue')
          },
          {
            path: '/order/sec-history',
            name: 'secondHistory',
            meta: { login: true, sub: 'second', header: 'order' },
            component: () => import('@/views/page/order/SecondHistory.vue')
          }
        ]
      },
      {
        path: '/wallet',
        name: 'wallet',
        redirect: '/wallet/overview',
        meta: { login: true, header: 'wallet' },
        component: () => import('@/views/WalletLayout.vue'),
        children: [
          {
            path: '/wallet/overview',
            name: 'walletOverview',
            meta: { login: true, header: 'wallet', top: true },
            component: () => import('@/views/page/wallet/WalletOverview.vue')
          },
          {
            path: '/wallet/spot',
            name: 'spotWallet',
            meta: { login: true, header: 'wallet', top: true },
            component: () => import('@/views/page/wallet/SpotWallet.vue')
          },
          {
            path: '/wallet/contract',
            name: 'contractWallet',
            meta: { login: true, header: 'wallet', top: true },
            component: () => import('@/views/page/wallet/ContractWallet.vue')
          },
          {
            path: '/wallet/rebate',
            name: 'rebateWallet',
            meta: { login: true, header: 'wallet', top: true },
            component: () => import('@/views/page/wallet/RebateWallet.vue')
          },
          {
            path: '/wallet/pledge',
            name: 'pledgeWallet',
            meta: { login: true, header: 'wallet', top: true },
            component: () => import('@/views/page/wallet/PledgeWallet.vue')
          },
          {
            path: '/wallet/service',
            name: 'serviceWallet',
            meta: { login: true, header: 'wallet', top: true },
            component: () => import('@/views/page/wallet/ServiceWallet.vue')
          },
          {
            path: '/wallet/ieo',
            name: 'ieoWallet',
            meta: { login: true, header: 'wallet', top: true },
            component: () => import('@/views/page/wallet/IeoWallet.vue')
          },
          {
            path: '/balance/history/:type/:coin/:id',
            name: 'balanceHistory',
            meta: { login: true, header: 'wallet', top: false },
            component: () => import('@/views/page/BalanceHistory.vue')
          }
        ]
      },
      {
        path: '/recommend',
        name: 'recommend',
        redirect: '/recommend/overview',
        meta: { login: true, header: 'recommend' },
        component: () => import('@/views/RecommendLayout.vue'),
        children: [
          {
            path: '/recommend/overview',
            name: 'recommendOverview',
            meta: { login: true, top: true },
            component: () => import('@/views/page/recommend/RecommendOverview.vue')
          },
          {
            path: '/recommend/rebate',
            name: 'recommendRebate',
            meta: { login: true, top: true },
            component: () => import('@/views/page/recommend/MyRebate.vue')
          },
          {
            path: '/recommend/rule',
            name: 'recommendRule',
            meta: { login: true, top: true },
            component: () => import('@/views/page/recommend/RebateRule.vue')
          }
        ]
      },
      {
        path: '/wallet/recharge',
        name: 'recharge',
        meta: { login: true, header: 'wallet' },
        component: () => import('@/views/page/RechargePage.vue')
      },
      {
        path: '/wallet/withdraw',
        name: 'withdraw',
        meta: { login: true, header: 'wallet' },
        component: () => import('@/views/page/WithdrawPage.vue'),
        beforeEnter: (to, form, next) => {
          userStatus(next)
        }
      },
      {
        path: '/wallet/transfer',
        name: 'transfer',
        meta: { login: true, header: 'wallet' },
        component: () => import('@/views/page/TransferPage.vue'),
        // beforeEnter: (to, form, next) => {
        //   userStatus(next)
        // }
      },
      {
        path: '/article/:type/:id',
        name: 'article',
        meta: { login: false },
        component: () => import('@/views/page/ArticlePage.vue')
      },
      {
        path: '/notice',
        name: 'notice',
        meta: { login: false },
        component: () => import('@/views/page/NoticePage.vue')
      },
      {
        path: '/agree/list',
        name: 'agreeList',
        meta: { login: false },
        component: () => import('@/views/page/AgreeList.vue')
      }
    ]
  },
  {
    path: '/login/layout',
    redirect: '/login',
    name: 'loginLayout',
    meta: { login: false },
    component: LoginLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        meta: { login: false },
        component: LoginPage
      },
      {
        path: '/register',
        name: 'register',
        meta: { login: false },
        component: RegisterPage
      },
      {
        path: '/forget',
        name: 'forget',
        meta: { login: false },
        component: () => import('@/views/page/ForgetPage.vue')
      }
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '/home' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return window.scrollTo(0,0);
  }
})

export default router
