import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0b5aad1e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tab-bar"
};
const _hoisted_2 = ["onClick"];
export default {
  __name: 'TabBar',
  props: {
    tabList: {
      type: Array,
      default: () => []
    },
    tabActive: {
      type: Number,
      default: 0
    },
    activeBg: {
      type: String,
      default: '#e0f1f7'
    }
  },
  emits: ['change'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const tabClass = index => {
      if (props.tabActive === index) {
        return ['tab-item', 'tab-active'];
      }
      return ['tab-item'];
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabList, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          onClick: $event => emit('change', index),
          class: _normalizeClass(tabClass(index)),
          style: _normalizeStyle({
            '--active-bg': props.activeBg
          })
        }, _toDisplayString(item), 15, _hoisted_2);
      }), 128))]);
    };
  }
};