import axios from "axios"
import store from "@/store"
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
})

service.interceptors.request.use(
  config => {
    config.headers = {
      token: store.state.token,
      lang: store.state.lang
    };
    return config;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
)

service.interceptors.response.use(
  response => {
    if (response.headers["content-type"].indexOf("json") === -1) {
        return response;
    }
    const res = response.data

    console.log(location.pathname);
    if (res.code === -100) {
      if(location.pathname != '/contract' && location.pathname != '/contract_sec' && location.pathname != '/ieo/LTJUSDT' && location.pathname != '/home' && location.pathname != '/markets/kline' && location.pathname != '/register'){
        store.commit('tokenChange', '')
        localStorage.removeItem('pcstore_info')
        router.push({ name: 'login' })
        console.log("1111111111111111");
      }
      
    }
    if (res.refreshtoken) {
      store.commit('tokenChange', res.refreshtoken)
    }
    return response.data;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
)

export default service