export default {
  required: 'आवश्यक',
  home: 'घर',
  market: 'बाजार',
  currency: 'व्यापार',
  walletTrade: 'स्थान',
  contract: 'फ्यूचर्स',
  wallet: 'पर्स',
  pulling: 'ताज़ा करने के लिए नीचे खींचें',
  loosing: 'ताज़ा होने के लिए छोड़ दें',
  loading: 'लोड हो रहा है',
  loadingError: 'लोड करने में विफल',
  nothing: 'कोई डेटा नहीं',
  finished: '',
  price: 'कीमत',
  number: 'मात्रा',
  recharge: 'जमा',
  transfer: 'स्थानांतरण करना',
  invite: 'आमंत्रित कर',
  inviteDes: 'हमारे साथ जुड़ने के लिए दोस्तों को आमंत्रित करें',
  contact: 'संपर्क करें',
  contactDes: '7*24 ऑनलाइन',
  fastTrade: 'विकल्प',
  name: 'नाम',
  fastPrice: 'कीमत',
  trend: '24H %',
  all: 'सभी',
  growthList: 'लाभार्थी',
  loserList: 'हारे',
  vol: '24H मात्रा',
  buy: 'खरीदना',
  sell: 'बेचना',
  optimal: 'बाजार कीमत',
  marketOrder: 'बाजार व्यापार',
  limitOrder: 'व्यापार सीमित करें',
  useable: 'उपलब्ध',
  tradeVol: 'राशि',
  entrust: 'सीमा आदेश',
  entrustTotal: 'राशि',
  noLogin: 'लॉग आउट',
  toLogin: 'अब प्रवेश करें',
  cancelOrder: 'रद्द करना',
  cancelError: 'रद्द करने में विफल',
  cancelSuccess: 'सफलतापूर्वक रद्द करें',
  cancelTip: 'रद्द करना सुनिश्चित करें？',
  completed: 'पूरा हुआ',
  canceled: 'रद्द',
  marketPrice: 'बाज़ार',
  limitPrice: 'सीमा',
  entrustPrice: 'कीमत',
  entrustNum: 'मात्रा',
  currencyType: 'चिन्ह, प्रतीक',
  currencyOrder: 'व्यापार रिकॉर्ड',
  history: 'इतिहास',
  tryAgain: 'पुनः प्रयास करें',
  entrustPriceTip: 'इनपुट मूल्य',
  tradeNumTip: 'इनपुट मात्रा',
  tradeNumLess: 'मात्रा 0 . से अधिक होनी चाहिए',
  numLessTip: 'अपर्याप्त शेषराशि',
  tradePriceTip: 'इनपुट राशि',
  balanceTip: 'अपर्याप्त शेषराशि',
  tradeError: 'व्यापार करने में विफल',
  tradeSuccess: 'सफलतापूर्वक व्यापार करें',
  tip: 'सूचना',
  tradeConfirm: 'व्यापार करने की पुष्टि करें？',
  cancelText: 'रद्द करना',
  confirmText: 'पुष्टि करें',
  toBuy: 'मोल लेना',
  toSell: 'कम बेचना',
  confirmToBuy: 'मोल लेना',
  confirmToSell: 'कम बेचना',
  slPrice: 'स्टॉप लॉस प्राइस',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'लाभ मूल्य लें',
  currentOrder: 'अभी का ऑर्डर',
  historyOrder: 'इतिहास',
  setting: 'समूह',
  unwind: 'बंद करना',
  handUnwind: 'मैन्युअल रूप से बंद करें',
  futuresPay: '',
  forceUnwind: 'परिसमापन',
  slTp: 'SL/TP',
  fastTradeUnwind: 'विकल्प',
  blowUp: 'परिसमापन',
  openNum: 'मात्रा',
  warrant: 'अंतर',
  openPrice: 'कीमत',
  fee: 'शुल्क',
  profit: 'फायदा',
  lastPrice: 'मौजूदा कीमत',
  unwindPrice: 'बंद कीमत',
  unwindError: 'बंद करने में विफल',
  unwindSuccess: 'सफलतापूर्वक बंद करें',
  setSuccess: 'सफलतापूर्वक सेट करें',
  setError: 'सेट करने में विफल',
  setSlTp: 'SL/TP . सेट करें',
  second: 'दूसरा',
  multiple: 'विभिन्न',
  direction: 'प्रकार',
  tradeCurrency: 'चिन्ह, प्रतीक',
  loss: 'नुकसान',
  fill: 'जीत',
  executePrice: 'कीमत',
  currentPrice: 'मौजूदा कीमत',
  closePrice: 'बंद कीमत',
  secondNum: 'अवधि',
  contractOrder: 'वायदा आदेश',
  fastTradeOrder: 'विकल्प आदेश',
  overview: 'अवलोकन',
  ieoBuy: 'IEO',
  balanceTotal: 'कुल संपत्ति',
  withdraw: 'निकालना',
  tradeOrder: 'व्यापार आदेश',
  rechargeHistory: 'जमा रिकॉर्ड',
  withdrawHistory: 'रिकॉर्ड वापस लें',
  freeze: 'जमा हुआ',
  amountTo: '',
  tipText: 'सूचना',
  ieoTip: 'यह जल्द ही आ रहा है।',
  financeList: 'वित्तीय रिकॉर्ड',
  currencyAccount: 'स्पॉट खाता',
  contractAccount: 'वायदा खाता',
  time: 'समय',
  status: 'दर्जा',
  balanceTyle: 'खाता',
  pay: 'नतीजा',
  income: 'आय',
  lastBalance: 'संतुलन',
  orderNo: 'पहचान',
  money: 'राशि',
  date: 'दिनांक',
  finallyIncome: 'राशि प्राप्त करें',
  commitMoney: 'राशि',
  orderNum: 'पहचान',
  waitReview: 'की समीक्षा',
  reviewPass: 'उत्तीर्ण',
  reviewReject: 'अस्वीकृत',
  waitWallet: 'की समीक्षा',
  walletPass: 'उत्तीर्ण',
  walletReject: 'अस्वीकृत',
  withdrawSuccess: 'सफलतापूर्वक वापस ल',
  withdrawError: 'स्थानांतरित करने में विफल',
  high24H: '24H उच्च',
  low24H: '24H कम',
  num24H: '24H मात्रा',
  minute: 'मिनट',
  hour: 'घंटे',
  day: 'दिन',
  week: 'सप्ताह',
  markets: 'बाज़ार',
  open: 'खुला हुआ',
  close: 'बंद करना',
  high: 'उच्च',
  low: 'कम',
  volume: 'मात्रा',
  fastLogin: 'अब प्रवेश करें',
  register: 'पंजीकरण करवाना',
  auth: 'सत्यापित करना',
  authSuccess: 'सत्यापित',
  noPass: 'अस्वीकृत',
  authCommit: 'की समीक्षा',
  onAuth: 'असत्यापित',
  onLogin: 'लॉग आउट',
  securitySet: 'सुरक्षा',
  userAgree: 'उपयोगकर्ता समर्थन',
  aboutUs: 'हमारे बारे में',
  logout: 'लॉग आउट',
  logoutSuccess: 'सफलतापूर्वक लॉगआउट करें',
  lookAuthInfo: 'मेरी जानकारी जांचें',
  authAgain: 'पुनः सत्यापित करें',
  authInfo: 'जानकारी',
  account: 'खाता',
  countryArea: 'देश/क्षेत्र',
  idNo: 'पहचान',
  idNoTip: 'इनपुट आईडी नंबर',
  email: 'ईमेल',
  emailInput: 'इनपुट ईमेल',
  setLoginPwd: 'लॉगिन पासवर्ड सेट करें',
  loginPwdInput: 'इनपुट लॉगिन पासवर्ड (8-20 अक्षर)',
  loginPwdTip: 'इनपुट लॉगिन पासवर्ड',
  loginPwdLen: 'लॉगिन पासवर्ड 8-20 अक्षरों का होना चाहिए',
  setFinancePwd: 'फंड पासवर्ड सेट करें',
  financePwdInput: 'इनपुट फंड पासवर्ड (6-20 अक्षर)',
  financePwdTip: 'इनपुट फंड पासवर्ड',
  financePwdLen: 'फंड पासवर्ड 6-20 अक्षरों का होना चाहिए',
  inviteCode: 'आमंत्रण संकेत',
  inviteCodeTip: 'इनपुट आमंत्रण कोड',
  inviteInput: 'आमंत्रण कोड आवश्यक है',
  imageCode: 'कॅप्चा',
  imageCodeInput: 'इनपुट कैप्चा',
  agree: 'उपयोगकर्ता समझौता》',
  agreeText: 'उपयोगकर्ता समझौता',
  next: 'अगला',
  registerSuccess: 'पूरी तरह से रजिस्टर करें',
  registerError: 'पंजीकरण करने में विफल',
  securityCheck: 'सुरक्षा',
  checkCodeInput: 'इनपुट सत्यापन कोड',
  sendCheckCode: 'भेजना',
  sendSuccess: 'भेज दिया',
  sendError: 'भेजने में विफल',
  welcome: 'स्वागत',
  loginEmail: 'ईमेल',
  loginEmailInput: 'इनपुट ईमेल पता',
  loginPwd: 'लॉग इन पासवर्ड',
  login: 'लॉग इन करें',
  forgetPwd: 'पासवर्ड भूल गए',
  loginTip: 'लॉगिन का मतलब है कि आप सहमत हैं',
  loginSuccess: 'पूरी तरह से लॉगिन करें',
  loginError: 'लॉगिन करने में विफल',
  fullname: 'नाम',
  country: 'देश/क्षेत्र',
  countryTip: 'इनपुट देश',
  imgFront: 'अपने आईडी कार्ड के सामने की तस्वीर अपलोड करें',
  imgReverse: 'अपने आईडी कार्ड के पिछले हिस्से की फोटो अपलोड करें',
  commit: 'प्रस्तुत करना',
  commitSuccess: 'पूरी तरह से सबमिट करें',
  commitError: 'सबमिट करने में विफल',
  selectCurrency: 'क्रिप्टो चुनें',
  rechargeNet: 'नेटवर्क',
  rechargeAddress: 'जमा पता',
  copyAddress: 'पता कॉपी करें',
  minRecharge: 'न्यूनतम जमा राशि',
  copySuccess: 'पूरी तरह से कॉपी करें',
  copyError: 'कॉपी करने में विफल',
  withdrawTip: 'आप अपना खाता सत्यापित करने के बाद वापस ले सकते हैं',
  withdrawAddress: 'निकासी का पता',
  withdrawAddressInput: 'इनपुट निकासी पता',
  withdrawNet: 'नेटवर्क',
  withdrawNum: 'राशि',
  withdrawNumInput: 'इनपुट राशि',
  withdrawNumLess: 'अपर्याप्त शेषराशि',
  withdrawMin: 'न्यूनतम निकासी राशि',
  balanceUse: 'उपलब्ध',
  actualMoney: 'राशि प्राप्त करें',
  actualMoneyText: 'राशि प्राप्त करें',
  financePwd: 'फंड पासवर्ड',
  trade: '',
  withdrawFail: 'वापस लेने में विफल',
  commitTime: 'दिनांक',
  from: 'से',
  to: 'प्रति',
  transferMoney: 'इनपुट राशि',
  transferSuccess: 'पूरी तरह से स्थानांतरण',
  transferError: 'स्थानांतरित करने में विफल',
  transferHistory: 'स्थानांतरण रिकॉर्ड',
  updateFinancePwd: 'फंड पासवर्ड बदलें',
  confirmPwd: 'पासवर्ड की पुष्टि कीजिये',
  confirmPwdInput: 'इनपुट पासवर्ड की पुष्टि करें',
  updatePwd: 'पासवर्ड बदलें',
  updatePwdSuccess: 'पूरी तरह से बदलें',
  updatePwdError: 'बदलने में विफल',
  noAlike: 'पासवर्ड असंगत है',
  emailUpdate: 'बदले ई - मेल',
  loginPwdUpdate: 'लॉगिन पासवर्ड बदलें',
  financePwdUpdate: 'फंड पासवर्ड बदलें',
  newEmail: 'नई ईमेल',
  emailUpdateTip: 'इनपुट नया ईमेल',
  checkEmail: 'ईमेल सत्यापित करें',
  updateEmail: 'बदले ई - मेल',
  updateEmailError: 'बदलने में विफल',
  updateEmailSuccess: 'पूरी तरह से बदलें',
  checkError: 'सत्यापित करने में विफल',
  pwdLen: '8-20 अक्षर',
  inviteUrl: 'सिफ़ारिश के लिंक',
  myInviteCode: 'आमंत्रण संकेत',
  fastInvite: 'अभी आमंत्रित करें',
  myInvite: 'आमंत्रण सूची',
  registerTime: 'दिनांक',
  bulletin: 'घोषणा',
  inviteFriend: 'मित्रों को आमंत्रित करें',
  saveAccount: 'अकाउंट और पासवर्ड सेव करें',
  indicator: 'संकेतक',
  type: 'प्रकार',
  amount: 'राशि',
  retrievePwd: 'पासवर्ड पुनः प्राप्त करें',
  order: 'आदेश', 
  registerTitle: 'अत्यंत समृद्ध वैश्विक खाते के लिए साइन अप करें', 
  emailCheck: 'ईमेल सत्यापन कोड', 
  haveAccount: 'क्या आपके पास पहले से एक खाता मौजूद है?', 
  registerTip: 'मैंने पढ़ लिया है और इससे सहमत हूं ', 
  userInfo: 'व्यक्तिगत जानकारी', 
  userAuth: 'खाता सत्यापित करें', 
  footerTip: 'अंतिम व्याख्या अधिकार मंच का है', 
  marketInfo: 'बाजार', 
  viewMore: 'अधिक जानने के लिए क्लिक करें', 
  tradeText: 'व्यापार', 
  position: 'अपंगता', 
  level: 'स्तर', 
  oneBuy: 'खरीदना', 
  oneSell: 'बेचना', 
  newTrade: 'रियल टाइम',
  tradePair: 'चिन्ह, प्रतीक', 
  historyEntrust: 'आदेश इतिहास', 
  allOrder: 'सभी रिकॉर्ड', 
  total: 'कुल', 
  oneOrder: 'आदेश', 
  priceTip: 'इनपुट मूल्य', 
  numberTip: 'इनपुट मात्रा', 
  profitText: 'फायदा', 
  foundInput: 'इनपुट राशि', 
  foundTip: 'कृपया राशि दर्ज करें', 
  countdown: 'उलटी गिनती', 
  tradeMoney: 'राशि', 
  completePrice: 'कीमत', 
  profitAndLoss: 'अनुमानित लाभ और हानि', 
  walletOverview: 'अवलोकन', 
  ieoBuyAccount: 'IEO खाता', 
  balanceText: 'इतिहास', 
  selectRechargeAddress: 'जमा पता चुनें', 
  successText: 'सफलता', 
  errorText: 'विफल', 
  selectWithdrawAddress: 'इनपुट वापस लेने का पता', 
  withdrawSuText: 'सफलतापूर्वक सबमिट करें', 
  transferMoneyLabel: 'राशि', 
  paramsError: 'पैरामीटर त्रुटि', 
  reset: 'रीसेट', 
  selectText: 'खोज', 
  emailManage: 'खाता प्रबंधन', 
  loginPwdDes: 'अपना लॉगिन पासवर्ड संशोधित करें', 
  updateText: 'संशोधित', 
  financePwdDes: 'अपना फंड पासवर्ड संशोधित करें', 
  emailManageDes: 'अपने खाते की सुरक्षा रखें', 
  oldEmail: 'मूल ईमेल पता', 
  countryAreaTip: 'इनपुट देश या क्षेत्र',  
  fullnameTip: 'इनपुट नाम',
  agreeTip: 'कृपया "उपयोगकर्ता अनुबंध" जांच',
  volume24h: '24H वॉल्यूम',
  // 首页
  currencyHomeType: 'क्रिप्टो',
  whyUs: 'हम क्यों?',
  why1: 'सुरक्षित और बीमित',
  why1Des: 'हम अपने सुरक्षित कोल्ड वॉलेट में 80% क्रिप्टोकरेंसी स्टोर करते हैं। कोल्ड स्टोरेज को लेजर द्वारा सुरक्षित और बीमाकृत किया जाता है।',
  why2: 'तेज़ लेन-देन',
  why2Des: 'हम लाखों का लेन-देन कर सकते हैं। बढ़ती मांग को पूरा करने के लिए हमारा सिस्टम इंफ्रास्ट्रक्चर कुछ सेकंड में बढ़ सकता है।',
  why3: 'सरल और आसान प्रयोग करने योग्य',
  why3Des: 'हमारे प्लेटफॉर्म पर ट्रेडिंग एक सुपर-फास्ट अनुभव है। हमने लाखों लोगों द्वारा उपयोग की जाने वाली तकनीक का निर्माण किया है, और उस अनुभव ने हमें इस शक्तिशाली एक्सचेंज को बनाने में मदद की है।',
  why4: 'तत्काल व्यापार',
  why4Des: 'एक क्लिक में तत्काल 150+ क्रिप्टोकरेंसी का व्यापार करें।',
  why5: 'सबसे अच्छी कीमत',
  why5Des: 'उच्च तरलता के साथ सभी क्रिप्टोकरेंसी का व्यापार करें।',
  why6: 'विश्वास',
  why6Des: '800k+ से अधिक उपयोगकर्ताओं ने विश्वास किया है और सैकड़ों करोड़ का कारोबार किया है।',
  homeGift: 'रजिस्टर बोनस के लिए ट्रेड करने के लिए स्वतंत्र रूप से $30 प्राप्त करें।',
  popular1: '$100,000 यूएसडी+',
  popular1Des: 'कुल कारोबार की मात्रा',
  popular2: '150+',
  popular2Des: 'मुद्राओं का समर्थन किया',
  popular3: '800के+',
  popular3Des: 'सत्यापित उपयोगकर्ता',
  globalText: 'क्रिप्टो करेंसी ट्रेडिंग के लिए 800K+ वैश्विक उपयोगकर्ताओं का भरोसा',
  startText: 'सिर्फ 5 मिनट में शुरू करें।',
  start1: 'साइन अप करें',
  start1Des: 'वेबसाइट खोलें या ऐप डाउनलोड करें, और ईमेल का उपयोग करके पंजीकरण करें।',
  start2: 'KYC',
  start2Des: 'अपना आईडी प्रूफ संभाल कर रखें, बुनियादी केवाईसी विवरण दर्ज करें और अपना खाता सत्यापित करवाएं।',
  start3: 'जमा',
  start3Des: 'आसानी से अपने डिजिटल वॉलेट में जमा करें।',
  start4: 'खरीद बिक्री',
  start4Des: 'सर्वोत्तम मूल्य पर क्रिप्टोकरेंसी खरीदें/बेचें और व्यापार करें।',
  secondTip: 'राशि {tip} से कम नहीं होनी चाहिए',
  appTitle: 'एपीपी अब उपलब्ध ह',
  appDes: 'सीधे अपने स्मार्टफोन पर श्रेणी में सर्वश्रेष्ठ विनियमित क्रिप्टो करेंसी एक्सचेंज प्लेटफॉर्म की सुरक्षा, ट्रेडिंग और क्षमताएं प्राप्त करें।',
  timeUp: 'समय समाप्त',
  closeAll: 'सब बंद कर',
  assetRate: 'मार्जिन दर',
  cancel: 'रद्द करना',
  cancelInfo: 'रद्द करने की पुष्टि करें?',
  buyCurrency: 'चिन्ह, प्रतीक',
  closeCurrency: 'मुद्रा',
  buyPrice: 'मूल्य',
  valuation: 'मूल्यांकन',
  profitP: 'उपज',
  buySuccess: 'सफलतापूर्वक',

  swiperTitle1: 'दुनिया का शीर्ष डिजिटल क्रिप्टो एक्सचेंज',
  swiperSub1: '100,000 से अधिक उपयोगकर्ताओं द्वारा भरोसा किया गया',

  ieoBuying: 'चल रहे',
  ieoOpen: 'आरंभ करने की तिथि(UTC-5)',
  ieoClose: 'समाप्ति अवधि(UTC-5)',
  about: 'के बारे में',
  ieoPlan: 'वितरण',
  ieoInfo: 'जानकारी',
  ieoContact: 'सामाजिक मीडिया',
  ieoStart: 'प्रारंभ',
  ieoBuyStart: 'आईओओ प्रारंभ',
  ieoBuyEnd: 'आईओओ खत्म',
  ieoIsTrade: 'शुरू करना',
  introduce: 'परिचय',
  createTeam: 'सुरक्षित कोष',
  angelFund: 'एंजेल फंड',
  ecology: 'संस्थान और पारिस्थितिकी तंत्र',
  presale: 'पूर्व बिक्री रिलीज',
  mechanism: 'खनन तंत्र',
  trading: 'एक्सचेंज ट्रेडिंग',

  issuePrice: 'कीमत जारी कर',
  minBuyPrice: 'न्यूनतम सदस्यता',
  buyNum: 'प्रति दिन सदस्यता',
  buyTotal: 'कुल दैनिक सदस्यता',
  Increment: 'सदस्यता वृद्धि',
  blockchain: 'ब्लॉकचेन',

  sellTotal: 'कुल जारी करना',
  currentBuy: 'वर्तमान सदस्यता',
  dowload: 'डाउनलोड केंद्र',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'इतिहास',
  lessMinTip: 'न्यूनतम राशि से कम नहीं हो सकता।',
  rechargeTip1: '1. यह आपका सबसे नया और अनूठा पता है, प्लेटफ़ॉर्म आपसे कभी भी किसी अन्य खाते में धनराशि स्थानांतरित करने या आपका सत्यापन कोड मांगने के लिए नहीं कहेगा।',
  rechargeTip2: '2. जमा की पुष्टि के लिए कम से कम 10 नेटवर्क ब्लॉक की आवश्यकता होती है। पुष्टि के बाद, आपके खाते में स्वचालित रूप से धनराशि प्राप्त हो जाएगी।',
  withdrawTip1: '1. सुनिश्चित करें कि आप इस निकासी के लिए उसी नेटवर्क का चयन करते हैं जो जमा पक्ष पर है, और जांचें कि प्राप्त करने वाला प्लेटफॉर्म नेटवर्क का समर्थन करता है। अन्यथा, आप अपनी संपत्ति खो देंगे।',
  withdrawTip2: '2. प्लेटफॉर्म द्वारा आपके आवेदन को मंजूरी दिए जाने के बाद नेटवर्क ब्लॉक आपकी निकासी की पुष्टि करेगा।',
  withdrawTip3: '3. निकासी की पुष्टि के लिए कम से कम 10 नेटवर्क ब्लॉक की आवश्यकता होती है। पुष्टि के बाद, आपके खाते में स्वचालित रूप से धनराशि प्राप्त हो जाएगी।',
  footerBottomTip: 'इस वेबसाइट पर प्रदान की गई जानकारी निवेश सलाह, वित्तीय सलाह, व्यापारिक सलाह या किसी अन्य प्रकार की सलाह का गठन नहीं करती है और आपको वेबसाइट की किसी भी सामग्री को ऐसा नहीं मानना चाहिए। क्रिप्टोक्यूरेंसी निवेश प्रकृति में अस्थिर और उच्च जोखिम वाले हैं। जितना आप खो सकते हैं उससे अधिक निवेश न करें।',

  stakeAccount: 'स्टेकिंग खाता',
  serviceAccount: 'सेवा खाता',
  lock: 'ताला',
  lockPrice: 'बंद कीमत',
  ieoHistory: 'IEO इतिहास',
  lockHistory: 'लॉक इतिहास',
  sellHistory: 'इतिहास बेचो',
  stake: 'जताया',
  service: 'सेवा',
  ieoBuyTip: '*आईईओ नोटिस: जीपीटीएच की संख्या सीमित है। हम उपयोगकर्ता के IEO एप्लिकेशन की समीक्षा करेंगे और हर 3 घंटे में यादृच्छिक रूप से लॉट निकालेंगे। अपेक्षाकृत बड़ी राशि वाले उपयोगकर्ताओं के पास LTJ खरीदने की संभावना अधिक होगी। प्रत्येक उपयोगकर्ता प्रति दिन 3 बार तक खरीदारी कर सकता है।',
  ieoLockTip: '* लॉक प्राइस: उपयोगकर्ताओं को डब्ल्यूटीसी की कीमत को लॉक करने के लिए प्रतिज्ञा मुद्रा के रूप में यूएसडीटी का उपयोग करने की आवश्यकता होती है, और प्रतिज्ञा अनुपात डब्ल्यूटीसी के मूल्य का 30% है। LTJ के लॉन्च होने के बाद, उपयोगकर्ता उस समय लॉक किए गए मूल्य पर LTJ बेच सकते हैं।',
  ieoSellTip: '*IEO बेचना: LTJ को बेचने के लिए सेवा शुल्क के रूप में LTJ मूल्य के 10% की आवश्यकता होती है। LTJ को बेचने के बाद, आपके द्वारा एक्सचेंज किए गए USDT को 20 दिनों में फ्रीज कर दिया जाएगा, और गिरवी रखे गए USDT को 20 दिनों में फ्रीज कर दिया जाएगा।',
  whitePaper: 'सफेद कागज',
  cost: 'लागत',
  currentEstimate: 'वर्तमान मूल्यांकन',
  stakeNum: 'स्टेकिंग राशि',
  estimate: 'मूल्यांकन',
  consume: 'उपभोग करना',
 
  seedSell: 'बीज गोल',
  privateSell: 'निजी दौर',
  openSell: 'कोल राउंड',
  ieoAward: 'जनता का दौर',
  ieoGrowUp: 'टीम',
  partner: 'सलाहकार',
  consultant: 'विपणन',
  ieoName: 'लिक्विडिटी',
  conAward: 'एआई कमाने के लिए',
  staking: 'जताया',

  release: 'मुक्त करना',
  closedTime: 'बंद समय',
  remark: 'टिप्पणी',


  totalProfit: 'कुल लाभ',
  transferIn: 'में स्थानांतरण',
  transferOut: 'बाहर स्थानांतरण',
  equity: 'निवल मूल्य',
  
  homeremind1: 'लोकप्रिय क्रिप्टोकरेंसी',
  homeremind2: 'अधिक बाज़ार देखें',
  homeremind3: 'अंतिम मूल्य(USD)',
  homeremind4: '24H परिवर्तन',
  homeremind5: 'कार्रवाई',
  homeremind6: 'और देखें',
  homeremind7: "उपयोगकर्ताओं की शीर्ष पसंद",
  homeremind8: '24 घंटे ट्रेडिंग वॉल्यूम',
  homeremind9: 'प्राइम वर्चुअल एसेट्स',
  homeremind10: 'हमारे मुख्य उत्पाद',
  homeremind11: 'चुनने के लिए ट्रेडिंग टूल की विस्तृत विविधता',
  homeremind12: 'स्थान',
  homeremind13: 'ट्रेडिंग के लिए 700 से अधिक क्रिप्टो उपलब्ध हैं',
  homeremind14: 'वायदा व्यापार करके अपने अनुभव को उन्नत करें',
  homeremind15: 'NYECoins Earn',
  homeremind16: 'उद्योग में सर्वश्रेष्ठ एपीवाई',
  homeremind17: 'हमारी विविध सेवाएँ',
  homeremind18: 'हमारी विविध सेवाओं के साथ असीमित संभावनाओं का अन्वेषण करें',
  homeremind19: 'आईईओ सदस्यता',
  homeremind20: "सर्वोत्तम प्री-सेल क्रिप्टोकरेंसी",
  homeremind21: 'वीआईपी ग्राहक सेवाएँ',
  homeremind22: '7*24 घंटे',
  homeremind23: 'समुदाय',
  homeremind24: 'यहां हमारे साथ धन के अवसरों का पता लगाएं',
  homeremind25: 'ट्रेडिंग में बने रहें. कहीं भी कभी भी।',
  homeremind26: 'हमारे मोबाइल एपीपी और वेबसाइट टर्मिनल से अवगत रहें।',
  homeremind27: 'सामाजिक मीडिया पर हमारा अनुसरण करें',
  homeremind28: 'हमारी नवीनतम खबरों से अपडेट रहें',
  homeremind29: 'कभी भी, कहीं भी व्यापार करें',
  homeremind30: 'अपने iOS, Android, या API के साथ NYECoins आज़माएँ।',

  msb: 'NYECoins को MSB, USA में विनियमित किया जाता है।',
  msb1: 'भरोसेमंद, सुरक्षित, तेज़',
  checkNow: 'अब जांचें',

  foundingTeam: 'संस्थापक दल',
  advisorTeam: 'सलाहकार दल',
  community: 'समुदाय',

  phone: 'फ़ोन नंबर',
  phoneTip: 'अपना फ़ोन नंबर डालें',



  rebateAccount: 'कमीशन खाता',
  rebateType: 'प्रकार',
  recommend: 'रेफ़रल',
  myRebate: 'मेरा कमीशन',
  rebateRule: 'आयोग के नियम',
  yes: 'हाँ',
  no: 'नहीं',
  authName: 'सत्यापित करें',
  countryName: 'देश क्षेत्र',
  allCommission: 'कुल कमीशन',
  myClient: 'मेरे ग्राहक',
  inviteUrl1: 'सिफ़ारिश के लिंक',
  inviteCode1: 'रेफरल कोड',
  commissioned: 'आयोग',

  swiperTitle2: 'ऑनलाइन क्रिप्टो ट्रेडिंग में वैश्विक नेता। क्रिप्टोकरेंसी खरीदें, बेचें और धारण करें।',
  swiperSub2: 'हमने लाखों वैश्विक उपयोगकर्ताओं को उनकी डिजिटल संपत्ति यात्रा में मार्गदर्शन किया है।',

  homeremind31: 'हम क्या करते हैं?',
  homeremind32: 'हम क्रिप्टोकरेंसी व्यापारियों और आम उपभोक्ताओं के बीच की रेखा को धुंधला करना चाहते हैं। हम नि:शुल्क प्रवेश देकर और लोगों को यह समझने और विश्वास करने में मदद करके क्रिप्टोकरेंसी को लोकप्रिय बनाएंगे कि हम पैसे का भविष्य क्या मानते हैं।',
  homeremind33: 'NYECOINS की विशेषताएं',
  homeremind34: 'NYECOINS स्पष्ट और निष्पक्ष सौदे सुनिश्चित करने के लिए केंद्रीय बैंक या एकल प्रशासक के बिना विक्रेताओं और खरीदारों को एक साथ लाता है',
  homeremind35: 'कम लेन-देन लागत',
  homeremind36: 'कम लेनदेन भुगतान और परिचालन व्यय वाले ग्राहकों के लिए एक विकेन्द्रीकृत मंच उपलब्ध है।',
  homeremind37: 'C2C लेनदेन',
  homeremind38: 'NYECOINS के साथ C2C ऑपरेशन आसान, तेज़ और सुरक्षित हैं। प्लेटफ़ॉर्म पर किसी भी ग्राहक को बीटीसी या अपनी पसंद की मुद्रा भेजें।',
  homeremind39: 'ब्रांडों के लिए प्रीमियम टूलकिट',
  homeremind40: 'हमने कंपनियों को अपने ग्राहकों को जानने के लिए उपकरण और जानकारी देकर उपभोक्ता लक्ष्यीकरण को अनुकूलित किया है।',
  homeremind41: 'वैश्विक साझेदारों द्वारा समर्थित',
  homeremind42: 'NYECOINS टीम',
  homeremind43: 'आइए हम आपको उस टीम से परिचित कराते हैं जो यह सब करेगी।',
  homeremind44: 'Mark Nichols',
  homeremind45: 'सह संस्थापक',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'वरिष्ठ सॉफ़्टवेयर इंजीनियर',
  homeremind48: 'Richard Norman',
  homeremind49: 'सामुदायिक प्रबंधक',
  homeremind50: 'Paul Garrett',
  homeremind51: 'यूआई/यूएक्स डिजाइनर',


  ieoIntro1: 'तरलता प्रोत्साहन',
  ieoIntro2: 'स्टेकिंग रिजर्व',
  ieoIntro3: 'पारिस्थितिकी तंत्र भागीदार',
  ieoIntro4: 'सार्वजनिक बिक्री',
  ieoIntro5: 'निजी बिक्री ए',
  ieoIntro6: 'बीज गोल',
  ieoIntro7: 'LinkToJoin टीम',
  ieoIntro8: 'उद्यम शुल्क आरक्षित',
}