import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7da4643a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "stop-trade-container"
};
const _hoisted_2 = {
  class: "content-item"
};
const _hoisted_3 = {
  class: "trade-option"
};
const _hoisted_4 = {
  class: "trade-item",
  style: {
    "padding-right": "12px"
  }
};
const _hoisted_5 = {
  class: "trade-item",
  style: {
    "padding-left": "12px"
  }
};
const _hoisted_6 = {
  class: "content-item",
  style: {
    "padding": "10px 0 40px 0"
  }
};
const _hoisted_7 = {
  class: "entrust-view"
};
const _hoisted_8 = {
  class: "table-header"
};
import { ref, onMounted, watch, onBeforeUnmount, createVNode } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { Modal, message } from 'ant-design-vue';
import TradeArea from '@/components/TradeArea.vue';
import TabBar from '@/components/TabBar.vue';
import TradeForm from '@/components/TradeForm.vue';
import SpotTable from '@/components/SpotTable.vue';
import { getUserAsset, exchangeTrade, orderList } from '@/apis';
import { exType } from '@/constant';
import { soptFmt } from '@/util/orderFmt';
export default {
  __name: 'SpotTrade',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const {
      t
    } = useI18n();
    const tradeType = ref(0);
    const klineKey = ref("");
    const buyForm = ref();
    const sellForm = ref();
    const assetList = ref([]);
    const assetUsdt = ref(0);
    const typeAsset = ref(0);
    const currentPage = ref(1);
    const currentTotal = ref(0);
    const currentLoading = ref(false);
    const currentList = ref([]);
    const historyPage = ref(1);
    const historyTotal = ref(0);
    const historyLoading = ref(false);
    const historyList = ref([]);
    const tabActive = ref(0);
    const klineChange = watch(klineKey, async () => {
      const findItem = assetList.value.find(item => {
        return item.name === store.state.marketInfo[klineKey.value].stock;
      });
      if (findItem) typeAsset.value = findItem.active;
    });
    onMounted(async () => {
      if (route.query.key && route.query.key !== '') {
        klineKey.value = route.query.key;
      } else {
        if (store.state.marketKey.length > 0) {
          klineKey.value = store.state.marketKey[0];
        } else {
          const keyWatch = watch(() => store.state.marketKey.length, () => {
            klineKey.value = store.state.marketKey[0];
            keyWatch();
          });
        }
      }
      init();
    });
    const init = async () => {
      if (store.state.token !== '') {
        const res = await getUserAsset({
          plate: 'asset',
          token: store.state.token
        });
        if (res.code === 200 && res.data) {
          assetList.value = res.data.map(item => {
            if (item.coin.coin_symbol === 'USDT') {
              assetUsdt.value = item.asset ? Number(item.asset.asset_active).toFixed(4) : 0;
            }
            if (klineKey.value !== '' && item.coin.coin_symbol === store.state.marketInfo[klineKey.value].stock) {
              typeAsset.value = item.asset ? Number(item.asset.asset_active).toFixed(4) : 0;
            }
            return {
              name: item.coin.coin_symbol,
              icon: process.env.VUE_APP_BASE_API + item.coin.coin_icon,
              total: item.asset ? item.asset.asset_total : 0,
              active: item.asset ? Number(item.asset.asset_active).toFixed(4) : 0
            };
          });
        }
        orderInit();
      }
    };
    const keyChnage = key => {
      if (key === 'LTJUSDT') {
        router.push(`/ieo/LTJUSDT`);
        return;
      }
      klineKey.value = key;
      buyForm.value.clear();
      sellForm.value.clear();
    };
    const orderInit = async (page = null) => {
      let data = {};
      if (tabActive.value === 0) {
        // 当前委托
        currentLoading.value = true;
        if (page) currentPage.value = page;
        data = {
          status: 1,
          page: currentPage.value
        };
      } else {
        historyLoading.value = true;
        if (page) historyPage.value = page;
        data = {
          status: '0,2',
          page: historyPage.value
        };
      }
      const res = await orderList(data);
      let list = [];
      if (res.code === 200 && res.data && res.data.list) {
        list = soptFmt(res.data.list);
      }
      if (tabActive.value === 0) {
        currentList.value = list;
        currentTotal.value = res.data.count;
        currentLoading.value = false;
      } else {
        historyList.value = list;
        historyTotal.value = res.data.count;
        historyLoading.value = false;
      }
    };
    const tabChange = index => {
      if (currentLoading.value || historyLoading.value) {
        return;
      }
      tabActive.value = index;
      orderInit();
    };
    const commit = form => {
      if (store.state.token === '') {
        router.push({
          name: 'login'
        });
        return;
      }
      if (tradeType.value === 1) {
        if (form.price === '' && Number(form.price) <= 0) {
          message.error(t('priceTip'));
          return;
        }
      }
      if (form.number === '' || Number(form.number) <= 0) {
        message.error(t('numberTip'));
        return;
      }
      if (form.side === 'buy') {
        if (Number(form.total) > form.max) {
          message.error(t('withdrawNumLess'));
          return;
        }
      } else {
        if (Number(form.number) > form.max) {
          message.error(t('withdrawNumLess'));
          return;
        }
      }
      Modal.confirm({
        title: t('tip'),
        content: createVNode('div', {
          style: ''
        }, t('tradeConfirm')),
        okText: t('confirmText'),
        okType: 'danger',
        cancelText: t('cancelText'),
        centered: true,
        onOk: async () => {
          const data = {
            market_id: store.state.marketInfo[klineKey.value].id,
            count: form.number === '' ? 0 : form.number,
            type: form.side,
            ex_type: 'ex',
            ex_password: '123123',
            deal_money: form.total
          };
          if (tradeType.value === 0) {
            data['trade_type'] = 'market';
          } else {
            data['trade_type'] = 'limit';
            data['price'] = form.price;
          }
          return await new Promise((resolve, reject) => {
            exchangeTrade(exType.ex, data).then(res => {
              if (res.code !== 200) {
                reject();
                message.error(res.message !== '' ? res.message : t('tradeError'));
                return;
              }
              message.success(t('tradeSuccess'));
              buyForm.value.clear();
              sellForm.value.clear();
              resolve();
              init();
            });
          });
        }
      });
    };
    onBeforeUnmount(() => {
      klineChange();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(TradeArea, {
        "kline-key": klineKey.value,
        height: "1050px",
        onChange: keyChnage,
        "key-list": _unref(store).state.marketKey
      }, {
        default: _withCtx(() => [_createVNode(TabBar, {
          "tab-list": [_unref(t)('marketPrice'), _unref(t)('limitPrice')],
          "tab-active": tradeType.value,
          onChange: _cache[0] || (_cache[0] = index => tradeType.value = index),
          style: {
            "padding": "20px 24px"
          }
        }, null, 8, ["tab-list", "tab-active"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(TradeForm, {
          ref_key: "buyForm",
          ref: buyForm,
          "use-num": `${assetUsdt.value}`,
          "use-stock": "USDT",
          stock: _unref(store).state.marketInfo[klineKey.value]?.stock,
          bg: "#03ad73",
          text: _unref(t)('buy'),
          "trade-type": tradeType.value,
          kline: klineKey.value,
          onCommit: commit
        }, null, 8, ["use-num", "stock", "text", "trade-type", "kline"])]), _createElementVNode("div", _hoisted_5, [_createVNode(TradeForm, {
          ref_key: "sellForm",
          ref: sellForm,
          "use-num": `${typeAsset.value}`,
          "use-stock": _unref(store).state.marketInfo[klineKey.value]?.stock,
          "trade-type": tradeType.value,
          stock: _unref(store).state.marketInfo[klineKey.value]?.stock,
          bg: "#d1514b",
          text: _unref(t)('sell'),
          kline: klineKey.value,
          side: "sell",
          onCommit: commit
        }, null, 8, ["use-num", "use-stock", "trade-type", "stock", "text", "kline"])])])]),
        _: 1
      }, 8, ["kline-key", "key-list"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(TabBar, {
        "tab-list": [_unref(t)('entrust'), _unref(t)('historyOrder')],
        "active-bg": "white",
        "tab-active": tabActive.value,
        onChange: tabChange
      }, null, 8, ["tab-list", "tab-active"]), _createElementVNode("div", {
        class: "right-btn",
        onClick: _cache[1] || (_cache[1] = $event => _unref(router).push({
          name: 'spotCurrent'
        }))
      }, _toDisplayString(_unref(t)('allOrder')), 1)]), _withDirectives(_createVNode(SpotTable, {
        data: currentList.value,
        "list-cnt": currentTotal.value,
        loading: currentLoading.value,
        current: true,
        onInit: init,
        onNext: orderInit,
        page: currentPage.value
      }, null, 8, ["data", "list-cnt", "loading", "page"]), [[_vShow, tabActive.value === 0]]), _withDirectives(_createVNode(SpotTable, {
        data: historyList.value,
        "list-cnt": historyTotal.value,
        loading: historyLoading.value,
        onInit: init,
        onNext: orderInit,
        page: historyPage.value
      }, null, 8, ["data", "list-cnt", "loading", "page"]), [[_vShow, tabActive.value === 1]])])])]);
    };
  }
};