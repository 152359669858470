import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-04c0c019"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "spot-table"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "color": "#03ad73"
  }
};
const _hoisted_3 = {
  key: 1,
  style: {
    "color": "#d1514b"
  }
};
import { createVNode } from 'vue';
import { Modal, message } from 'ant-design-vue';
import { exchangeCancel } from '@/apis';
import { exType, orderType, orderSide, orderStatus } from '@/constant';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'SpotTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    listCnt: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    }
  },
  emits: ['init', 'next'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const columns = [{
      name: 'time',
      key: 'time'
    }, {
      name: 'currencyType',
      key: 'name',
      align: 'center'
    }, {
      name: 'type',
      key: 'type',
      align: 'center'
    }, {
      name: 'direction',
      key: 'direction',
      align: 'center'
    }, {
      name: 'price',
      dataIndex: 'price',
      align: 'center'
    }, {
      name: 'number',
      dataIndex: 'count',
      align: 'center'
    }, {
      name: 'entrustTotal',
      dataIndex: 'deal',
      align: 'center'
    }, {
      key: 'action',
      align: 'right'
    }];
    const cancelOrder = id => {
      Modal.confirm({
        title: t('tip'),
        content: createVNode('div', {
          style: ''
        }, t('cancelTip')),
        okText: t('confirmText'),
        cancelText: t('cancelText'),
        centered: true,
        okType: 'danger',
        onOk: async () => {
          return await new Promise((resolve, reject) => {
            exchangeCancel(exType.ex, {
              id: id
            }).then(res => {
              if (res.code !== 200) {
                reject();
                message.error(res.message !== '' ? res.message : t('cancelError'));
                return;
              }
              message.success(t('cancelSuccess'));
              resolve();
              emit('init');
            });
          });
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_a_tag = _resolveComponent("a-tag");
      const _component_a_button = _resolveComponent("a-button");
      const _component_a_table = _resolveComponent("a-table");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_table, {
        columns: columns,
        dataSource: props.data,
        loading: props.loading,
        pagination: {
          pageSize: 10,
          total: props.listCnt,
          current: props.page,
          showSizeChanger: false,
          onChange: page => {
            emit('next', page);
          },
          showTotal: () => `${_unref(t)('total')} ${props.listCnt} ${_unref(t)('oneOrder')}`
        }
      }, {
        headerCell: _withCtx(({
          column
        }) => [column.key === 'action' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [!props.current ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createTextVNode(_toDisplayString(_unref(t)('status')), 1)], 64)) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createTextVNode(_toDisplayString(_unref(t)(column.name)), 1)], 64))]),
        bodyCell: _withCtx(({
          column,
          record
        }) => [column.key === 'time' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createTextVNode(_toDisplayString(record.time), 1)], 64)) : column.key === 'name' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createTextVNode(_toDisplayString(`${record.name}/${record.coin}`), 1)], 64)) : column.key === 'type' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 2
        }, [record.type === _unref(orderType).market ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createTextVNode(_toDisplayString(_unref(t)('marketPrice')), 1)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createTextVNode(_toDisplayString(_unref(t)('limitPrice')), 1)], 64))], 64)) : column.key === 'direction' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 3
        }, [record.side === _unref(orderSide).buy ? (_openBlock(), _createBlock(_component_a_tag, {
          key: 0,
          color: "#03ad73"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('buy')), 1)]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_a_tag, {
          key: 1,
          color: "#d1514b"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('sell')), 1)]),
          _: 1
        }))], 64)) : column.key === 'action' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 4
        }, [props.current ? (_openBlock(), _createBlock(_component_a_button, {
          key: 0,
          danger: "",
          onClick: $event => cancelOrder(record.id)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('cancelOrder')), 1)]),
          _: 2
        }, 1032, ["onClick"])) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [record.status === _unref(orderStatus).complete ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)('completed')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(t)('canceled')), 1))], 64))], 64)) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["dataSource", "loading", "pagination"])]);
    };
  }
};