export default {
  required: '必填',
  home: '首頁',
  market: '行情',
  currency: '幣幣',
  contract: '合約',
  fastTrade: '秒合約',
  ieoBuy: 'IEO申購',
  wallet: '錢包',
  order: '訂單', 
  login: '登錄',
  register: '註冊',
  welcome: '歡迎登錄',
  email: '郵箱',
  emailInput: '請輸入郵箱',
  loginPwd: '登錄密碼',
  loginPwdTip: '請輸入登錄密碼',
  loginPwdLen: '登錄密碼應為8-20位字符',
  saveAccount: '保存賬號密碼',
  forgetPwd: '忘記密碼',
  retrievePwd: '找回密碼',
  loginError: '登錄失敗',
  loginSuccess: '登錄成功',
  registerTitle: '創建賬戶', 
  emailCheck: '郵箱驗證碼', 
  checkCodeInput: '請輸入郵箱驗證碼',
  sendCheckCode: '發送驗證碼',
  financePwd: '資金密碼',
  financePwdLen: '資金密碼應為6-20位字符',
  financePwdTip: '請輸入資金密碼',
  inviteCode: '邀請碼',
  inviteCodeTip: '請輸入邀請碼',
  haveAccount: '已有賬號？', 
  toLogin: '去登錄',
  registerTip: '我已閲讀並同意', 
  agree: '《用戶協議》',
  agreeText: '用戶協議',
  agreeTip: '請勾選用戶協議',
  sendSuccess: '發送成功',
  sendError: '發送失敗',
  registerSuccess: '註冊成功',
  registerError: '註冊失敗',
  loadingError: '加載失敗',
  confirmPwd: '確認密碼',
  confirmPwdInput: '請輸入確認密碼',
  confirmText: '確認',
  cancelText: '取消',
  noAlike: '密碼輸入不一致',
  updatePwdSuccess: '密碼修改成功',
  updatePwdError: '密碼修改失敗',
  userInfo: '個人信息', 
  securitySet: '安全設置',
  userAuth: '身份認證', 
  logout: '退出登錄',
  logoutSuccess: '退出成功',
  tipText: '提示',
  ieoTip: '即將開啟，敬請期待！',
  footerTip: '最終解釋權歸本平台所有', 
  aboutUs: '關於我們',
  bulletin: '公告',
  userAgree: '用戶支持',
  contact: '聯繫我們',
  marketInfo: '市場行情', 
  name: '名稱',
  fastPrice: '最新價',
  trend: '24H涨跌',
  tradeVol: '交易額',
  viewMore: '查看更多', 
  buy: '買入',
  sell: '賣出',
  num24H: '24H量',
  high24H: '24H高',
  low24H: '24H低',
  tradeText: '交易', 
  growthList: '漲幅榜',
  loserList: '跌幅榜',
  all: '全部',
  currencyType: '幣種',
  minute: '分鐘',
  hour: '小時',
  day: '天',
  week: '週',
  position: '盤口', 
  level: '檔位', 
  price: '價格',
  number: '數量',
  oneBuy: '買', 
  oneSell: '賣', 
  open: '開',
  close: '收',
  high: '高',
  low: '低',
  volume: '成交量',
  time: '時間',
  newTrade: '實時成交',
  marketPrice: '市價',
  limitPrice: '限價',
  useable: '可用',
  optimal: '以當前最優價格交易',
  tradePair: '交易對', 
  type: '類型',
  direction: '方向',
  entrustTotal: '委託總額',
  entrust: '當前委託',
  historyEntrust: '歷史委託', 
  currentOrder: '當前訂單',
  historyOrder: '歷史訂單',
  allOrder: '訂單記錄', 
  cancelOrder: '撤單',
  cancelError: '撤單失敗',
  cancelSuccess: '撤單成功',
  status: '狀態',
  total: '共', 
  oneOrder: '單', 
  sl: '止損',
  tp: '止盈',
  completed: '已完成',
  canceled: '已取消',
  tip: '溫馨提示',
  cancelTip: '確認要撤銷委託？',
  priceTip: '請輸入價格', 
  numberTip: '請輸入數量', 
  withdrawNumLess: '餘額不足',
  tradeConfirm: '確認交易？',
  tradeError: '交易失敗',
  tradeSuccess: '交易成功',
  confirmToBuy: '買漲',
  confirmToSell: '賣空',
  unwindPrice: '平倉價格',
  setting: '設置',
  unwind: '平倉',
  handUnwind: '手動平倉',
  futuresPay: '',
  forceUnwind: '強平',
  slTp: '止盈止損',
  fastTradeUnwind: '秒合約平倉',
  blowUp: '爆倉',
  setError: '設置失敗',
  setSlTp: '設置止盈/止損',
  multiple: '倍數',
  openNum: '開倉數量',
  openPrice: '開倉價格',
  warrant: '擔保資產',
  fee: '手續費',
  profit: '盈虧',
  tradePriceTip: '請輸入交易額',
  unwindError: '平倉失敗',
  unwindSuccess: '平倉成功',
  setSuccess: '設置成功',
  second: '秒',
  profitText: '收益', 
  currentPrice: '現價',
  foundInput: '輸入金額', 
  foundTip: '請輸入買入金額', 
  closePrice: '結算價',
  countdown: '倒計時', 
  toBuy: '買漲',
  toSell: '賣空',
  loss: '虧',
  fill: '盈',
  secondNum: '週期',
  executePrice: '執行價格',
  money: '金額',
  tradeMoney: '交易金額', 
  completePrice: '成交價', 
  profitAndLoss: '预期收益和亏损', 
  walletOverview: '錢包總覽', 
  currencyAccount: '幣幣賬戶',
  contractAccount: '合約賬戶',
  ieoBuyAccount: 'IEO申購賬戶', 
  recharge: '充值',
  transfer: '劃轉',
  withdraw: '提現',
  balanceTotal: '總賬戶資產折合',
  freeze: '凍結',
  amountTo: '折合',
  balanceText: '流水', 
  selectCurrency: '請選擇幣種',
  selectRechargeAddress: '選擇充值地址', 
  rechargeNet: '充值網絡',
  rechargeAddress: '充值地址',
  copyAddress: '複製地址',
  minRecharge: '最小充值金額',
  copySuccess: '複製成功',
  copyError: '複製失敗',
  rechargeHistory: '充值記錄',
  withdrawHistory: '提現記錄',
  successText: '成功', 
  errorText: '失敗', 
  orderNum: '訂單號',
  balanceUse: '可用餘額',
  withdrawMin: '最小提現金額',
  actualMoney: '實際到賬金額約',
  selectWithdrawAddress: '輸入提現地址', 
  withdrawAddress: '提現地址',
  withdrawAddressInput: '請輸入提現地址',
  withdrawNet: '提現網絡',
  withdrawNum: '提現金額',
  withdrawNumInput: '請輸入提現金額',
  next: '下一步',
  waitReview: '待審核',
  reviewPass: '審核通過',
  reviewReject: '審核拒絕',
  waitWallet: '待审核',
  walletPass: '錢通過',
  walletReject: '拒絕',
  withdrawSuccess: '提現成功',
  withdrawError: '轉賬失敗',
  securityCheck: '安全驗證',
  finallyIncome: '實際到賬',
  commitMoney: '提交金額',
  withdrawFail: '提現失敗',
  withdrawSuText: '提交成功', 
  from: '從',
  to: '到',
  transferMoneyLabel: '劃轉金額', 
  transferMoney: '請輸入劃轉金額',
  transferSuccess: '劃轉成功',
  transferError: '劃轉失敗',
  transferHistory: '劃轉記錄',
  balanceTyle: '資產類型',
  lastBalance: '當前餘額',
  pay: '支出',
  income: '收入',
  paramsError: '參數錯誤', 
  reset: '重置', 
  selectText: '查詢', 
  emailUpdate: '郵箱修改',
  loginPwdUpdate: '登錄密碼修改',
  financePwdUpdate: '資金密碼修改',
  authSuccess: '已認證',
  noPass: '審核未通過',
  authCommit: '提交待審核',
  onAuth: '未認證',
  fullname: '姓名',
  account: '賬號',
  idNo: '證件號碼',
  inviteUrl: '我的邀請鏈接',
  myInviteCode: '我的邀請碼',
  emailManage: '郵箱管理', 
  loginPwdDes: '用於管理您的賬戶登錄密碼', 
  updateText: '更改', 
  financePwdDes: '用於管理您的賬戶資金密碼', 
  emailManageDes: '保護您的交易和賬戶安全', 
  oldEmail: '原郵箱', 
  newEmail: '新郵箱',
  emailUpdateTip: '輸入新的郵箱賬號',
  checkError: '驗證失敗',
  updateEmailError: '郵箱修改失敗',
  updateEmailSuccess: '郵箱修改成功',
  authAgain: '重新認證',
  countryArea: '國家/地區',
  idNoTip: '請輸入證件號碼',
  countryAreaTip: '請輸入國家/地區',  
  fullnameTip: '請輸入姓名', 
  commit: '提交',
  imgFront: '請上傳證件正面照片',
  imgReverse: '請上傳證件反面照片',
  commitSuccess: '提交成功',
  commitError: '提交失敗',
  indicator: '指標',
  volume24h: '24H 交易量',
  // 首页
  currencyHomeType: '幣種',
  whyUs: '為什麼選擇我們？',
  why1: '安全和保險',
  why1Des: '我們將 80% 的加密貨幣存儲在我們的安全冷錢包中。 冷錢包由 Ledger 提供保護和保險。',
  why2: '快速交易',
  why2Des: '我們可以處理數百萬筆交易。 我們的系統基礎設施可以在幾秒鐘內擴展以滿足不斷增長的需求。',
  why3: '簡單易用',
  why3Des: '在我們的平台上交易是一種超快速的體驗。 我們建立了數百萬人使用的技術，這些經驗幫助我們建立了這個強大的交易所。',
  why4: '即時交易',
  why4Des: '單擊一下即可交易 150 多種即時加密貨幣。',
  why5: '最優的價格',
  why5Des: '交易所有具有高流動性的加密貨幣。',
  why6: '信任',
  why6Des: '超過 80 萬用戶相信我們並交易了數十億。',
  homeGift: '免費獲得 30 美元用於交易註冊贈金。',
  popular1: '100,000 美元+',
  popular1Des: '總交易量',
  popular2: '150+',
  popular2Des: '支持貨幣',
  popular3: '800K+',
  popular3Des: '驗證用戶',
  globalText: '全球超過 80 萬用戶信賴加密貨幣交易',
  startText: '只需 5 分鐘即可開始。',
  start1: '註冊',
  start1Des: '打開網站或下載應用程序，並使用電子郵件註冊。',
  start2: 'KYC',
  start2Des: '將您的身份證明放在手邊，輸入基本的 KYC 詳細信息並驗證您的帳戶。',
  start3: '存款',
  start3Des: '輕鬆存入您的數字錢包。',
  start4: '買賣',
  start4Des: '以最優惠的價格買賣和交易加密貨幣。',
  secondTip: '金額不能少於{tip}',
  appTitle: '應用程序現已可用',
  appDes: '直接在您的智能手機上獲得安全的、兼容的、受監管的加密貨幣交易平台。',
  withdrawTip: '實名認證後才可提現',
  actualMoneyText: '到賬金額',
  commitTime: '提交時間',
  timeUp: '時間到',
  closeAll: '一鍵全平',
  assetRate: '資金比例',
  cancel: '撤銷',
  cancelInfo: '確認撤銷？',
  date: '日期',
  buyCurrency: '認購幣',
  closeCurrency: '結算幣種',
  buyPrice: '認購價',
  valuation: '當前估值',
  profitP: '收益率',
  buySuccess: '認購成功',

  swiperTitle1: '全球頂尖數字貨幣交易所',
  swiperSub1: '超過十萬用戶的信賴',

  ieoBuying: '認購中',
  ieoOpen: '開始日期',
  ieoClose: '截止日期',
  about: '關於',
  ieoPlan: '分配計畫',
  ieoInfo: '認購信息',
  ieoContact: '社交媒體',
  ieoStart: '項目啟動',
  ieoBuyStart: '申購開始',
  ieoBuyEnd: '申購結束',
  ieoIsTrade: '上市交易',
  introduce: '簡介',
  ieoSubBuy: '認購',
  subBuyHistory: '認購歷史',
  createTeam: '儲備基金',
  angelFund: '天使基金',
  ecology: '機構與生態系統',
  presale: '預售發行',
  mechanism: '挖礦機制',
  trading: '上市交易',

  issuePrice: '發行價',
  minBuyPrice: '最低認購額',
  buyNum: '每天認購次數',
  buyTotal: '每天認購總額',
  Increment: '認購遞增量',
  blockchain: '區塊鏈',

  seedSell: '種子輪',
  privateSell: '私募基金',
  openSell: 'KOL',
  ieoAward: '公開發售',
  ieoGrowUp: '團隊',
  partner: '顧問',
  consultant: '市場營銷',
  ieoName: '流動性',
  conAward: 'AI To Earn',
  staking: '質押',

  sellTotal: '發行總量',
  currentBuy: '當前認購量',
  dowload: '下載中心',
  lessMinTip: '不能低於最低認購額',
  rechargeTip1: '1、此地址是你最新和獨一無二的充值地址，平台在任何情況下都不會要求你向某一個賬戶轉賬，也不會向你索要驗證碼。',
  rechargeTip2: '2、充值需要至少10個網絡區塊進行確認，確認後，你的賬號將自動收到該筆充值資金。',
  withdrawTip1: '1、請確保你選擇的提幣網絡和你在相對應的該筆充幣所選擇的網絡一致，請確保平台支持你選擇的網絡，否則提幣將無法到賬或退回。',
  withdrawTip2: '2、平台審核通過你的提幣申請後，將提交給網絡區塊確認。',
  withdrawTip3: '3、提幣需要至少10個網絡區塊進行確認，確認後，你將自動收到該筆提幣資金。',
  footerBottomTip: '本網站提供的信息不構成投資建議、財務建議、交易建議或任何其他類型的建議，你不應將本網站的任何內容視為此類內容。加密貨幣投資具有波動性和高風險性。不要投資超過你能承受的損失。',

  stakeAccount: '質押賬戶',
  serviceAccount: '服務賬戶',
  lock: '锁定',
  lockPrice: '锁定价格',
  ieoHistory: '認購記錄',
  lockHistory: '鎖定記錄',
  sellHistory: '賣出記錄',
  stake: '质押',
  service: '服务',
  ieoBuyTip: '*IEO提示：LTJ數量有限。我們將審核用戶的IEO申請並每3小時隨機抽籤一次。金額較大的用戶購買LTJ的概率較高。每個用戶每天最多可以購買3次。',
  ieoLockTip: '*鎖定價格：用戶需要使用USDT作為質押幣種，才可以鎖定LTJ的價格，質押比例為LTJ價值的30%。 LTJ上市後，用戶可按當時鎖定的價格將LTJ賣出。',
  ieoSellTip: '*認購賣出：賣出LTJ需要收取LTJ價值的10%作為服務費，賣出的LTJ後，你兌換的USDT將於20天后解凍，質押的USDT將於20天后解凍。',
  whitePaper: '白皮書',
  cost: '成本',
  currentEstimate: '當前估值',
  stakeNum: '質押數量',
  estimate: '估值',
  consume: '消耗',
  release: '釋放',
  closedTime: '平倉時間',
  remark: '備註',

  totalProfit: '總盈虧',
  transferIn: '轉入',
  transferOut: '轉出',
  equity: '淨值',


  
  homeremind1: '流行的加密貨幣',
  homeremind2: '看更多市場',
  homeremind3: '最新價格(USD)',
  homeremind4: '24小時變化',
  homeremind5: '行動',
  homeremind6: '看更多',
  homeremind7: "用戶的最佳選擇",
  homeremind8: '24小時交易量',
  homeremind9: '優質虛擬資產',
  homeremind10: '我們的核心產品',
  homeremind11: '多種交易工具可供選擇',
  homeremind12: '法幣',
  homeremind13: '超過 700 種加密貨幣可供交易',
  homeremind14: '透過合约交易提升您的體驗',
  homeremind15: 'NYECoins Earn',
  homeremind16: '業界最佳 APY',
  homeremind17: '我們的多元化服務',
  homeremind18: '透過我們多元化的服務探索無限可能',
  homeremind19: 'IEO認購',
  homeremind20: "最有價值的數位貨幣",
  homeremind21: '專屬客服服務',
  homeremind22: '7*24線上客服',
  homeremind23: '社群',
  homeremind24: '與我們一起發現財富機會',
  homeremind25: '隨時隨地進行交易',
  homeremind26: '透過我們的行動應用程式和網路用戶端隨時了解情況',
  homeremind27: '在社群媒體上關注我們',
  homeremind28: '隨時了解我們的最新消息',
  homeremind29: '隨時隨地進行交易',
  homeremind30: '使用您的 iOS、Android 或 API 嘗試 NYECoins。',

  msb: 'NYECoins 受美國 MSB 監管。',
  msb1: '值得信賴、安全、快捷',
  checkNow: '現在檢查',

  foundingTeam: '創始團隊',
  advisorTeam: '顧問團隊',
  community: '社群',

  phone: '電話號碼',
  phoneTip: '輸入您的電話號碼',








  rebateAccount: '佣金帳戶',
  rebateType: '佣金類型',
  recommend: '我的推薦',
  myRebate: '我的佣金',
  rebateRule: '返傭規則',
  yes: '是',
  no: '否',
  authName: '認證',
  countryName: '國家/地區',
  allCommission: '總佣金',
  myClient: '我的客戶',
  inviteUrl1: '推薦連結',
  inviteCode1: '推薦碼',
  commissioned: '佣金',

  swiperTitle2: '線上加密貨幣交易的全球領導者。 購買、出售和持有加密貨幣。',
  swiperSub2: '我們已經指導了數百萬全球用戶的數位資產之旅。',

  homeremind31: '我們做什麼？',
  homeremind32: '我們希望模糊加密貨幣商人和普通消費者之間的界線。 我們將透過免費入場、幫助人們理解和信任我們所相信的貨幣的未來來普及加密貨幣。',
  homeremind33: 'NYECOINS的特點',
  homeremind34: 'NYECOINS將賣家和買家聚集在一起，無需中央銀行或單一管理員，以確保交易清晰公平',
  homeremind35: '降低交易成本',
  homeremind36: '一個去中心化的平台，可供客戶以較低的交易付款和營運費用使用。',
  homeremind37: 'C2C交易',
  homeremind38: 'NYECOINS的C2C操作簡單、快速且安全。將BTC或您選擇的貨幣發送給平台上的任何客戶。',
  homeremind39: '品牌高級工具包',
  homeremind40: '我們透過為公司提供了解客戶的工具和洞察力來優化消費者定位。',
  homeremind41: '全球合作夥伴的支持',
  homeremind42: 'NYECOINS團隊',
  homeremind43: '讓我們向您介紹將使這一切實現的團隊。',
  homeremind44: 'Mark Nichols',
  homeremind45: '共同創辦人',
  homeremind46: 'Edward Blankenship',
  homeremind47: '資深軟體工程師',
  homeremind48: 'Richard Norman',
  homeremind49: '社群管理員',
  homeremind50: 'Paul Garrett',
  homeremind51: 'UI/UX設計師',



  ieoIntro1: '流動性激勵',
  ieoIntro2: '質押儲備',
  ieoIntro3: '生態系合作夥伴',
  ieoIntro4: '公開發售',
  ieoIntro5: '私人發售A',
  ieoIntro6: '種子輪',
  ieoIntro7: 'LinkToJoin團隊',
  ieoIntro8: '企業費用儲備',
}