import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less'
import i18n from './language/i18n'

router.beforeEach((to, from, next) => {
  if (to.meta.login) {
    if (store.state.token === '') {
      
      if(to.fullPath != '/contract' && to.fullPath != '/contract_sec' && to.fullPath != '/ieo/LTJUSDT'){
        router.push({ name: 'login' })
        return
      }
    }
  }
  next()
})

if (window.innerWidth < 750) {
  if (window.location.pathname === '/register') {
    const strArr = window.location.href.split('?')
    let url = 'https://nyecoins.com/register'
    if (strArr[1]) {
      url = `${url}?${strArr[1]}`
    }
    window.open(url, '_self')
  } else {
    window.open('https://nyecoins.com/home', '_self')
  }
} else {
  createApp(App).use(store).use(router).use(i18n).mount('#app')
}

