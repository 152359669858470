import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ea4a282"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "data-block"
};
const _hoisted_2 = {
  class: "center"
};
const _hoisted_3 = {
  class: "right"
};
import { ref, watch, onBeforeUnmount, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'DataBlock',
  props: {
    price: {
      type: String,
      default: '0'
    },
    max: {
      type: Number,
      default: 10
    },
    min: {
      type: Number,
      default: 1
    },
    index: {
      type: Number,
      default: 0
    },
    decimal: {
      type: Number,
      default: 2
    },
    colorL: {
      type: String
    },
    bg: {
      type: String
    },
    top: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    const {
      t
    } = useI18n();
    const num = ref(0);
    const bgWidth = ref('0%');
    const last = ref(0);
    const no = ref(Number(props.index));
    if (props.top) {
      no.value = Math.floor(18 - props.index);
    }
    onMounted(() => {
      count();
    });
    const lastWatch = watch(() => props.price, () => {
      count();
    });
    const count = () => {
      num.value = (Math.random() * (props.max - props.min) + props.min).toFixed(2);
      bgWidth.value = (num.value / props.max * 100).toFixed(2) + '%';
      const lastNum = Number(props.price) - no.value * 0.01 - Number((Math.random() * 0.01).toFixed(4));
      last.value = lastNum > 0 ? lastNum.toFixed(props.decimal) : 0;
    };
    onBeforeUnmount(() => {
      lastWatch();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "left",
        style: _normalizeStyle({
          color: props.colorL
        })
      }, [props.top ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(_toDisplayString(_unref(t)('oneSell')), 1)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(_toDisplayString(_unref(t)('oneBuy')), 1)], 64)), _createTextVNode(" " + _toDisplayString(no.value), 1)], 4), _createElementVNode("div", _hoisted_2, _toDisplayString(last.value), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(num.value), 1), _createElementVNode("div", {
        class: "data-block-bg",
        style: _normalizeStyle({
          backgroundColor: props.bg,
          width: bgWidth.value
        })
      }, null, 4)]);
    };
  }
};