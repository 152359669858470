import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a5bb3bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "width": "100%",
    "display": "flex",
    "flex-direction": "column",
    "align-items": "center"
  }
};
const _hoisted_2 = {
  key: 1,
  class: "area-l area-side"
};
const _hoisted_3 = {
  class: "header"
};
const _hoisted_4 = {
  class: "header-item left"
};
const _hoisted_5 = {
  class: "header-item center"
};
const _hoisted_6 = {
  class: "header-item right"
};
const _hoisted_7 = {
  class: "data-list"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  class: "left",
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_10 = ["src"];
const _hoisted_11 = {
  class: "area-center"
};
const _hoisted_12 = {
  class: "side"
};
const _hoisted_13 = {
  class: "content"
};
const _hoisted_14 = {
  class: "top"
};
const _hoisted_15 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  style: {
    "font-size": "14px",
    "color": "#147797",
    "cursor": "pointer",
    "margin-right": "30px"
  }
};
const _hoisted_18 = {
  style: {
    "padding": "16px",
    "box-shadow": "0 2px 12px rgba(50,50,51,.12)",
    "background-color": "white"
  }
};
const _hoisted_19 = {
  key: 2,
  class: "area-r area-side"
};
const _hoisted_20 = {
  class: "header"
};
const _hoisted_21 = {
  class: "header-item left"
};
const _hoisted_22 = {
  class: "header-item center"
};
const _hoisted_23 = {
  class: "header-item right"
};
const _hoisted_24 = {
  class: "header"
};
const _hoisted_25 = {
  class: "header-item left"
};
const _hoisted_26 = {
  class: "header-item center"
};
const _hoisted_27 = {
  class: "header-item right"
};
const _hoisted_28 = {
  class: "right"
};
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import store from '@/store';
import TradeHeader from '@/components/TradeHeader.vue';
import DataBlock from '@/components/DataBlock.vue';
import ChartToolsBar from '@/components/ChartToolsBar.vue';
import { dispose, init } from 'klinecharts';
import wssId from '@/util/wssId';
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import { exchangeRobot } from '@/apis';
import { useI18n } from 'vue-i18n';
import { fmtNumber } from '@/util';
export default {
  __name: 'TradeArea',
  props: {
    klineKey: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '980px'
    },
    keyList: {
      type: Array,
      default: () => []
    },
    ieo: {
      type: Boolean,
      default: false
    },
    klineHeight: {
      type: String,
      default: '560px'
    },
    active: {
      type: Number,
      default: 2
    }
  },
  emits: ['change'],
  setup(__props, {
    expose,
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const decimal = ref(8);
    const maxNum = ref(0);
    const minNum = ref(0);
    const klineKeyWatch = watch(() => props.klineKey, async () => {
      if (wssConnect && wss) {
        wss.send(JSON.stringify({
          id: wssId['kline.unsubscribe'],
          method: 'kline.unsubscribe',
          params: []
        }));
        first = true;
        now = dayjs().unix();
        klineMore();
      }
      const {
        id,
        decimal: decimalNum
      } = store.state.marketInfo[props.klineKey];
      if (id) {
        decimal.value = decimalNum;
        const res = await exchangeRobot({
          market_id: id
        });
        if (res.code === 200) {
          maxNum.value = res.data[0] ? Number(res.data[0].robot_max_amount) : 0;
          minNum.value = res.data[0] ? Number(res.data[0].robot_min_amount) : 0;
        }
      }
    });
    let wss = null;
    let now = dayjs().unix();
    let wssConnect = false;
    let first = true;
    const last = ref('0.00');
    let wssHeart = null;

    // 指标
    const indicatorList = ref([]);
    const showPopover = ref(false);
    let indicatorKey = {};
    const dealList = ref([]);
    let dealTimer = null;
    let leaving = false;
    const activeKey = ref('0');
    let chart = null;
    const klineActive = ref(0);
    const klineSecond = [60, 300, 900, 1800, 3600, 86400, 604800];
    const fmtTime = ts => {
      return dayjs(ts * 1000).format('HH:mm:ss');
    };
    const timeChange = index => {
      klineActive.value = index;
      wss.send(JSON.stringify({
        id: wssId['kline.unsubscribe'],
        method: 'kline.unsubscribe',
        params: []
      }));
      first = true;
      now = dayjs().unix();
      klineMore();
    };
    let klineLoading = false;
    const klineMore = () => {
      if (klineLoading) {
        setTimeout(klineMore, 1000);
        return;
      }
      klineLoading = true;
      if (props.klineKey !== '' && wssConnect && wss) {
        const diff = BigNumber(klineSecond[klineActive.value]).multipliedBy(300).toNumber();
        const form = BigNumber(now).minus(diff).toNumber();
        wss.send(JSON.stringify({
          id: wssId['kline.query'],
          method: 'kline.query',
          params: [props.klineKey, form, now, klineSecond[klineActive.value]]
        }));
      } else {
        klineLoading = false;
      }
    };
    const chartDraw = shapeName => {
      chart.createShape(shapeName);
    };
    const chartClear = () => {
      chart.removeShape();
    };
    let eee = () => {
      chart.setPriceVolumePrecision(store.state.marketInfo[props.klineKey].decimal, store.state.marketInfo[props.klineKey].decimal);
    };
    expose({
      eee
    });
    onMounted(() => {
      klineActive.value = props.active;
      chart = init('kline-chart', {
        candle: {
          tooltip: {
            showRule: 'follow_cross',
            showType: 'rect',
            labels: [t('time'), t('open'), t('close'), t('high'), t('low'), t('volume')]
          }
        }
      });
      chart.setTimezone('America/New_York');
      chart.setOffsetRightSpace(20);
      chart.createTechnicalIndicator({
        name: 'VOL',
        calcParams: [10],
        styles: {
          margin: {
            top: 0.2,
            bottom: 2
          }
        }
      });
      chart.createTechnicalIndicator({
        name: 'MA',
        shortName: 'MA',
        calcParams: [10, 20, 30],
        dragEnabled: true,
        plots: [{
          key: 'ma1',
          title: 'MA10: ',
          type: 'line'
        }, {
          key: 'ma2',
          title: 'MA20: ',
          type: 'line'
        }, {
          key: 'ma3',
          title: 'MA30: ',
          type: 'line'
        }],
        regeneratePlots: params => {
          return params.map((p, i) => {
            return {
              key: `ma${i + 1}`,
              title: `MA${p}: `,
              type: 'line'
            };
          });
        }
      }, true, {
        id: 'candle_pane',
        dragEnabled: true
      });
      chart.loadMore(klineMore);
      wssInit();
    });
    const dealSend = () => {
      dealTimer = setInterval(() => {
        if (wssConnect && wss) {
          wss.send(JSON.stringify({
            id: wssId['deals.query'],
            method: 'deals.query',
            params: [props.klineKey, 30]
          }));
        }
      }, 2000);
    };
    let closeHeart = null;
    const wssInit = () => {
      clearInterval(wssHeart);
      clearInterval(dealTimer);
      wss = new WebSocket(process.env.VUE_APP_WSS);
      wss.onopen = () => {
        wssConnect = true;
        klineMore();
        dealSend();
        wssHeart = setInterval(() => {
          if (wssConnect && wss) {
            wss.send(1);
          }
        }, 10000);
      };
      wss.onmessage = res => {
        const data = JSON.parse(res.data);
        if (data.method) {
          switch (data.method) {
            case 'kline.query':
              if (first) {
                const klineInfo = store.state.marketInfo[props.klineKey];
                if (klineInfo) {
                  chart.setPriceVolumePrecision(klineInfo.decimal, klineInfo.decimal);
                }
                chart.applyNewData(data.result.map((item, index) => {
                  if (index == data.result.length - 1)
                    //k线和update刚开始同步显示
                    last.value = Number(item[2]);
                  return {
                    timestamp: Number(item[0] * 1000),
                    open: Number(item[1]),
                    high: Number(item[3]),
                    low: Number(item[4]),
                    close: Number(item[2]),
                    volume: Number(item[5])
                  };
                }), data.result.length >= 300);
                if (data.result.length > 0) {
                  now = BigNumber(data.result[0][0]).minus(klineSecond[klineActive.value]).toNumber();
                }
                first = false;
                wss.send(JSON.stringify({
                  id: wssId['kline.subscribe'],
                  method: 'kline.subscribe',
                  params: [props.klineKey, klineSecond[klineActive.value]]
                }));
              } else {
                chart.applyMoreData(data.result.map(item => {
                  return {
                    timestamp: Number(item[0] * 1000),
                    open: Number(item[1]),
                    high: Number(item[3]),
                    low: Number(item[4]),
                    close: Number(item[2]),
                    volume: Number(item[5])
                  };
                }), data.result.length >= 300);
                if (data.result.length > 0) {
                  now = BigNumber(data.result[0][0]).minus(klineSecond[klineActive.value]).toNumber();
                }
              }
              klineLoading = false;
              break;
            case 'kline.update':
              if (data.params[0]) {
                last.value = data.params[0][2];
                chart.updateData({
                  timestamp: Number(data.params[0][0] * 1000),
                  open: Number(data.params[0][1]),
                  high: Number(data.params[0][3]),
                  low: Number(data.params[0][4]),
                  close: Number(data.params[0][2]),
                  volume: Number(data.params[0][5])
                });
              }
              break;
            case 'deals.query':
              dealList.value = data.result;
              break;
          }
        }
      };
      wss.onclose = () => {
        if (!leaving) {
          clearInterval(wssHeart);
          wssConnect = false;
          clearInterval(dealTimer);
          closeHeart = setTimeout(() => {
            if (!leaving) {
              wssInit();
            }
          }, 5000);
        }
      };
    };
    const langWatch = watch(() => store.state.lang, () => {
      chart.setStyleOptions({
        candle: {
          tooltip: {
            labels: [t('time'), t('open'), t('close'), t('high'), t('low'), t('volume')]
          }
        }
      });
    });
    const indicatorChange = name => {
      if (indicatorList.value.includes(name)) {
        indicatorKey[name] = chart.createTechnicalIndicator(name, false, {
          height: 90
        });
      } else {
        if (indicatorKey[name]) {
          chart.removeTechnicalIndicator(indicatorKey[name]);
        }
      }
    };
    onBeforeUnmount(() => {
      leaving = true;
      clearTimeout(closeHeart);
      dispose('kline-chart');
      wssConnect = false;
      try {
        wss.close();
      } finally {
        langWatch();
        clearInterval(dealTimer);
        clearInterval(wssHeart);
        klineKeyWatch();
      }
    });
    return (_ctx, _cache) => {
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
      const _component_a_dropdown = _resolveComponent("a-dropdown");
      const _component_a_tab_pane = _resolveComponent("a-tab-pane");
      const _component_a_tabs = _resolveComponent("a-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!__props.ieo ? (_openBlock(), _createBlock(TradeHeader, {
        key: 0,
        name: _unref(store).state.marketInfo[props.klineKey]?.name,
        last: last.value,
        growth: _unref(store).state.market[props.klineKey]?.growth,
        up: _unref(store).state.market[props.klineKey]?.up,
        high: _unref(store).state.market[props.klineKey]?.high,
        low: _unref(store).state.market[props.klineKey]?.low,
        vol: _unref(store).state.market[props.klineKey]?.volume
      }, null, 8, ["name", "last", "growth", "up", "high", "low", "vol"])) : _renderSlot(_ctx.$slots, "trade-header", {
        key: 1
      }), _createElementVNode("div", {
        class: "trade-area",
        style: _normalizeStyle({
          '--height': props.height
        })
      }, [__props.ieo ? _renderSlot(_ctx.$slots, "ieo", {
        key: 0
      }) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('currencyType')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('fastPrice')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('trend')), 1)]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.keyList, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "data-item",
          key: index,
          onClick: $event => emit('change', item),
          style: _normalizeStyle({
            background: props.klineKey === item ? '#f9f9f9' : ''
          })
        }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("img", {
          src: _unref(store).state.marketInfo[item]?.icon,
          style: {
            "width": "17px",
            "margin-right": "6px"
          }
        }, null, 8, _hoisted_10), _createElementVNode("span", null, _toDisplayString(_unref(store).state.marketInfo[item]?.name), 1)]), _createElementVNode("div", {
          class: "center",
          style: _normalizeStyle({
            color: _unref(store).state.market[item]?.up ? '#04AC92' : '#EC2752'
          })
        }, _toDisplayString(_unref(store).state.market[item]?.last), 5), _createElementVNode("div", {
          class: "right",
          style: _normalizeStyle({
            color: _unref(store).state.market[item]?.up ? '#04AC92' : '#EC2752'
          })
        }, _toDisplayString(_unref(store).state.market[item]?.growth) + "%", 5)], 12, _hoisted_8);
      }), 128))])])), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", {
        class: "kline",
        style: _normalizeStyle({
          paddingTop: props.ieo ? '5px' : '0px'
        })
      }, [_createElementVNode("div", _hoisted_12, [_createVNode(ChartToolsBar, {
        onDraw: chartDraw,
        onClear: chartClear
      })]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList([`1${_unref(t)('minute')}`, `5${_unref(t)('minute')}`, `15${_unref(t)('minute')}`, `30${_unref(t)('minute')}`, `1${_unref(t)('hour')}`, `1${_unref(t)('day')}`, `1${_unref(t)('week')}`], (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "time-item",
          style: _normalizeStyle({
            color: klineActive.value === index ? '#147797' : ''
          }),
          onClick: $event => timeChange(index),
          key: index
        }, _toDisplayString(item), 13, _hoisted_16);
      }), 128))]), _createVNode(_component_a_dropdown, {
        visible: showPopover.value,
        "onUpdate:visible": _cache[5] || (_cache[5] = $event => showPopover.value = $event),
        placement: "bottom"
      }, {
        overlay: _withCtx(() => [_createElementVNode("div", _hoisted_18, [_createVNode(_component_van_checkbox_group, {
          modelValue: indicatorList.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => indicatorList.value = $event)
        }, {
          default: _withCtx(() => [_createVNode(_component_van_checkbox, {
            shape: "square",
            "icon-size": "18",
            name: "MACD",
            style: {
              "margin-bottom": "10px"
            },
            onClick: _cache[0] || (_cache[0] = $event => indicatorChange('MACD'))
          }, {
            default: _withCtx(() => [_createTextVNode("MACD")]),
            _: 1
          }), _createVNode(_component_van_checkbox, {
            shape: "square",
            "icon-size": "18",
            name: "BOLL",
            style: {
              "margin-bottom": "10px"
            },
            onClick: _cache[1] || (_cache[1] = $event => indicatorChange('BOLL'))
          }, {
            default: _withCtx(() => [_createTextVNode("BOLL")]),
            _: 1
          }), _createVNode(_component_van_checkbox, {
            shape: "square",
            "icon-size": "18",
            name: "KDJ",
            style: {
              "margin-bottom": "10px"
            },
            onClick: _cache[2] || (_cache[2] = $event => indicatorChange('KDJ'))
          }, {
            default: _withCtx(() => [_createTextVNode("KDJ")]),
            _: 1
          }), _createVNode(_component_van_checkbox, {
            shape: "square",
            "icon-size": "18",
            name: "RSI",
            onClick: _cache[3] || (_cache[3] = $event => indicatorChange('RSI'))
          }, {
            default: _withCtx(() => [_createTextVNode("RSI")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_17, _toDisplayString(_unref(t)('indicator')), 1)]),
        _: 1
      }, 8, ["visible"])]), _createElementVNode("div", {
        id: "kline-chart",
        style: _normalizeStyle({
          height: props.klineHeight,
          width: '100%'
        })
      }, null, 4)])], 4), _renderSlot(_ctx.$slots, "default")]), !__props.ieo ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(_component_a_tabs, {
        activeKey: activeKey.value,
        "onUpdate:activeKey": _cache[6] || (_cache[6] = $event => activeKey.value = $event),
        type: "card",
        class: "custom-tab"
      }, {
        default: _withCtx(() => [_createVNode(_component_a_tab_pane, {
          key: "0",
          tab: _unref(t)('position')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, _toDisplayString(_unref(t)('level')), 1), _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(t)('price')), 1), _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(t)('number')), 1)]), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(17, index => {
            return _createVNode(DataBlock, {
              key: index,
              index: index,
              bg: "rgba(251, 243, 241, .6)",
              decimal: decimal.value,
              "color-l": "#EC234D",
              max: maxNum.value,
              min: minNum.value,
              price: last.value,
              top: true
            }, null, 8, ["index", "decimal", "max", "min", "price"]);
          }), 64)), _createElementVNode("div", {
            class: "current-price",
            style: _normalizeStyle({
              color: _unref(store).state.market[props.klineKey]?.up ? '#04AC92' : '#EC2752'
            })
          }, _toDisplayString(last.value), 5), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(17, index => {
            return _createVNode(DataBlock, {
              key: index,
              index: index,
              bg: "rgba(234, 249, 246, .6)",
              decimal: decimal.value,
              "color-l": "#269B8B",
              max: maxNum.value,
              min: minNum.value,
              price: last.value
            }, null, 8, ["index", "decimal", "max", "min", "price"]);
          }), 64))]),
          _: 1
        }, 8, ["tab"]), _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: _unref(t)('newTrade')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, _toDisplayString(_unref(t)('price')), 1), _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(t)('number')), 1), _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(t)('time')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dealList.value, item => {
            return _openBlock(), _createElementBlock("div", {
              class: "deal-item",
              key: item.id
            }, [_createElementVNode("div", {
              class: "left",
              style: _normalizeStyle({
                color: item.type === 'buy' ? '#04AC92' : '#D04C64'
              })
            }, _toDisplayString(_unref(fmtNumber)(item.price)), 5), _createElementVNode("div", {
              class: "center",
              style: _normalizeStyle({
                color: item.type === 'buy' ? '#04AC92' : '#D04C64'
              })
            }, _toDisplayString(_unref(fmtNumber)(item.amount)), 5), _createElementVNode("div", _hoisted_28, _toDisplayString(fmtTime(item.time)), 1)]);
          }), 128))]),
          _: 1
        }, 8, ["tab"])]),
        _: 1
      }, 8, ["activeKey"])])) : _createCommentVNode("", true)], 4)]);
    };
  }
};