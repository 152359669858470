import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-60e16a50"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "register-page"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "custom-label"
};
const _hoisted_4 = {
  class: "custom-label"
};
const _hoisted_5 = {
  class: "custom-label"
};
const _hoisted_6 = {
  class: "custom-label"
};
const _hoisted_7 = {
  style: {
    "display": "flex",
    "justify-content": "space-between"
  }
};
const _hoisted_8 = {
  class: "custom-label"
};
const _hoisted_9 = {
  class: "custom-label"
};
const _hoisted_10 = {
  class: "agree-view"
};
const _hoisted_11 = {
  class: "form-footer"
};
import { ref, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import store from '@/store';
import { message } from 'ant-design-vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { getCaptcha, sendEmailVerify, registerApi } from '@/apis';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'RegisterPage',
  setup(__props) {
    const route = useRoute();
    const {
      t
    } = useI18n();
    const loading = ref(false);
    const agree = ref(false);
    const form = reactive({
      email: '',
      checkCode: '',
      loginPwd: '',
      financePwd: '',
      inviteCode: '',
      phone: ''
    });
    if (route.query.code) {
      form.inviteCode = route.query.code;
    }
    const countTime = ref(false);
    const sendLoading = ref(false);
    const time = ref(dayjs().valueOf() + 60 * 1000);
    let checkKey = '';
    const captchaId = ref('');
    const captchaImg = ref('');
    const captcha = ref('123123');
    const init = async () => {
      // 获取图形验证码
      const res = await getCaptcha();
      if (res.code === 200) {
        captchaId.value = res.data.id;
        captchaImg.value = res.data.img;
      }
    };
    const sendCheckCode = async () => {
      if (loading.value) {
        return;
      }
      if (!form.email || form.email === '') {
        message.error(t('emailInput'));
        return;
      }
      sendLoading.value = true;
      const res = await sendEmailVerify({
        captcha_id: captchaId.value,
        captcha_code: captcha.value,
        email: form.email
      });
      sendLoading.value = false;
      if (res.code === 200) {
        message.success(t('sendSuccess'));
        checkKey = res.data.key;
        time.value = dayjs().valueOf() + 60 * 1000;
        countTime.value = true;
        return;
      } else {
        message.error(res.msg !== '' ? res.msg : t('sendError'));
        return;
      }
    };
    const register = async () => {
      if (!agree.value) {
        message.error(t('agreeTip'));
        return;
      }
      loading.value = true;
      const res = await registerApi({
        captcha_id: captchaId.value,
        captcha_code: captcha.value,
        email: form.email,
        email_verify_key: checkKey,
        email_verify_code: form.checkCode,
        password: form.loginPwd,
        invite_code: form.inviteCode,
        ex_password: form.financePwd,
        phone: form.phone
      });
      loading.value = false;
      if (res.code !== 200) {
        message.error(res.message !== '' ? res.message : t('registerError'));
        return;
      }
      if (res.data.token) {
        store.commit('tokenChange', res.data.token);
        store.commit('emailChange', form.email);
        localStorage.setItem('pcstore_info', JSON.stringify({
          token: res.data.token,
          time: dayjs().unix(),
          email: form.email
        }));
      }
      message.success(t('registerSuccess'));
      router.replace({
        name: 'home'
      });
    };
    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_a_input = _resolveComponent("a-input");
      const _component_a_form_item = _resolveComponent("a-form-item");
      const _component_a_statistic_countdown = _resolveComponent("a-statistic-countdown");
      const _component_a_input_password = _resolveComponent("a-input-password");
      const _component_a_checkbox = _resolveComponent("a-checkbox");
      const _component_a_button = _resolveComponent("a-button");
      const _component_a_form = _resolveComponent("a-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('registerTitle')), 1), _createVNode(_component_a_form, {
        model: form,
        layout: "vertical",
        style: {
          "margin-top": "40px"
        },
        onFinish: register
      }, {
        default: _withCtx(() => [_createVNode(_component_a_form_item, {
          name: "email",
          rules: [{
            required: true,
            message: _unref(t)('emailInput')
          }]
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('email')), 1), _createVNode(_component_a_input, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.email,
            "onUpdate:value": _cache[0] || (_cache[0] = $event => form.email = $event)
          }, null, 8, ["value"])]),
          _: 1
        }, 8, ["rules"]), _createVNode(_component_a_form_item, {
          name: "checkCode",
          rules: [{
            required: true,
            message: _unref(t)('checkCodeInput')
          }]
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('emailCheck')), 1), _createVNode(_component_a_input, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.checkCode,
            "onUpdate:value": _cache[2] || (_cache[2] = $event => form.checkCode = $event)
          }, {
            suffix: _withCtx(() => [sendLoading.value ? (_openBlock(), _createBlock(_unref(LoadingOutlined), {
              key: 0,
              style: {
                "font-size": "18px",
                "margin": "0 5px"
              }
            })) : (_openBlock(), _createElementBlock(_Fragment, {
              key: 1
            }, [countTime.value ? (_openBlock(), _createBlock(_component_a_statistic_countdown, {
              key: 0,
              value: time.value,
              format: "ss",
              onFinish: _cache[1] || (_cache[1] = $event => countTime.value = false),
              valueStyle: {
                fontSize: '15px',
                padding: '0 10px'
              }
            }, null, 8, ["value"])) : (_openBlock(), _createElementBlock("span", {
              key: 1,
              style: {
                "cursor": "pointer",
                "color": "#2376b7",
                "font-weight": "600"
              },
              onClick: sendCheckCode
            }, _toDisplayString(_unref(t)('sendCheckCode')), 1))], 64))]),
            _: 1
          }, 8, ["value"])]),
          _: 1
        }, 8, ["rules"]), _createVNode(_component_a_form_item, {
          name: "loginPwd",
          rules: [{
            required: true,
            message: _unref(t)('loginPwdTip')
          }, {
            min: 8,
            message: _unref(t)('loginPwdLen')
          }, {
            max: 20,
            message: _unref(t)('loginPwdLen')
          }]
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('loginPwd')), 1), _createVNode(_component_a_input_password, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.loginPwd,
            "onUpdate:value": _cache[3] || (_cache[3] = $event => form.loginPwd = $event)
          }, null, 8, ["value"])]),
          _: 1
        }, 8, ["rules"]), _createVNode(_component_a_form_item, {
          name: "financePwd",
          rules: [{
            required: true,
            message: _unref(t)('financePwdTip')
          }, {
            min: 6,
            message: _unref(t)('financePwdLen')
          }, {
            max: 20,
            message: _unref(t)('financePwdLen')
          }]
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('financePwd')), 1), _createVNode(_component_a_input_password, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.financePwd,
            "onUpdate:value": _cache[4] || (_cache[4] = $event => form.financePwd = $event)
          }, null, 8, ["value"])]),
          _: 1
        }, 8, ["rules"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_a_form_item, {
          name: "inviteCode",
          rules: [{
            required: true,
            message: _unref(t)('inviteCodeTip')
          }],
          style: {
            "width": "49%"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('inviteCode')) + " (" + _toDisplayString(_unref(t)('required')) + ")", 1), _createVNode(_component_a_input, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.inviteCode,
            "onUpdate:value": _cache[5] || (_cache[5] = $event => form.inviteCode = $event)
          }, null, 8, ["value"])]),
          _: 1
        }, 8, ["rules"]), _createVNode(_component_a_form_item, {
          name: "phone",
          rules: [{
            required: true,
            message: _unref(t)('phoneTip')
          }],
          style: {
            "width": "49%"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('phone')) + " (" + _toDisplayString(_unref(t)('required')) + ")", 1), _createVNode(_component_a_input, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.phone,
            "onUpdate:value": _cache[6] || (_cache[6] = $event => form.phone = $event)
          }, null, 8, ["value"])]),
          _: 1
        }, 8, ["rules"])]), _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createVNode(_component_a_checkbox, {
            checked: agree.value,
            "onUpdate:checked": _cache[8] || (_cache[8] = $event => agree.value = $event)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('registerTip')) + " ", 1), _createElementVNode("span", {
              class: "is-link",
              onClick: _cache[7] || (_cache[7] = $event => _unref(router).push({
                name: 'agreeList'
              }))
            }, _toDisplayString(_unref(t)('agree')), 1)]),
            _: 1
          }, 8, ["checked"])]), _createVNode(_component_a_button, {
            type: "primary",
            block: "",
            loading: loading.value,
            "html-type": "submit",
            style: {
              "height": "45px"
            }
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('register')), 1)]),
            _: 1
          }, 8, ["loading"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_11, [_createTextVNode(_toDisplayString(_unref(t)('haveAccount')), 1), _createElementVNode("span", {
        class: "is-link",
        onClick: _cache[9] || (_cache[9] = $event => _unref(router).push({
          name: 'login'
        }))
      }, _toDisplayString(_unref(t)('toLogin')), 1)])]);
    };
  }
};