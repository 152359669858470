export default {
  required: 'Obligatoire',
  home: 'Accueil',
  market: 'Marchés',
  currency: 'Échanger',
  walletTrade: 'Devise',
  contract: 'Contrats',
  wallet: 'Portefeuilles',
  pulling: 'Déroulez pour rafraîchir...',
  loosing: 'Relâcher pour rafraîchir...',
  loading: 'Chargement...',
  loadingError: 'Échec du chargement',
  nothing: 'Pas de données',
  finished: '',
  price: 'Prix',
  number: 'Quantité',
  recharge: 'Verser',
  transfer: 'Transférer',
  invite: 'Inviter',
  inviteDes: 'Invitez des amis à nous rejoindre',
  contact: 'Nous contacter',
  contactDes: '7*24 en ligne',
  fastTrade: 'Choix',
  name: 'Nom',
  fastPrice: 'Prix',
  trend: '24H %',
  all: 'Toute',
  growthList: 'Gagnantes',
  loserList: 'Perdantes',
  vol: '24H Vol',
  buy: 'Acheter',
  sell: 'Vendre',
  optimal: 'Prix ​​du marché',
  marketOrder: 'Commerce de marché',
  limitOrder: 'Limiter le commerce',
  useable: 'Disponible',
  tradeVol: 'Montante',
  entrust: 'Ordre à cours limité',
  entrustTotal: 'Montante',
  noLogin: 'Se déconnecter, ',
  toLogin: 'Connecte-toi maintenant',
  cancelOrder: 'Annuler',
  cancelError: 'Échec de l\'annulation',
  cancelSuccess: 'Annuler avec succès',
  cancelTip: 'Assurez-vous d\'annuler?',
  completed: 'Complété',
  canceled: 'Annulé',
  marketPrice: 'Marché',
  limitPrice: 'Limite',
  entrustPrice: 'Prix',
  entrustNum: 'Quantité',
  currencyType: 'Symbole',
  currencyOrder: 'Registre commercial',
  history: 'Histoire',
  tryAgain: 'Réessayer',
  entrustPriceTip: 'Prix d\'entrée',
  tradeNumTip: 'Quantité d\'entrée',
  tradeNumLess: 'La quantité doit être supérieure à 0',
  numLessTip: 'Solde insuffisant',
  tradePriceTip: 'Montant d\'entrée',
  balanceTip: 'Solde insuffisant',
  tradeError: 'Échec de l\'échange',
  tradeSuccess: 'Tradez avec succès',
  tip: 'Remarquer',
  tradeConfirm: 'Confirmer pour échanger ?',
  cancelText: 'Annuler',
  confirmText: 'Confirmer',
  toBuy: 'acheter',
  toSell: 'vendre à découvert',
  confirmToBuy: 'Acheter',
  confirmToSell: 'Vendre à découvert',
  slPrice: 'SL Prix',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Prix',
  currentOrder: 'Commande en cours',
  historyOrder: 'Histoire',
  setting: 'Régler',
  unwind: 'proche',
  handUnwind: 'Fermer manuellement',
  futuresPay: '',
  forceUnwind: 'Liquidation',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Choix',
  blowUp: 'Liquidation',
  openNum: 'Quantité',
  warrant: 'Marge',
  openPrice: 'Prix',
  fee: 'Frais',
  profit: 'Profit',
  lastPrice: 'Prix ​​actuel',
  unwindPrice: 'Prix fermé',
  unwindError: 'Impossible de fermer',
  unwindSuccess: 'Fermer avec succès',
  setSuccess: 'Définir avec succès',
  setError: 'Échec de la définition',
  setSlTp: 'Régler SL/TP',
  second: 's',
  multiple: 'Plusieurs',
  direction: 'Taper',
  tradeCurrency: 'Symbole',
  loss: 'Perte',
  fill: 'Gagner',
  executePrice: 'Prix',
  currentPrice: 'Prix ​​actuel',
  closePrice: 'Prix fermé',
  secondNum: 'Période',
  contractOrder: 'Ordres à terme',
  fastTradeOrder: 'Commandes d\'options',
  overview: 'Aperçu',
  ieoBuy: 'IEO',
  balanceTotal: 'Actif total',
  withdraw: 'retirer',
  tradeOrder: 'Ordre commercial',
  rechargeHistory: 'Dossier de dépôt',
  withdrawHistory: 'Retirer l\'enregistrement',
  freeze: 'Congelée',
  amountTo: '',
  tipText: 'Remarquer',
  ieoTip: 'C\'est pour bientôt.',
  financeList: 'Dossier financier',
  currencyAccount: 'Compte en devise',
  contractAccount: 'Compte à terme',
  time: 'Temps',
  status: 'Statut',
  balanceTyle: 'Compte',
  pay: 'Résultat',
  income: 'Revenu',
  lastBalance: 'solde',
  orderNo: 'ID',
  money: 'Montante',
  date: 'Date',
  finallyIncome: 'Recevoir le montant',
  commitMoney: 'Montante',
  orderNum: 'ID',
  waitReview: 'Révision',
  reviewPass: 'Passé',
  reviewReject: 'Rejetée',
  waitWallet: 'Révision',
  walletPass: 'Passé',
  walletReject: 'Rejetée',
  withdrawSuccess: 'Retirer avec succès',
  withdrawError: 'Échec du transfert',
  high24H: '24H Haute',
  low24H: '24H Bas',
  num24H: '24H Vol',
  minute: 'Mins',
  hour: 'Heures',
  day: 'Jour',
  week: 'La semaine',
  markets: 'Marché',
  open: 'Ouverte',
  close: 'proche',
  high: 'Haute',
  low: 'Bas',
  volume: 'Vol',
  fastLogin: 'Connecte-toi maintenant',
  register: 'S\'inscrire',
  auth: 'Vérifier',
  authSuccess: 'Vérifié',
  noPass: 'Rejetée',
  authCommit: 'Révision',
  onAuth: 'Non vérifié',
  onLogin: 'Se déconnecter',
  securitySet: 'Sécurité',
  userAgree: 'Assistance aux utilisateurs',
  aboutUs: 'À propos de nous',
  logout: 'Se déconnecter',
  logoutSuccess: 'Déconnexion réussie',
  lookAuthInfo: 'Vérifier mes informationsn',
  authAgain: 'Revérifier',
  authInfo: 'Informations',
  account: 'Compte',
  countryArea: 'Pays/Région',
  idNo: 'ID#',
  idNoTip: 'Saisir le numéro d\'identification',
  email: 'E-mail',
  emailInput: 'E-mail d\'entrée',
  setLoginPwd: 'Définir le mot de passe de connexion',
  loginPwdInput: 'Entrez le mot de passe de connexion (8-20 caractères)',
  loginPwdTip: 'Saisir le mot de passe de connexion',
  loginPwdLen: 'Le mot de passe de connexion doit être composé de 8 à 20 caractères',
  setFinancePwd: 'Définir le mot de passe du fonds',
  financePwdInput: 'Entrez le mot de passe du fonds (6-20 caractères)',
  financePwdTip: 'Saisir le mot de passe du fonds',
  financePwdLen: 'Le mot de passe du fonds doit comporter entre 6 et 20 caractères',
  inviteCode: 'Code d\'invitation',
  inviteCodeTip: 'Saisir le code d\'invitation',
  inviteInput: 'Le code d\'invitation est requis',
  imageCode: 'Captcha',
  imageCodeInput: 'Captcha d\'entrée',
  agree: '《Contrat d\'utilisation》',
  agreeText: 'Contrat d\'utilisation',
  next: 'Prochaine',
  registerSuccess: 'Inscrivez-vous complètement',
  registerError: 'Échec de l\'inscription',
  securityCheck: 'Sécurité',
  checkCodeInput: 'Saisissez le code de vérification',
  sendCheckCode: 'Envoyer',
  sendSuccess: 'Expédié',
  sendError: 'Échec de l\'envoi',
  welcome: 'Bienvenue à',
  loginEmail: 'E-mail',
  loginEmailInput: 'Saisir l\'adresse e-mail',
  loginPwd: 'Mot de passe',
  login: 'Connexion',
  forgetPwd: 'Mot de passe oublié',
  loginTip: 'La connexion signifie que vous acceptez',
  loginSuccess: 'Connectez-vous complètement',
  loginError: 'Échec de la connexion',
  fullname: 'Nom',
  country: 'Pays/Région',
  countryTip: 'Entrez le pays ou la région',
  imgFront: 'Téléchargez une photo du recto de votre pièce d\'identité',
  imgReverse: 'Téléchargez une photo du verso de votre pièce d\'identité',
  commit: 'Soumettre',
  commitSuccess: 'Soumettre complètement',
  commitError: 'Échec de l\'envoi',
  selectCurrency: 'Choisissez la cryptographie',
  rechargeNet: 'Réseau',
  rechargeAddress: 'Adresse de dépôt',
  copyAddress: 'Copier l\'adresse',
  minRecharge: 'Montant minimum du dépôt',
  copySuccess: 'Copier entièrement',
  copyError: 'Échec de la copie',
  withdrawTip: 'Vous pouvez retirer après avoir vérifié votre compte',
  withdrawAddress: 'Adresse de retrait',
  withdrawAddressInput: 'Saisir l\'adresse de retrait',
  withdrawNet: 'Réseau',
  withdrawNum: 'Montante',
  withdrawNumInput: 'Montant d\'entrée',
  withdrawNumLess: 'Solde insuffisant',
  withdrawMin: 'Montant minimum de retrait',
  balanceUse: 'Disponible',
  actualMoney: 'Recevoir le montant',
  actualMoneyText: 'Recevoir le montant',
  financePwd: 'Mot de passe du fonds',
  trade: '',
  withdrawFail: 'Échec du retrait',
  commitTime: 'Date',
  from: 'De',
  to: 'À',
  transferMoney: 'Montant d\'entrée',
  transferSuccess: 'Transférer complètement',
  transferError: 'Échec du transfert',
  transferHistory: 'Transfert d\'enregistrement',
  updateFinancePwd: 'Modifier le mot de passe du fonds',
  confirmPwd: 'Confirmez le mot de passe',
  confirmPwdInput: 'Saisissez le mot de passe de confirmation',
  updatePwd: 'Changer le mot de passe',
  updatePwdSuccess: 'Changer complètement',
  updatePwdError: 'Échec de la modification',
  noAlike: 'Le mot de passe est incohérent',
  emailUpdate: 'Changer l\'e-mail',
  loginPwdUpdate: 'Changer le mot de passe de connexion',
  financePwdUpdate: 'Modifier le mot de passe du fonds',
  newEmail: 'Nouveau courriel',
  emailUpdateTip: 'Saisir un nouvel e-mail',
  checkEmail: 'Vérifier les courriels',
  updateEmail: 'Changer l\'e-mail',
  updateEmailError: 'Échec de la modification',
  updateEmailSuccess: 'Changer complètement',
  checkError: 'Échec de la vérification',
  pwdLen: '8-20 caractères',
  inviteUrl: 'Lien de référence',
  myInviteCode: 'Code d\'invitation',
  fastInvite: 'Inviter maintenant',
  myInvite: 'Liste des invitations',
  registerTime: 'Date',
  bulletin: 'Annonce',
  inviteFriend: 'Inviter des amis',
  saveAccount: 'Enregistrer le compte et le mot de passe',
  indicator: 'Indicatrices',
  type: 'Taper',
  amount: 'Montante',
  order: 'Ordres', 
  registerTitle: 'Créer un compte mondial extrêmement riche', 
  emailCheck: 'Code de vérification de courrier électronique', 
  haveAccount: 'Vous avez déjà un compte?', 
  registerTip: 'J\'ai lu et j\'accepte ', 
  userInfo: 'Renseignements personnels', 
  userAuth: 'Vérification du compte', 
  footerTip: 'Le droit d\'interprétation finale appartient à la plateforme', 
  marketInfo: 'Marchés', 
  viewMore: 'Cliquez pour en savoir plus', 
  tradeText: 'Échanger', 
  position: 'Handicap', 
  level: 'Niveau', 
  oneBuy: 'Acheter', 
  oneSell: 'Vendre', 
  newTrade: 'Temps réel',
  tradePair: 'Symbole', 
  historyEntrust: 'Historique des commandes', 
  allOrder: 'Tous les enregistrements', 
  total: 'Totale', 
  oneOrder: 'Ordres', 
  priceTip: 'Prix d\'entrée', 
  numberTip: 'Quantité d\'entrée', 
  profitText: 'Profit', 
  foundInput: 'Montant d\'entrée', 
  foundTip: 'Veuillez saisir le montant', 
  countdown: 'Compte à rebours', 
  tradeMoney: 'Montante', 
  completePrice: 'Prix', 
  profitAndLoss: 'Bénéfice et perte estimés', 
  walletOverview: 'Aperçu', 
  ieoBuyAccount: 'IEO Compte', 
  balanceText: 'Histoire', 
  selectRechargeAddress: 'Sélectionnez l\'adresse de dépôt', 
  successText: 'Succès', 
  errorText: 'Échouer', 
  selectWithdrawAddress: 'Adresse de retrait d\'entrée', 
  withdrawSuText: 'Soumettre avec succès', 
  transferMoneyLabel: 'Montante', 
  paramsError: 'Erreur de paramètre', 
  reset: 'Réinitialiser', 
  selectText: 'Chercher', 
  emailManage: 'Gérer le compte', 
  loginPwdDes: 'Modifier votre mot de passe de connexion', 
  updateText: 'Modifier', 
  financePwdDes: 'Modifier votre mot de passe de fonds', 
  emailManageDes: 'Gardez la sécurité de votre compte', 
  oldEmail: 'Adresse e-mail d\'origine', 
  countryAreaTip: 'Entrez le pays ou la région',  
  fullnameTip: 'Nom d\'entrée',
  retrievePwd: 'Mot de passe oublié',
  volume24h: 'Volume 24H',
  currencyHomeType: 'Crypto',

  whyUs: 'POURQUOI NOUS?',
  why1: 'Sécurisé et assuré',
  why1Des: 'Nous stockons 80% des crypto-monnaies dans notre portefeuille froid sécurisé. Les entrepôts frigorifiques sont sécurisés et assurés par Ledger.',
  why2: 'Transactions rapides',
  why2Des: 'Nous pouvons gérer des millions de transactions. Notre infrastructure système peut évoluer en quelques secondes pour répondre à la demande croissante.',
  why3: 'Simple et facile à utiliser',
  why3Des: 'Le trading sur notre plateforme est une expérience ultra-rapide. Nous avons construit la technologie utilisée par des millions, et cette expérience nous a aidés à construire ce puissant échange.',
  why4: 'Commerce instantané',
  why4Des: 'Échangez plus de 150 crypto-monnaies instantanées en un seul clic.',
  why5: 'Meilleur prix',
  why5Des: 'Négociez toutes les crypto-monnaies avec une liquidité élevée.',
  why6: 'Confiance',
  why6Des: 'Plus de 800 000 utilisateurs ont foi et ont échangé des centaines de crores.',
  homeGift: "OBTENEZ GRATUITEMENT 30 $ À ÉCHANGER CONTRE UN BONUS D'INSCRIPTION.",
  popular1: '100 000 USD+',
  popular1Des: 'Volume total échangé',
  popular2: '150+',
  popular2Des: 'Devises prises en charge',
  popular3: '800K+',
  popular3Des: 'Utilisateurs vérifiés',
  globalText: 'LA CONFIANCE DE PLUS DE 800 000 UTILISATEURS MONDIAUX POUR LE TRADING DE CRYPTOMONNAIE',
  startText: 'COMMENCEZ EN SEULEMENT 5 MIN.',
  start1: "S'inscrire",
  start1Des: "Ouvrez le site Web ou téléchargez l'application et inscrivez-vous par e-mail.",
  start2: 'KYC',
  start2Des: "Gardez votre preuve d'identité à portée de main, entrez les détails KYC de base et faites vérifier votre compte.",
  start3: 'Verser',
  start3Des: 'Déposez facilement dans votre portefeuille numérique.',
  start4: 'Acheter vendre',
  start4Des: 'Achetez / vendez et échangez des crypto-monnaies au meilleur prix.',
  secondTip: 'Le montant ne doit pas être inférieur à {tip}',
  appTitle: 'APPLICATION DISPONIBLE MAINTENANT',
  appDes: "Bénéficiez de la sécurité, du trading et des capacités de la meilleure plate-forme d'échange de crypto-monnaie réglementée, directement sur votre smartphone.",
  timeUp: 'Temps écoulé',
  closeAll: 'Ferme tout',
  assetRate: 'Taux de marge',
  cancel: 'Annuler',
  cancelInfo: 'Confirmer pour annuler ?',
  buyCurrency: 'Symbole',
  closeCurrency: 'Devise',
  buyPrice: 'Prix',
  valuation: 'Valorisation',
  profitP: 'Rendement',
  buySuccess: 'Avec succès',

  swiperTitle1: "Le meilleur échange de crypto numérique au monde",
  swiperSub1: 'Reconnu par plus de 100 000 utilisateurs',

  ieoBuying: 'En cours',
  ieoOpen: 'Date de début(UTC-5)',
  ieoClose: 'Date de fin(UTC-5)',
  about: 'À propos',
  ieoPlan: 'Distribution',
  ieoInfo: 'Renseignements',
  ieoContact: 'Des médias sociaux',
  ieoStart: 'Initialisation',
  ieoBuyStart: 'Début IEO',
  ieoBuyEnd: 'Finition IEO',
  ieoIsTrade: "Lancement",
  introduce: 'Introduction',
  ieoSubBuy: 'IEO',
  subBuyHistory: "L'histoire",
  createTeam: 'Fonds de réserve',
  angelFund: 'Fonds des anges',
  ecology: 'Institutions et écosystèmes',
  presale: 'Version de pré-vente',
  mechanism: 'Mécanisme minier',
  trading: 'Négociation d\'échange',

  issuePrice: 'Prix d\'émission',
  minBuyPrice: 'Abonnement Minimal',
  buyNum: 'Abonnements par Jour',
  buyTotal: 'Abonnement Quotidien Total',
  Increment: 'Incrément d\'abonnement',
  blockchain: 'Chaîne de Blocs',

  sellTotal: 'Émission Totale',
  currentBuy: 'Abonnement Actuel',
  dowload: 'Centre de Téléchargement',
  lessMinTip: 'Ne peut être inférieur au montant minimum.',
  rechargeTip1: "1. Il s'agit de votre adresse la plus récente et unique, la plateforme ne vous demandera jamais de transférer des fonds vers un autre compte ou de vous demander votre code de vérification.",
  rechargeTip2: "2. Le dépôt nécessite au moins 10 blocs réseau pour confirmation. Après confirmation, votre compte recevra automatiquement les fonds.",
  withdrawTip1: "1. Assurez-vous de sélectionner le même réseau pour ce retrait que celui côté dépôt et vérifiez que la plateforme de réception prend en charge le réseau. Sinon, vous perdrez vos actifs.",
  withdrawTip2: "2. Les blocs réseau confirmeront votre retrait une fois que la plateforme aura approuvé votre candidature.",
  withdrawTip3: "3. Le retrait nécessite au moins 10 blocs de réseau pour confirmation. Après confirmation, votre compte recevra automatiquement les fonds.",
  footerBottomTip: "Les informations figurant sur ce site ne constituent pas des conseils d'investissement, des conseils financiers, des conseils de négociation ou tout autre type de conseil et vous ne devez pas considérer le contenu du site comme tel. Les investissements en crypto-monnaies sont volatils et à haut risque de par leur nature. N'investissez pas plus que ce que vous pouvez vous permettre de perdre.",

  stakeAccount: 'Compte de jalonnement',
  serviceAccount: 'Compte de service',
  lock: 'Serrure',
  lockPrice: 'Prix verrouillé',
  ieoHistory: 'Historique de l\'IEO',
  lockHistory: 'Historique de verrouillage',
  sellHistory: 'Historique des ventes',
  stake: 'Jalonnement',
  service: 'Service',
  ieoBuyTip: "*Avis IEO : Le nombre de LTJ est limité. Nous examinerons l'application IEO de l'utilisateur et tirerons au sort au hasard toutes les 3 heures. Les utilisateurs disposant d'un montant relativement important auront une probabilité plus élevée d'acheter du LTJ. Chaque utilisateur peut acheter jusqu'à 3 fois par jour.",
  ieoLockTip: '* Prix de verrouillage : les utilisateurs doivent utiliser l\'USDT comme devise de gage pour verrouiller le prix du LTJ, et le ratio de gage est de 30 % de la valeur du LTJ. Après le lancement du LTJ, les utilisateurs peuvent vendre le LTJ au prix verrouillé à ce moment-là.',
  ieoSellTip: '*Vente IEO : la vente de LTJ nécessite 10 % de la valeur du LTJ en tant que frais de service. Après avoir vendu le LTJ, l\'USDT que vous avez échangé sera débloqué dans 20 jours, et l\'USDT mis en gage sera débloqué dans 20 jours.',
  whitePaper: 'PAPIER BLANC',
  cost: 'Coût',
  currentEstimate: 'Évaluation actuelle',
  stakeNum: 'Montant de mise',
  estimate: 'Évaluation',
  consume: 'Consommer',

  seedSell: 'Tour de semences',
  privateSell: 'Tour privé',
  openSell: 'Ronde KOL',
  ieoAward: 'Ronde publique',
  ieoGrowUp: 'Équipe',
  partner: 'Conseillers',
  consultant: 'Commercialisation',
  ieoName: 'Liquidité',
  conAward: 'IA pour gagner',
  staking: 'Jalonnement',

  release: 'Libérer',
  closedTime: 'Heure de fermeture',
  remark: 'Remarque',

  totalProfit: 'Bénéfice total',
  transferIn: 'Transférer dans',
  transferOut: 'Transfert sortant',
  equity: 'valeur nette',
  
  homeremind1: 'Crypto-monnaies populaires',
  homeremind2: 'Voir plus de marchés',
  homeremind3: 'Dernier prix(USD)',
  homeremind4: 'Changement 24H',
  homeremind5: 'Action',
  homeremind6: 'Voir plus',
  homeremind7: 'Meilleurs choix des utilisateurs',
  homeremind8: 'Volume des échanges sur 24 heures',
  homeremind9: 'Actifs virtuels de premier ordre',
  homeremind10: 'Nos produits phares',
  homeremind11: 'Une grande variété d\'outils de trading parmi lesquels choisir',
  homeremind12: 'Place',
  homeremind13: 'Plus de 700 cryptos disponibles au trading',
  homeremind14: 'Améliorez votre expérience en négociant des contrats à terme',
  homeremind15: 'Gagner NYECoins',
  homeremind16: 'Meilleurs APY du secteur',
  homeremind17: 'Nos services diversifiés',
  homeremind18: 'Explorez des possibilités illimitées grâce à nos divers services',
  homeremind19: 'Abonnement IEO',
  homeremind20: 'Les meilleures crypto-monnaies en prévente',
  homeremind21: 'Service client VIP',
  homeremind22: '7*24 heures',
  homeremind23: 'Communauté',
  homeremind24: 'Découvrez des opportunités de richesse ici avec nous',
  homeremind25: "Restez dans le trading. N'importe où, n'importe quand.",
  homeremind26: 'Restez informé grâce à notre application mobile et notre terminal de site Web.',
  homeremind27: 'Suivez-nous sur les réseaux sociaux',
  homeremind28: 'Restez au courant de nos dernières nouvelles',
  homeremind29: 'Négociez à tout moment et en tout lieu',
  homeremind30: 'Essayez NYECoins avec votre iOS, Android ou API.',

  msb: 'NYECoins est réglementé par MSB, États-Unis.',
  msb1: 'Digne de confiance, sûr, rapide',
  checkNow: 'Vérifie maintenant',

  foundingTeam: 'Équipe fondatrice',
  advisorTeam: 'Équipe de conseillers',
  community: 'Communauté',

  phone: 'Numéro de téléphone',
  phoneTip: 'Saisissez votre numéro de téléphone',


  rebateAccount: 'compte de commissions',
  rebateType: 'taper',
  recommend: 'Référence',
  myRebate: 'ma commission',
  rebateRule: 'Règles de la Commission',
  yes: 'Oui',
  no: 'Non',
  authName: 'Vérifier',
  countryName: 'Superficie du pays',
  allCommission: 'Commission totale',
  myClient: 'Mes clients',
  inviteUrl1: 'Lien de référence',
  inviteCode1: 'Code de Parrainage',
  commissioned: 'commission',

  swiperTitle2: 'Le leader mondial du trading de crypto en ligne. Achetez, vendez et détenez des crypto-monnaies.',
  swiperSub2: 'Nous avons guidé des millions d\'utilisateurs dans le monde dans leur parcours en matière d\'actifs numériques.',

  homeremind31: 'Qu\'est-ce qu\'on fait?',
  homeremind32: 'Nous voulons brouiller la frontière entre les marchands de cryptomonnaies et les consommateurs ordinaires. Nous populariserons la crypto-monnaie en offrant une entrée gratuite et en aidant les gens à comprendre et à se confier sur ce que nous pensons être l’avenir de l’argent.',
  homeremind33: 'Caractéristiques des NYECOINS',
  homeremind34: 'NYECOINS rassemble des vendeurs et des acheteurs sans banque centrale ni administrateur unique pour garantir des transactions claires et équitables',
  homeremind35: 'Coûts de transaction réduits',
  homeremind36: 'Une plateforme décentralisée disponible pour les clients avec des paiements de transaction et des dépenses opérationnelles inférieurs.',
  homeremind37: 'Transactions C2C',
  homeremind38: 'Les opérations C2C sont simples, rapides et sûres avec NYECOINS. Envoyez du BTC ou la devise de votre choix à n’importe quel client de la plateforme.',
  homeremind39: 'Boîte à outils premium pour les marques',
  homeremind40: 'Nous avons optimisé le ciblage des consommateurs en donnant aux entreprises les outils et les informations nécessaires pour connaître leurs clients.',
  homeremind41: 'Soutenu par des partenaires mondiaux',
  homeremind42: 'L\'équipe NYECOINS',
  homeremind43: 'Laissez-nous vous présenter l\'équipe qui fera que tout cela se réalise.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Co-fondateur',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Ingénieur logiciel senior',
  homeremind48: 'Richard Norman',
  homeremind49: 'Gestionnaire de communauté',
  homeremind50: 'Paul Garrett',
  homeremind51: 'Concepteur UI/UX',


  ieoIntro1: 'Incitations à la liquidité',
  ieoIntro2: 'Réserve de jalonnement',
  ieoIntro3: "Partenaires de l'écosystème",
  ieoIntro4: 'Vente publique',
  ieoIntro5: 'Vente Privée A',
  ieoIntro6: 'Ronde de grains',
  ieoIntro7: 'Équipe LinkToJoin',
  ieoIntro8: "Réserve de frais d'entreprise",
}