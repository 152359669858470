import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, onBeforeMount } from 'vue';
import store from '@/store';
import hiIN from 'ant-design-vue/es/locale/hi_IN';
import zhTW from 'ant-design-vue/es/locale/zh_TW';
import enUS from 'ant-design-vue/es/locale/en_US';
import frFr from 'ant-design-vue/es/locale/fr_FR';
import esEs from 'ant-design-vue/es/locale/es_ES';
import deDe from 'ant-design-vue/es/locale/de_DE';
import itIt from 'ant-design-vue/es/locale/it_IT';
import thTh from 'ant-design-vue/es/locale/th_TH';
import ptPt from 'ant-design-vue/es/locale/pt_PT';
import trTr from 'ant-design-vue/es/locale/tr_TR';
import koKr from 'ant-design-vue/es/locale/ko_KR';
import jaJp from 'ant-design-vue/es/locale/ja_JP';
import ruRu from 'ant-design-vue/es/locale/ru_RU';
import dayjs from 'dayjs';
// import 'dayjs/locale/hi'
// import 'dayjs/locale/zh-tw'
// import 'dayjs/locale/en'
import { useI18n } from 'vue-i18n';
import BigNumber from 'bignumber.js';
export default {
  __name: 'App',
  setup(__props) {
    const i18n = useI18n();
    const getLang = () => {
      switch (store.state.lang) {
        case 'tc':
          return zhTW;
        case 'en':
          return enUS;
        case 'hin':
          return hiIN;
        case 'fr':
          return frFr;
        case 'span':
          return esEs;
        case 'de':
          return deDe;
        case 'it':
          return itIt;
        case 'th':
          return thTh;
        case 'pt':
          return ptPt;
        case 'tr':
          return trTr;
        case 'kr':
          return koKr;
        case 'jp':
          return jaJp;
        case 'ru':
          return ruRu;
      }
    };
    onBeforeMount(() => {
      try {
        let lang = localStorage.getItem("pcsetlang") || "en";
        store.commit('langChange', lang);
        const msg = require(`@/language/lang/${lang}`).default;
        i18n.setLocaleMessage(lang, msg);
        i18n.locale.value = lang;
      } catch (err) {
        console.warn(err);
      }
    });
    onMounted(() => {
      window.addEventListener('beforeunload', () => {
        if (store.state.token !== '') {
          localStorage.setItem('pcstore_info', JSON.stringify({
            token: store.state.token,
            time: dayjs().unix(),
            email: store.state.email
          }));
        }
        localStorage.setItem('wallet-total', JSON.stringify({
          show: store.state.walletShow
        }));
      });

      // //解决hw问题
      // window.localStorage.clear()
      // window.sessionStorage.clear()
    });

    const info = localStorage.getItem('pcstore_info');
    if (info) {
      const {
        token,
        time,
        email
      } = JSON.parse(info);
      if (token && time && email) {
        const now = dayjs().unix();
        const timeDif = BigNumber(now).minus(time).toNumber();
        if (timeDif <= 3600) {
          store.commit('tokenChange', token);
          store.commit('emailChange', email);
        } else {
          localStorage.removeItem("pcstore_info");
        }
      }
    }
    store.commit('wsInit');
    store.commit('getUser');
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_a_config_provider = _resolveComponent("a-config-provider");
      return _openBlock(), _createBlock(_component_a_config_provider, {
        locale: getLang()
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};