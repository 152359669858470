import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// import moment from "moment"
import { fmtNumber } from "@/util"

export function soptFmt(list) {
  return list.map((item) => {
    return {
      key:item.order_id,
      id: item.order_id,
      name: item.stock.coin_name,
      coin: item.money.coin_name,
      time: item.order_create_time,
      status: item.order_status,
      side: item.order_side,
      type: item.order_type,
      price: fmtNumber(item.order_price),
      count: fmtNumber(item.order_count),
      deal: fmtNumber(item.order_deal_money)
    }
  })
}

export function contractFmt(list) {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  return list.map(item => {
    return {
      key: item.contract_id,
      id: item.contract_id,
      name: item.market.market_symbol,
      multiple: item.contract_multiple,
      side: item.contract_direction,
      type: item.contract_trade_type,
      time: item.contract_create_time,
      openNum: fmtNumber(item.contract_open_amount),
      holdNum: fmtNumber(item.contract_hold_amount),
      warrant: fmtNumber(item.contract_margin),
      fee: fmtNumber(item.contract_fee),
      profit: fmtNumber(item.contract_profit),
      status: item.contract_status,
      margin: fmtNumber(item.contract_margin),
      openPrice: fmtNumber(item.contract_open_price),
      closeTime: dayjs(item.contract_close_time * 1000).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss"),
      sl: fmtNumber(item.contract_sl), //止损
      tp: fmtNumber(item.contract_tp), // 止盈
      close: item.contract_close_type,
      last: fmtNumber(item.contract_close_price)
    }
  })
}

export function secondFmt(list, timestamp) {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  return list.map(item => {
    // const now = moment().unix()
    const now = BigNumber(timestamp)
    const end = BigNumber(item.contract_open_time).plus(item.contract_second_time).toNumber()
    return {
      key: item.contract_id,
      id: item.contract_id,
      name: item.market.market_symbol,
      multiple: item.contract_multiple,
      open: item.contract_open_price,
      second: item.contract_second_time,
      amount: item.contract_order_amount,
      profit: item.contract_profit,
      endTime: BigNumber(end).minus(now).multipliedBy(1000).toNumber(),
      time: item.contract_create_time,
      last: item.contract_second_close_price ? item.contract_second_close_price : item.contract_close_price,
      direction: item.contract_direction,
      status: item.contract_status,
      idt: now
    }
  })
}