export default {
  required: 'Gerekli',
  home: 'Ana sayfa',
  market: 'Piyasalar',
  currency: 'Ticaret',
  walletTrade: 'Yer',
  contract: 'Sözleşme',
  wallet: 'Cüzdanlar',
  pulling: 'Yenilemek için aşağıya çekin...',
  loosing: 'Yenilemek için bırakın...',
  loading: 'Yükleniyor...',
  loadingError: 'Yükleme başarısız',
  nothing: 'Veri yok',
  finished: '',
  price: 'Fiyat',
  number: 'Miktar',
  recharge: 'Depozito',
  transfer: 'Aktar',
  invite: 'Davet etmek',
  inviteDes: 'Arkadaşlarınızı bize katılmaya davet edin',
  contact: 'Bizimle iletişime geçin',
  contactDes: '7*24 İnternet üzerinden',
  fastTrade: 'Seçenekler',
  name: 'İsim',
  fastPrice: 'Fiyat',
  trend: '24H %',
  all: 'Herşey',
  growthList: 'kazananlar',
  loserList: 'Kaybedenler',
  vol: '24H Vol',
  buy: 'Satın almak',
  sell: 'Satmak',
  optimal: 'Market fiyatı',
  marketOrder: 'Pazar Ticareti',
  limitOrder: 'Ticareti Sınırla',
  useable: 'Mevcut',
  tradeVol: 'Miktar',
  entrust: 'Limit Emri',
  entrustTotal: 'Miktar',
  noLogin: 'Çıkış Yap',
  toLogin: 'Şimdi giriş yap',
  cancelOrder: 'İptal',
  cancelError: 'iptal edilemedi',
  cancelSuccess: 'İptal başarıyla',
  cancelTip: 'İptal etmek kesin mi?',
  completed: 'Tamamlanmış',
  canceled: 'İptal edildi',
  marketPrice: 'Pazar',
  limitPrice: 'sınır',
  entrustPrice: 'Fiyat',
  entrustNum: 'Miktar',
  currencyType: 'Sembol',
  headerCurrencyType: 'Sembol',
  currencyOrder: 'Ticaret Kaydı',
  history: 'Tarih',
  tryAgain: 'Tekrar deneyin',
  entrustPriceTip: 'girdi fiyatı',
  tradeNumTip: 'Giriş miktarı',
  tradeNumLess: 'Miktar 0\'dan fazla olmalıdır',
  numLessTip: 'Yetersiz bakiye',
  tradePriceTip: 'Giriş miktarı',
  balanceTip: 'Yetersiz bakiye',
  tradeError: 'Ticaret başarısız oldu',
  tradeSuccess: 'Başarıyla ticaret yapın',
  tip: 'Fark etme',
  tradeConfirm: 'Ticareti onayla?',
  cancelText: 'İptal',
  confirmText: 'Teyit etmek',
  toBuy: 'Hepsini satın al',
  toSell: 'Kısa satmak',
  confirmToBuy: 'Hepsini satın al',
  confirmToSell: 'Kısa satmak',
  slPrice: 'SL Fiyat',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Fiyat',
  currentOrder: 'Şuan ki sipariş',
  historyOrder: 'Tarih',
  setting: 'Ayarlamak',
  unwind: 'Kapat',
  handUnwind: 'Manuel olarak kapat',
  futuresPay: '',
  forceUnwind: 'Tasfiye',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Seçenekler',
  blowUp: 'Tasfiye',
  openNum: 'Miktar',
  warrant: 'kenar boşluğu',
  openPrice: 'Fiyat',
  fee: 'Ücret',
  profit: 'Kâr',
  lastPrice: 'Mevcut fiyat',
  unwindPrice: 'Kapanış Fiyatı',
  unwindError: 'kapatılamadı',
  unwindSuccess: 'Başarıyla kapat',
  setSuccess: 'Başarıyla ayarla',
  setError: 'ayarlanamadı',
  setSlTp: 'Ayarlamak SL/TP',
  second: 's',
  multiple: 'çoklu',
  direction: 'Tip',
  tradeCurrency: 'Sembol',
  loss: 'Kayıp',
  fill: 'Kazanç',
  executePrice: 'Fiyat',
  currentPrice: 'Mevcut fiyat',
  closePrice: 'Kapanış Fiyatı',
  secondNum: 'Dönem',
  contractOrder: 'Vadeli İşlem Emirleri',
  fastTradeOrder: 'Opsiyon Siparişleri',
  overview: 'genel bakış',
  ieoBuy: 'IEO',
  balanceTotal: 'Toplam varlık',
  withdraw: 'geri çekilmek',
  tradeOrder: 'Ticaret Emri',
  rechargeHistory: 'Mevduat Kaydı',
  withdrawHistory: 'Kayıt Çek',
  freeze: 'Dondurulmuş',
  amountTo: '',
  tipText: 'Fark etme',
  ieoTip: 'Yakında geliyor.',
  financeList: 'Mali kayıt',
  currencyAccount: 'Spot Hesap',
  contractAccount: 'Vadeli İşlem Hesabı',
  time: 'Zaman',
  status: 'Durum',
  balanceTyle: 'Hesap',
  pay: 'Sonuç',
  income: 'Gelir',
  lastBalance: 'Denge',
  orderNo: 'ID',
  money: 'Miktar',
  date: 'Tarih',
  finallyIncome: 'Alma tutarı',
  commitMoney: 'Miktar',
  orderNum: 'ID',
  waitReview: 'gözden geçirme',
  reviewPass: 'Geçti',
  reviewReject: 'Reddedildi',
  waitWallet: 'gözden geçirme',
  walletPass: 'Geçti',
  walletReject: 'Reddedildi',
  withdrawSuccess: 'Başarıyla Para Çekme',
  withdrawError: 'Aktarılamadı',
  high24H: '24H Yüksek',
  low24H: '24H Düşük',
  num24H: '24H Vol',
  minute: 'Dakika',
  hour: 'Saat',
  day: 'Gün',
  week: 'Hafta',
  markets: 'Pazar',
  open: 'Açık',
  close: 'Kapat',
  high: 'Yüksek',
  low: 'Düşük',
  volume: 'Vol',
  fastLogin: 'Şimdi giriş yap',
  register: 'Kayıt ol',
  auth: 'Doğrulamak',
  authSuccess: 'doğrulandı',
  noPass: 'Reddedildi',
  authCommit: 'gözden geçirme',
  onAuth: 'Doğrulanmamış',
  onLogin: 'Çıkış Yap',
  securitySet: 'Güvenlik',
  userAgree: 'Kullanıcı Desteği',
  aboutUs: 'Hakkımızda',
  logout: 'Çıkış Yap',
  logoutSuccess: 'Başarıyla Çıkış Yap',
  lookAuthInfo: 'bilgilerimi kontrol et',
  authAgain: 'yeniden doğrula',
  authInfo: 'Bilgi',
  account: 'Hesap',
  countryArea: 'Ülke/Bölge',
  idNo: 'ID#',
  idNoTip: 'Kimlik numarasını girin',
  email: 'Email',
  emailInput: 'E-posta adresini girin',
  setLoginPwd: 'Oturum açma parolasını ayarla',
  loginPwdInput: 'Oturum açma şifresini girin (8-20 karakter)',
  loginPwdTip: 'Giriş şifresini girin',
  loginPwdLen: 'Giriş şifresi 8-20 karakter olmalıdır',
  setFinancePwd: 'Fon parolası belirleyin',
  financePwdInput: 'Giriş fonu şifresi (6-20 karakter)',
  financePwdTip: 'Giriş fonu şifresi',
  financePwdLen: 'Fon şifresi 6-20 karakter olmalıdır',
  inviteCode: 'Davet Kodu',
  inviteCodeTip: 'Davet kodunu girin',
  inviteInput: 'Davet kodu gerekli',
  imageCode: 'Captcha',
  imageCodeInput: 'Güvenlik kodunu girin',
  registerTip: 'Kayıt, kabul ettiğiniz anlamına gelir ',
  agree: '《Kullanıcı Sözleşmesi》',
  agreeText: 'Kullanıcı Sözleşmesi',
  next: 'Sonraki',
  registerSuccess: 'Tamamen Kaydolun',
  registerError: 'Kayıt başarısız',
  securityCheck: 'Güvenlik',
  checkCodeInput: 'Doğrulama kodunu girin',
  sendCheckCode: 'Göndermek',
  sendSuccess: 'Gönderilmiş',
  sendError: 'gönderilemedi',
  welcome: 'Hoşgeldiniz',
  loginEmail: 'Email',
  loginEmailInput: 'E-posta adresini girin',
  loginPwd: 'Giriş şifresi',
  login: 'Giriş yapmak',
  forgetPwd: 'Parolanızı mı unuttunuz',
  loginTip: 'Giriş, kabul ettiğiniz anlamına gelir',
  loginSuccess: 'Tamamen giriş yap',
  loginError: 'Giriş yapılamadı',
  fullname: 'İsim',
  fullnameTip: 'adınızı girin',
  country: 'Ülke/Bölge',
  countryTip: 'Girilen ülke/bölge',
  imgFront: 'Kimliğinizin ön yüzünün fotoğrafını yükleyin',
  imgReverse: 'Kimliğinizin arka yüzünün fotoğrafını yükleyin',
  commit: 'Göndermek',
  commitSuccess: 'Tamamen gönder',
  commitError: 'gönderilemedi',
  selectCurrency: 'Kripto seçin',
  rechargeNet: 'Ağ',
  rechargeAddress: 'Para Yatırma Adresi',
  copyAddress: 'Adresi kopyala',
  minRecharge: 'Minimum para yatırma tutarı',
  copySuccess: 'tamamen kopyala',
  copyError: 'kopyalanamadı',
  withdrawTip: 'Hesabınızı doğruladıktan sonra çekebilirsiniz',
  withdrawAddress: 'Para çekme adresi',
  withdrawAddressInput: 'Para çekme adresini girin',
  withdrawNet: 'Ağ',
  withdrawNum: 'Miktar',
  withdrawNumInput: 'Giriş miktarı',
  withdrawNumLess: 'Yetersiz bakiye',
  withdrawMin: 'Minimum para çekme tutarı',
  balanceUse: 'Mevcut',
  actualMoney: 'Alma tutarı',
  actualMoneyText: 'Alma tutarı',
  financePwd: 'fon şifresi',
  trade: '',
  withdrawFail: 'Geri çekilemedi',
  commitTime: 'Tarih',
  from: 'İtibaren',
  to: 'İle',
  transferMoney: 'Giriş miktarı',
  transferSuccess: 'Tamamen aktar',
  transferError: 'Aktarılamadı',
  transferHistory: 'Transfer Kaydı',
  updateFinancePwd: 'fon şifresini değiştir',
  confirmPwd: 'Şifreyi Onayla',
  confirmPwdInput: 'Giriş onay şifresi',
  updatePwd: 'Şifre değiştir',
  updatePwdSuccess: 'Tamamen değiştir',
  updatePwdError: 'değiştirilemedi',
  noAlike: 'Şifre tutarsız',
  emailUpdate: 'e-posta adresini değiştir',
  loginPwdUpdate: 'Giriş şifresini değiştir',
  financePwdUpdate: 'fon şifresini değiştir',
  newEmail: 'Yeni email adresi',
  emailUpdateTip: 'Yeni e-posta adresi girin',
  checkEmail: 'E-posta Adresini doğrulayın',
  updateEmail: 'e-posta adresini değiştir',
  updateEmailError: 'değiştirilemedi',
  updateEmailSuccess: 'Tamamen değiştir',
  checkError: 'Doğrulanamadı',
  pwdLen: '8-20 karakter',
  inviteUrl: 'Yönlendirme bağlantısı',
  myInviteCode: 'Davet Kodu',
  fastInvite: 'Şimdi Davet Et',
  myInvite: 'Davet Listesi',
  registerTime: 'Tarih',
  bulletin: 'Duyuru',
  inviteFriend: 'Arkadaşları davet etmek',
  saveAccount: 'Hesabı ve şifreyi kaydet',
  indicator: 'Göstergeler',
  type: 'Çeşitleri',
  amount: 'Miktar',
  scanError: 'Görüntü tanıma başarısız oldu',
  scanInitErr: 'Başlatma başarısız oldu',
  noCamera: 'Kamera izni yok',
  secondTip: 'Tutar {tip}\'den az olmamalıdır',
  completePrice: 'Fiyat',
  profitText: 'Kâr',
  profitAndLoss: 'Tahmini kar ve zarar',
  timeUp: 'Zaman doldu',
  balanceText: 'Tarih',
  closeAll: 'Hepsini kapat',
  assetRate: 'Marj Oranı',
  cancel: 'İptal',
  cancelInfo: 'İptal etmek için onayla?',
  buyCurrency: 'Sembol',
  closeCurrency: 'Para birimi',
  buyPrice: 'Fiyat',
  valuation: 'Değerleme',
  profitP: 'Teslim olmak',
  buySuccess: 'başarıyla',
  swiperTitle1: 'Dünyanın En İyi Dijital Kripto Borsası',
  swiperSub1: '100.000\'den Fazla Kullanıcı Tarafından Güvenilir',

  ieoBuying: 'devam ediyor',
  ieoOpen: 'Başlangıç ​​tarihi (UTC-5)',
  ieoClose: 'Bitiş tarihi(UTC-5)',
  about: 'Hakkında',
  ieoPlan: 'Dağıtım',
  ieoInfo: 'Bilgi',
  ieoContact: 'Sosyal medya',
  ieoStart: 'Başlatma',
  ieoBuyStart: 'IEO Başlangıç',
  ieoBuyEnd: 'IEO Sona ermek',
  ieoIsTrade: 'Öğle yemeği',
  introduce: 'Giriş',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO Tarih',
  rechargeTip1: '1. Bu sizin en yeni ve benzersiz adresinizdir, platform sizden asla başka bir hesaba para aktarmanızı veya doğrulama kodunuzu istemez.',
  rechargeTip2: '2. Depozito, onay için en az 10 ağ bloğu gerektirir. Onaylandıktan sonra, hesabınız otomatik olarak fonları alacaktır.',
  withdrawTip1: '1. Bu para çekme işlemi için para yatırma tarafındakiyle aynı ağı seçtiğinizden emin olun ve alıcı platformun ağı desteklediğini kontrol edin. Aksi takdirde varlıklarınızı kaybedersiniz.',
  withdrawTip2: '2. Ağ engellemeleri, platform başvurunuzu onayladıktan sonra geri çekilmenizi onaylayacaktır.',
  withdrawTip3: '3. Para çekme işlemi, onay için en az 10 ağ bloğu gerektirir. Onaylandıktan sonra, hesabınız otomatik olarak fonları alacaktır.',
  retrievePwd: 'Şifreyi yenile',
  order: 'Emirler', 
  registerTitle: 'Hesap Aç', 
  emailCheck: 'E-posta Doğrulama Kodu', 
  haveAccount: 'Zaten hesabınız var mı?',  
  userInfo: 'Kişisel bilgi', 
  userAuth: 'Hesap Doğrulama', 
  footerTip: 'Nihai yorumlama hakkı platforma aittir.', 
  marketInfo: 'piyasalar', 
  viewMore: 'Daha fazlasını bulmak için tıklayın', 
  tradeText: 'Ticaret', 
  position: 'Handikap', 
  level: 'Seviye', 
  oneBuy: 'Satın almak', 
  oneSell: 'Satmak', 
  newTrade: 'Gerçek zaman',
  tradePair: 'Sembol', 
  historyEntrust: 'Sipariş Geçmişi', 
  allOrder: 'Tüm Kayıt', 
  total: 'Toplam', 
  oneOrder: 'Emirler', 
  priceTip: 'girdi fiyatı', 
  numberTip: 'Giriş miktarı', 
  foundInput: 'Giriş miktarı', 
  foundTip: 'Lütfen miktarı girin', 
  countdown: 'geri sayım', 
  tradeMoney: 'Miktar', 
  walletOverview: 'genel bakış', 
  ieoBuyAccount: 'IEO Hesap', 
  selectRechargeAddress: 'Para yatırma adresini seçin', 
  successText: 'Başarı', 
  errorText: 'Başarısız', 
  selectWithdrawAddress: 'Para çekme adresini girin', 
  withdrawSuText: 'Başarıyla Gönder', 
  transferMoneyLabel: 'Miktar', 
  paramsError: 'Parametre Hatası', 
  reset: 'Sıfırla', 
  selectText: 'Arama', 
  emailManage: 'Hesap Yönetimi', 
  loginPwdDes: 'Oturum açma parolanızı değiştirin', 
  updateText: 'Değiştir', 
  financePwdDes: 'Fon şifrenizi değiştirin', 
  emailManageDes: 'Hesap güvenliğinizi koruyun', 
  oldEmail: 'Orijinal e-posta adresi', 
  countryAreaTip: 'Girilen ülke veya bölge',  
  agreeTip: 'Lütfen "Kullanıcılar Sözleşmesi" ni kontrol edin',
  volume24h: '24H Vol',
  // 首页
  currencyHomeType: 'Sembol',
  whyUs: 'NEDEN BİZ?',
  why1: 'Güvenli ve Sigortalı',
  why1Des: 'Kripto para birimlerinin %80\'ini güvenli soğuk cüzdanımızda saklıyoruz. Soğuk hava depoları, Ledger tarafından güvence altına alınır ve sigortalanır.',
  why2: 'Hızlı İşlemler',
  why2Des: 'Milyonlarca işlemi halledebiliriz. Sistem altyapımız, artan talebi karşılamak için birkaç saniye içinde ölçeklenebilir.',
  why3: 'Basit ve Kolay Kullanılabilir',
  why3Des: 'Platformumuzda işlem yapmak süper hızlı bir deneyimdir. Milyonlarca kişi tarafından kullanılan bir teknoloji geliştirdik ve bu deneyim, bu güçlü alışverişi oluşturmamıza yardımcı oldu.',
  why4: 'Anında Ticaret',
  why4Des: 'Tek bir tıklamayla anında 150\'den fazla kripto para birimi ticareti yapın.',
  why5: 'En iyi fiyat',
  why5Des: 'Tüm kripto para birimlerinde yüksek likidite ile ticaret yapın.',
  why6: 'Güven',
  why6Des: '800.000\'den fazla kullanıcı inandı ve yüzlerce crore ticaret yaptı.',
  homeGift: 'KAYIT BONUSU İÇİN İŞLEM YAPMAK İÇİN ÜCRETSİZ 30$ KAZANIN.',
  popular1: '100.000 ABD Doları+',
  popular1Des: 'Toplam İşlem Hacmi',
  popular2: '150+',
  popular2Des: 'Desteklenen Para Birimleri',
  popular3: '800K+',
  popular3Des: 'Doğrulanmış Kullanıcılar',
  globalText: 'KRİPTO PARA TİCARETİNDE 800 BİNDEN FAZLA KÜRESEL KULLANICI TARAFINDAN GÜVENİLİR',
  startText: 'SADECE 5 DAKİKADA BAŞLAYIN.',
  start1: 'Üye olmak',
  start1Des: 'Web sitesini açın veya uygulamayı indirin ve e-posta kullanarak kaydolun.',
  start2: 'KYC',
  start2Des: 'Kimlik Kanıtınızı Elinizin Altında Tutun, Temel KYC Ayrıntılarını Girin ve Hesabınızı Doğrulatın.',
  start3: 'Depozito',
  start3Des: 'Dijital Cüzdanınıza Kolayca Para Yatırın.',
  start4: 'Satın almak/Satmak',
  start4Des: 'En iyi fiyata Kripto Para Alım Satım ve Ticareti yapın.',
  appTitle: 'UYGULAMA ŞİMDİ MEVCUT',
  createTeam: 'Yedek fonlar',
  angelFund: 'Melek Fonu',
  ecology: 'Kurumlar ve Ekosistemler',
  presale: 'Satış Öncesi Sürüm',
  mechanism: 'Madencilik Mekanizması',
  trading: 'Borsa Ticareti',

  issuePrice: 'Ihraç Fiyatı',
  minBuyPrice: 'Minimum Abonelik',
  buyNum: 'Günlük Abonelik Sayısı',
  buyTotal: 'Toplam Günlük Abonelik',
  Increment: 'Abonelik Artışı',
  blockchain: 'Blockchain',

  sellTotal: 'Toplam Ihraç',
  currentBuy: 'Mevcut Abonelik',
  dowload: 'İndirme Merkezi',
  lessMinTip: 'Minimum miktardan az olamaz.',
  appDes: 'Sınıfının en iyisi düzenlenmiş kripto para birimi takas platformunun güvenlik, ticaret ve yeteneklerine doğrudan akıllı telefonunuzdan sahip olun.',
  footerBottomTip: 'Bu web sitesinde sağlanan bilgiler yatırım tavsiyesi, finansal tavsiye, alım-satım tavsiyesi veya başka herhangi bir tavsiye teşkil etmez ve web sitesinde yer alan içerikleri bu şekilde görmemelisiniz. Kripto para yatırımları, doğası gereği değişken ve yüksek risklidir. Kaybetmeyi göze alabileceğinizden daha fazla yatırım yapmayın.',
  stakeAccount: 'Bahis Hesabı',
  serviceAccount: 'Hizmet Hesabı',
  lock: 'Kilit',
  lockPrice: 'Kilitli Fiyat',
  ieoHistory: 'IEO Tarihi',
  lockHistory: 'Kilit Geçmişi',
  sellHistory: 'Satış Geçmişi',
  stake: 'Staking',
  service: 'Hizmet',
  ieoBuyTip: "*IEO Bildirimi: LTJ sayısı sınırlıdır. Kullanıcının IEO uygulamasını inceleyeceğiz ve her 3 saatte bir rastgele kura çekeceğiz. Nispeten büyük miktarda olan kullanıcıların LTJ satın alma olasılığı daha yüksek olacaktır. Her kullanıcı günde en fazla 3 defa satın alabilir.",
  ieoLockTip: "*Kilit Fiyatı: Kullanıcıların, LTJ'nin fiyatını kilitlemek için rehin para birimi olarak USDT'yi kullanmaları gerekir ve rehin oranı, LTJ değerinin %30'udur. LTJ başlatıldıktan sonra, kullanıcılar LTJ'yi o sırada kilitlenen fiyattan satabilirler.",
  ieoSellTip: "*IEO Satışı: LTJ satışı, hizmet ücreti olarak LTJ değerinin %10'unu gerektirir. LTJ'yi sattıktan sonra, takas ettiğiniz USDT 20 gün içinde, taahhüt edilen USDT ise 20 gün içinde çözülecektir.",
  whitePaper: 'BEYAZ KAĞIT',
  cost: 'Maliyet',
  currentEstimate: 'Cari Değerleme',
  stakeNum: 'Staking Miktarı',
  estimate: 'Değerleme',
  consume: 'Tüketmek',
  
  seedSell: 'Tohum Yuvarlak',
  privateSell: 'Özel Tur',
  openSell: 'KOL Yuvarlak',
  ieoAward: 'halka açık tur',
  ieoGrowUp: 'Takım',
  partner: 'Danışman',
  consultant: 'Pazarlama',
  ieoName: 'Likidite',
  conAward: 'AI Kazanmak İçin',
  staking: 'Staking',

  release: 'Serbest bırakmak',
  closedTime: 'Kapalı Zaman',
  remark: 'açıklama',

  totalProfit: 'Toplam kar',
  transferIn: 'İçeriye transfer et',
  transferOut: 'Dışa Aktar',
  equity: 'net değer',
  
  homeremind1: 'Popüler Kripto Para Birimleri',
  homeremind2: 'Daha fazla pazar görüntüle',
  homeremind3: 'Son fiyat(USD)',
  homeremind4: '24 Saat Değişim',
  homeremind5: 'Aksiyon',
  homeremind6: 'Daha fazla göster',
  homeremind7: "Kullanıcıların En İyi Seçimleri",
  homeremind8: '24 saatlik Hacim',
  homeremind9: 'Prime Sanal Varlıklar',
  homeremind10: 'Temel Ürünlerimiz',
  homeremind11: 'Aralarından seçim yapabileceğiniz çok çeşitli ticaret araçları',
  homeremind12: 'Leke',
  homeremind13: 'Ticaret için 700\'den fazla kripto mevcut',
  homeremind14: 'Vadeli işlem ticareti yaparak deneyiminizi yükseltin',
  homeremind15: 'NYECoins Earn',
  homeremind16: 'Sektördeki en iyi APY\'ler',
  homeremind17: 'Çeşitlendirilmiş Hizmetlerimiz',
  homeremind18: 'Çeşitli hizmetlerimizle sınırsız olanakları keşfedin',
  homeremind19: 'IEO Aboneliği',
  homeremind20: 'En İyi Satış Öncesi Kripto Paralar',
  homeremind21: 'VIP Müşteri Hizmetleri',
  homeremind22: '7*24 Saat',
  homeremind23: 'Toplum',
  homeremind24: 'Burada bizimle zenginlik fırsatlarını ortaya çıkarın',
  homeremind25: 'Ticarette kalın. Her yerde, her zaman.',
  homeremind26: 'Mobil uygulamamız ve web sitesi terminalimiz ile gelişmelerden haberdar olun.',
  homeremind27: 'Bizi Sosyal Medyada Takip Edin',
  homeremind28: 'En son haberlerimiz ile güncel kalın',
  homeremind29: 'Her Zaman, Her Yerde Ticaret Yapın',
  homeremind30: 'NYECoins\'u iOS, Android veya API\'nizle deneyin.',

  msb: "NYECoins, MSB, ABD'de Düzenlenmektedir.",
  msb1: 'Güvenilir, Güvenli, Hızlı',
  checkNow: 'Şimdi kontrol et',

  foundingTeam: 'Kurucu Ekip',
  advisorTeam: 'Danışman Ekibi',
  community: 'Toplum',

  phone: 'Telefon numarası',
  phoneTip: 'Telefon numaranızı girin',


  rebateAccount: 'komisyon hesabı',
  rebateType: 'tip',
  recommend: 'Referans',
  myRebate: 'benim komisyonum',
  rebateRule: 'Komisyon kuralları',
  yes: 'Evet',
  no: 'HAYIR',
  authName: 'Doğrulamak',
  countryName: 'Ülke bölgesi',
  allCommission: 'Toplam komisyon',
  myClient: 'Müşterilerim',
  inviteUrl1: 'Yönlendirme Bağlantısı',
  inviteCode1: 'Yönlendirme Kodu',
  commissioned: 'komisyon',

  swiperTitle2: 'Çevrimiçi Kripto Ticaretinde Küresel Lider. Kripto para birimlerini satın alın, satın ve tutun.',
  swiperSub2: 'Milyonlarca Küresel Kullanıcıya Dijital Varlık Yolculuklarında Rehberlik Ettik.',

  homeremind31: 'Biz ne yaptık?',
  homeremind32: 'Kripto para tüccarları ile sıradan tüketiciler arasındaki çizgiyi bulanıklaştırmak istiyoruz. Ücretsiz giriş imkanı sunarak ve insanların paranın geleceği olduğuna inandığımız şeyi anlamalarına ve onlara güvenmelerine yardımcı olarak kripto para birimini popüler hale getireceğiz.',
  homeremind33: 'NYECOINS\'in Özellikleri',
  homeremind34: 'NYECOINS, açık ve adil anlaşmalar sağlamak için satıcıları ve alıcıları merkez bankası veya tek bir yönetici olmadan bir araya getiriyor',
  homeremind35: 'Daha Düşük İşlem Maliyetleri',
  homeremind36: 'Daha düşük işlem ödemeleri ve operasyonel giderlerle müşterilere sunulan merkezi olmayan bir platform.',
  homeremind37: 'C2C İşlemleri',
  homeremind38: 'NYECOINS ile C2C işlemleri kolay, hızlı ve güvenlidir. Platformdaki herhangi bir müşteriye BTC\'yi veya seçtiğiniz para birimini gönderin.',
  homeremind39: 'Markalar için Premium Araç Seti',
  homeremind40: 'Şirketlere müşterilerini tanımalarına yönelik araçlar ve bilgiler sunarak tüketici hedeflemeyi optimize ettik.',
  homeremind41: 'Global Ortaklar tarafından desteklenmektedir',
  homeremind42: 'NYECOINS Ekibi',
  homeremind43: 'Sizi her şeyi gerçekleştirecek ekiple tanıştıralım.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Kurucu Ortak',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Kıdemli Yazılım Mühendisi',
  homeremind48: 'Richard Norman',
  homeremind49: 'Topluluk yöneticisi',
  homeremind50: 'Paul Garrett',
  homeremind51: 'UI/UX Tasarımcısı',


  ieoIntro1: 'Likidite Teşvikleri',
  ieoIntro2: 'Staking Rezervi',
  ieoIntro3: 'Ekosistem Ortakları',
  ieoIntro4: 'Halk indirimi',
  ieoIntro5: 'Özel Satış A',
  ieoIntro6: 'Tohum Yuvarlak',
  ieoIntro7: 'LinkToJoin Ekibi',
  ieoIntro8: 'Kurumsal Ücret Rezervi',
}