import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0e358b12"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "login-page"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "sub-title"
};
const _hoisted_4 = {
  class: "custom-label"
};
const _hoisted_5 = {
  class: "custom-label"
};
const _hoisted_6 = {
  class: "login-footer"
};
import { ref, reactive, onMounted } from 'vue';
import router from '@/router';
import store from '@/store';
import { loginApi, getCaptcha } from '@/apis';
import { decrypt, encrypt } from '@/util/saveAccount';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'LoginPage',
  setup(__props) {
    const {
      t
    } = useI18n();
    const loading = ref(false);
    const form = reactive({
      email: '',
      password: ''
    });
    const saveAccount = ref(false);
    const captchaId = ref('');
    const captchaImg = ref('');
    const captcha = ref('123123');
    onMounted(() => {
      const str = localStorage.getItem('pcstore_save');
      if (str && str !== '') {
        const data = decrypt(str);
        try {
          const msg = JSON.parse(data);
          form.email = msg.a ? msg.a : '';
          form.password = msg.m ? msg.m : '';
          saveAccount.value = true;
        } catch (err) {
          console.warn(err);
        }
      }
      init();
    });
    const init = async () => {
      // 获取图形验证码
      const res = await getCaptcha();
      if (res.code === 200) {
        captchaId.value = res.data.id;
        captchaImg.value = res.data.img;
      }
    };
    const login = async () => {
      loading.value = true;
      const res = await loginApi({
        captcha_id: captchaId.value,
        captcha_code: captcha.value,
        account: form.email,
        password: form.password
      });
      loading.value = false;
      if (res.code !== 200) {
        message.error(res.message !== '' ? res.message : t('登錄失敗'));
        return;
      }
      if (res.data.token) {
        store.commit('tokenChange', res.data.token);
        store.commit('emailChange', form.email);
        store.commit('getUser');
        localStorage.setItem('pcstore_info', JSON.stringify({
          token: res.data.token,
          time: dayjs().unix(),
          email: form.email
        }));
        if (saveAccount.value) {
          const str = encrypt({
            a: form.email,
            m: form.password
          });
          localStorage.setItem('pcstore_save', str);
        } else {
          localStorage.removeItem('pcstore_save');
        }
        message.success(t("loginSuccess"));
        router.replace({
          name: 'home'
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_a_input = _resolveComponent("a-input");
      const _component_a_form_item = _resolveComponent("a-form-item");
      const _component_a_input_password = _resolveComponent("a-input-password");
      const _component_a_checkbox = _resolveComponent("a-checkbox");
      const _component_a_button = _resolveComponent("a-button");
      const _component_a_form = _resolveComponent("a-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, "NYECoins " + _toDisplayString(_unref(t)('login')), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('welcome')), 1), _createVNode(_component_a_form, {
        model: form,
        layout: "vertical",
        style: {
          "margin-top": "40px"
        },
        onFinish: login
      }, {
        default: _withCtx(() => [_createVNode(_component_a_form_item, {
          name: "email",
          rules: [{
            required: true,
            message: _unref(t)('emailInput')
          }]
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('email')), 1), _createVNode(_component_a_input, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.email,
            "onUpdate:value": _cache[0] || (_cache[0] = $event => form.email = $event),
            placeholder: _unref(t)('emailInput')
          }, null, 8, ["value", "placeholder"])]),
          _: 1
        }, 8, ["rules"]), _createVNode(_component_a_form_item, {
          name: "password",
          rules: [{
            required: true,
            message: _unref(t)('loginPwdTip')
          }]
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('loginPwd')), 1), _createVNode(_component_a_input_password, {
            style: {
              "height": "45px"
            },
            allowClear: "",
            value: form.password,
            "onUpdate:value": _cache[1] || (_cache[1] = $event => form.password = $event),
            placeholder: _unref(t)('loginPwdTip')
          }, null, 8, ["value", "placeholder"])]),
          _: 1
        }, 8, ["rules"]), _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_a_checkbox, {
            checked: saveAccount.value,
            "onUpdate:checked": _cache[2] || (_cache[2] = $event => saveAccount.value = $event)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('saveAccount')), 1)]),
            _: 1
          }, 8, ["checked"]), _createVNode(_component_a_button, {
            type: "primary",
            block: "",
            loading: loading.value,
            "html-type": "submit",
            style: {
              "height": "45px",
              "margin-top": "30px"
            }
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('login')), 1)]),
            _: 1
          }, 8, ["loading"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", {
        class: "footer-link",
        onClick: _cache[3] || (_cache[3] = $event => _unref(router).push({
          name: 'register'
        }))
      }, _toDisplayString(_unref(t)('register')), 1), _createElementVNode("span", {
        class: "footer-link",
        onClick: _cache[4] || (_cache[4] = $event => _unref(router).push({
          name: 'forget'
        }))
      }, _toDisplayString(_unref(t)('forgetPwd')), 1)])]);
    };
  }
};