export default {
  required: 'ที่จำเป็น',
  home: 'หน้าแรก',
  market: 'ตลาด',
  currency: 'ซื้อขาย',
  walletTrade: 'จุด',
  contract: 'ฟิวเจอร์ส',
  wallet: 'กระเป๋าสตางค์',
  pulling: 'ดึงลงเพื่อรีเฟรช...',
  loosing: 'ปล่อยเพื่อรีเฟรช...',
  loading: 'กำลังโหลด...',
  loadingError: 'โหลดไม่สำเร็จ',
  nothing: 'ไม่มีข้อมูล',
  finished: '',
  price: 'ราคา',
  number: 'ปริมาณ',
  recharge: 'เงินฝาก',
  transfer: 'โอนย้าย',
  invite: 'เชิญชวน',
  inviteDes: 'เชิญเพื่อนเข้าร่วมกับเรา',
  contact: 'ติดต่อเรา',
  contactDes: '7*24 ออนไลน์',
  fastTrade: 'ตัวเลือก',
  name: 'ชื่อ',
  fastPrice: 'ราคา',
  trend: '24H %',
  all: 'ทั้งหมด',
  growthList: 'เกนเนอร์',
  loserList: 'ผู้แพ้',
  vol: '24H Vol',
  buy: 'ซื้อ',
  sell: 'ขาย',
  optimal: 'ราคาตลาด',
  marketOrder: 'ตลาดซื้อขาย',
  limitOrder: 'จำกัด การค้า',
  useable: 'มีอยู่',
  tradeVol: 'จำนวน',
  entrust: 'คำสั่งจำกัด',
  entrustTotal: 'จำนวน',
  noLogin: 'ออกจากระบบ',
  toLogin: 'ลงชื่อเข้าใช้ตอนนี้',
  cancelOrder: 'ยกเลิก',
  cancelError: 'ยกเลิกไม่สำเร็จ',
  cancelSuccess: 'ยกเลิกเรียบร้อยแล้ว',
  cancelTip: 'แน่ใจว่าจะยกเลิก？',
  completed: 'สมบูรณ์',
  canceled: 'ยกเลิก',
  marketPrice: 'ตลาด',
  limitPrice: 'จำกัด',
  entrustPrice: 'ราคา',
  entrustNum: 'ปริมาณ',
  currencyType: 'สัญลักษณ์',
  headerCurrencyType: 'สัญลักษณ์',
  currencyOrder: 'บันทึกการค้า',
  history: 'ประวัติศาสตร์',
  tryAgain: 'ลองอีกครั้ง',
  entrustPriceTip: 'ราคาอินพุต',
  tradeNumTip: 'ปริมาณอินพุต',
  tradeNumLess: 'ปริมาณควรมากกว่า 0',
  numLessTip: 'ยอดเงินคงเหลือไม่เพียงพอ',
  tradePriceTip: 'จำนวนเงินที่ป้อน',
  balanceTip: 'ยอดเงินคงเหลือไม่เพียงพอ',
  tradeError: 'ซื้อขายไม่สำเร็จ',
  tradeSuccess: 'เทรดให้สำเร็จ',
  tip: 'สังเกต',
  tradeConfirm: 'ยืนยันการซื้อขาย？',
  cancelText: 'ยกเลิก',
  confirmText: 'ยืนยัน',
  toBuy: 'ซื้อ',
  toSell: 'ขายชอร์ต',
  confirmToBuy: 'ซื้อ',
  confirmToSell: 'ขายชอร์ต',
  slPrice: 'SL ราคา',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP ราคา',
  currentOrder: 'คำสั่งซื้อปัจจุบัน',
  historyOrder: 'ประวัติศาสตร์',
  setting: 'ชุด',
  unwind: 'ปิด I',
  handUnwind: 'ปิดด้วยตนเอง',
  futuresPay: '',
  forceUnwind: 'การชำระบัญชี',
  slTp: 'SL/TP',
  fastTradeUnwind: 'ตัวเลือก',
  blowUp: 'การชำระบัญชี',
  openNum: 'ปริมาณ',
  warrant: 'ระยะขอบ',
  openPrice: 'ราคา',
  fee: 'ค่าธรรมเนียม',
  profit: 'กำไร',
  lastPrice: 'ราคาปัจจุบัน',
  unwindPrice: 'ราคาปิด',
  unwindError: 'ไม่สามารถปิด',
  unwindSuccess: 'ปิดสำเร็จ',
  setSuccess: 'ตั้งค่าสำเร็จ',
  setError: 'ตั้งค่าไม่สำเร็จ',
  setSlTp: 'ชุด SL/TP',
  second: 's',
  multiple: 'หลายรายการ',
  direction: 'พิมพ์',
  tradeCurrency: 'สัญลักษณ์',
  loss: 'การสูญเสีย',
  fill: 'ชนะ',
  executePrice: 'ราคา',
  currentPrice: 'ราคาปัจจุบัน',
  closePrice: 'ราคาปิด',
  secondNum: 'ระยะเวลา',
  contractOrder: 'คำสั่งฟิวเจอร์ส',
  fastTradeOrder: 'คำสั่งตัวเลือก',
  overview: 'ภาพรวม',
  ieoBuy: 'IEO',
  balanceTotal: 'สินทรัพย์รวม',
  withdraw: 'ถอน',
  tradeOrder: 'คำสั่งซื้อขาย',
  rechargeHistory: 'บันทึกการฝาก',
  withdrawHistory: 'บันทึกการถอน',
  freeze: 'แช่แข็ง',
  amountTo: '',
  tipText: 'สังเกต',
  ieoTip: 'มันจะมาเร็ว ๆ น',
  financeList: 'บันทึกทางการเงิน',
  currencyAccount: 'บัญชีสปอต',
  contractAccount: 'บัญชีฟิวเจอร์ส',
  time: 'เวลา',
  status: 'สถานะ',
  balanceTyle: 'บัญชี',
  pay: 'ผล',
  income: 'รายได้',
  lastBalance: 'สมดุล',
  orderNo: 'ID',
  money: 'จำนวน',
  date: 'วันที่',
  finallyIncome: 'รับจำนวน',
  commitMoney: 'จำนวน',
  orderNum: 'ID',
  waitReview: 'กำลังทบทวน',
  reviewPass: 'ผ่านไป',
  reviewReject: 'ถูกปฏิเสธ',
  waitWallet: 'กำลังทบทวน',
  walletPass: 'ผ่านไป',
  walletReject: 'ถูกปฏิเสธ',
  withdrawSuccess: 'ถอนสำเร็จ',
  withdrawError: 'โอนไม่สำเร็จ',
  high24H: '24H สูง',
  low24H: '24H ต่ำ',
  num24H: '24H Vol',
  minute: 'นาที',
  hour: 'ชั่วโมง',
  day: 'วัน',
  week: 'สัปดาห์',
  markets: 'ตลาด',
  open: 'เปิด',
  close: 'ปิด I',
  high: 'สูง',
  low: 'ต่ำ',
  volume: 'Vol',
  fastLogin: 'ลงชื่อเข้าใช้ตอนนี้',
  register: 'ลงทะเบียน',
  auth: 'ตรวจสอบ',
  authSuccess: 'ตรวจสอบแล้ว',
  noPass: 'ถูกปฏิเสธ',
  authCommit: 'กำลังทบทวน',
  onAuth: 'ไม่ได้รับการยืนยัน',
  onLogin: 'ออกจากระบบ',
  securitySet: 'ความปลอดภัย',
  userAgree: 'การสนับสนุนผู้ใช้',
  aboutUs: 'เกี่ยวกับเรา',
  logout: 'ออกจากระบบ',
  logoutSuccess: 'ออกจากระบบสำเร็จ',
  lookAuthInfo: 'ตรวจสอบข้อมูลของฉัน',
  authAgain: 'ยืนยันอีกครั้ง',
  authInfo: 'ข้อมูล',
  account: 'บัญชี',
  countryArea: 'ประเทศ/ภูมิภาค',
  idNo: 'ID#',
  idNoTip: 'ป้อนหมายเลข ID',
  email: 'อีเมล',
  emailInput: 'ป้อนที่อยู่อีเมล',
  setLoginPwd: 'ตั้งรหัสผ่านเข้าสู่ระบบ',
  loginPwdInput: 'ใส่รหัสผ่านเข้าสู่ระบบ (8-20 ตัวอักษร)',
  loginPwdTip: 'ใส่รหัสผ่านเข้าสู่ระบบ',
  loginPwdLen: 'รหัสผ่านเข้าสู่ระบบควรมีความยาว 8-20 ตัวอักษร',
  setFinancePwd: 'ตั้งรหัสผ่านกองทุน',
  financePwdInput: 'ใส่รหัสผ่านกองทุน (6-20 ตัวอักษร)',
  financePwdTip: 'ใส่รหัสผ่านกองทุน',
  financePwdLen: 'รหัสผ่านกองทุนควรมีความยาว 6-20 ตัวอักษร',
  inviteCode: 'รหัสการเชิญ',
  inviteCodeTip: 'ใส่รหัสคำเชิญ',
  inviteInput: 'จำเป็นต้องมีรหัสคำเชิญ',
  imageCode: 'Captcha',
  imageCodeInput: 'ป้อนแคปต์ชา',
  registerTip: 'ลงทะเบียนหมายความว่าคุณตกลง ',
  agree: '《ข้อตกลงผู้ใช้》',
  agreeText: 'ข้อตกลงผู้ใช้',
  next: 'ถัดไป',
  registerSuccess: 'ลงทะเบียนอย่างสมบูรณ์',
  registerError: 'ลงทะเบียนไม่สำเร็จ',
  securityCheck: 'ความปลอดภัย',
  checkCodeInput: 'ใส่รหัสยืนยัน',
  sendCheckCode: 'ส่ง',
  sendSuccess: 'ส่งแล้ว',
  sendError: 'การส่งล้มเหลว',
  welcome: 'ยินดีต้อนรับสู่',
  loginEmail: 'อีเมล',
  loginEmailInput: 'ป้อนที่อยู่อีเมล',
  loginPwd: 'ลงชื่อรหัสผ่าน',
  login: 'เข้าสู่ระบบ',
  forgetPwd: 'ลืมรหัสผ่าน',
  loginTip: 'เข้าสู่ระบบหมายความว่าคุณตกลง',
  loginSuccess: 'เข้าสู่ระบบอย่างสมบูรณ์',
  loginError: 'ไม่สามารถเข้าสู่ระบบ',
  fullname: 'ชื่อ',
  fullnameTip: 'ใส่ชื่อของคุณ',
  country: 'ประเทศ/ภูมิภาค',
  countryTip: 'ประเทศ/ภูมิภาคที่ป้อน',
  imgFront: 'อัปโหลดรูปถ่ายด้านหน้าบัตรประจำตัวของคุณ',
  imgReverse: 'อัปโหลดรูปถ่ายด้านหลังบัตรประจำตัวของคุณ',
  commit: 'ส่ง',
  commitSuccess: 'ส่งให้ครบ',
  commitError: 'ส่งไม่สำเร็จ',
  selectCurrency: 'เลือกการเข้ารหัสลับ',
  rechargeNet: 'เครือข่าย',
  rechargeAddress: 'ที่อยู่ฝาก',
  copyAddress: 'คัดลอกที่อยู่',
  minRecharge: 'จำนวนเงินฝากขั้นต่ำ',
  copySuccess: 'คัดลอกอย่างสมบูรณ์',
  copyError: 'คัดลอกไม่สำเร็จ',
  withdrawTip: 'คุณสามารถถอนได้หลังจากยืนยันบัญชีของคุณ',
  withdrawAddress: 'ที่อยู่ถอน',
  withdrawAddressInput: 'ป้อนที่อยู่การถอน',
  withdrawNet: 'เครือข่าย',
  withdrawNum: 'จำนวน',
  withdrawNumInput: 'จำนวนเงินที่ป้อน',
  withdrawNumLess: 'ยอดเงินคงเหลือไม่เพียงพอ',
  withdrawMin: 'จำนวนเงินที่ถอนขั้นต่ำ',
  balanceUse: 'มีอยู่',
  actualMoney: 'รับจำนวน',
  actualMoneyText: 'รับจำนวน',
  financePwd: 'รหัสผ่านกองทุน',
  trade: '',
  withdrawFail: 'ถอนไม่สำเร็จ',
  commitTime: 'วันที่',
  from: 'จาก',
  to: 'ถึง',
  transferMoney: 'จำนวนเงินที่ป้อน',
  transferSuccess: 'โอนให้เรียบร้อย',
  transferError: 'โอนไม่สำเร็จ',
  transferHistory: 'บันทึกการโอน',
  updateFinancePwd: 'เปลี่ยนรหัสผ่านกองทุน',
  confirmPwd: 'ยืนยันรหัสผ่าน',
  confirmPwdInput: 'ใส่รหัสผ่านยืนยัน',
  updatePwd: 'เปลี่ยนรหัสผ่าน',
  updatePwdSuccess: 'เปลี่ยนอย่างสมบูรณ์',
  updatePwdError: 'เปลี่ยนไม่สำเร็จ',
  noAlike: 'รหัสผ่านไม่สอดคล้องกัน',
  emailUpdate: 'เปลี่ยนที่อยู่อีเมลล์',
  loginPwdUpdate: 'เปลี่ยนรหัสผ่านเข้าสู่ระบบ',
  financePwdUpdate: 'เปลี่ยนรหัสผ่านกองทุน',
  newEmail: 'ที่อยู่อีเมลใหม่',
  emailUpdateTip: 'ป้อนที่อยู่อีเมลใหม่',
  checkEmail: 'ยืนยันที่อยู่อีเมล',
  updateEmail: 'เปลี่ยนที่อยู่อีเมลล์',
  updateEmailError: 'เปลี่ยนไม่สำเร็จ',
  updateEmailSuccess: 'เปลี่ยนอย่างสมบูรณ์',
  checkError: 'ล้มเหลวในการตรวจสอบ',
  pwdLen: '8-20 ตัว',
  inviteUrl: 'ลิงค์อ้างอิง',
  myInviteCode: 'รหัสการเชิญ',
  fastInvite: 'เชิญเลย',
  myInvite: 'รายการเชิญ',
  registerTime: 'วันที่',
  bulletin: 'ประกาศ',
  inviteFriend: 'เชิญเพื่อน ๆ',
  saveAccount: 'บันทึกบัญชีและรหัสผ่าน',
  indicator: 'ตัวบ่งชี้',
  type: 'ชนิดของ',
  amount: 'จำนวน',
  scanError: 'การจดจำภาพล้มเหลว',
  scanInitErr: 'การเริ่มต้นล้มเหลว',
  noCamera: 'ไม่อนุญาตให้ใช้กล้อง',
  secondTip: 'จำนวนเงินไม่ควรต่ำกว่า  {tip}',
  completePrice: 'ราคา',
  profitText: 'กำไร',
  profitAndLoss: 'กำไรขาดทุนโดยประมาณ',
  timeUp: 'หมดเวลา',
  balanceText: 'ประวัติศาสตร์',
  closeAll: 'ปิดทั้งหมด',
  assetRate: 'อัตรากำไรขั้นต้น',
  cancel: 'ยกเลิก',
  cancelInfo: 'ยืนยันการยกเลิก?',
  buyCurrency: 'สัญลักษณ์',
  closeCurrency: 'สกุลเงิน',
  buyPrice: 'ราคา',
  valuation: 'การประเมินค่า',
  profitP: 'ผลผลิต',
  buySuccess: 'เรียบร้อยแล้ว',
  swiperTitle1: 'การแลกเปลี่ยนสกุลเงินดิจิทัลชั้นนำของโลก',
  swiperSub1: 'ได้รับความไว้วางใจจากผู้ใช้มากกว่า 100,000 ราย',

  ieoBuying: 'กำลังดำเนินการ',
  ieoOpen: 'วันที่เริ่มต้น (UTC-5)',
  ieoClose: 'วันที่เสร็จสิ้น(UTC-5)',
  about: 'เกี่ยวกับ',
  ieoPlan: 'การกระจาย',
  ieoInfo: 'ข้อมูล',
  ieoContact: 'สื่อสังคม',
  ieoStart: 'การเริ่มต้น',
  ieoBuyStart: 'IEO เริ่ม',
  ieoBuyEnd: 'IEO เสร็จ',
  ieoIsTrade: 'ปล่อย',
  introduce: 'บทนำ',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO ประวัติศาสตร์',
  rechargeTip1: '1. นี่คือที่อยู่ใหม่ล่าสุดและไม่ซ้ำใครของคุณ แพลตฟอร์มจะไม่ขอให้คุณโอนเงินไปยังบัญชีอื่นหรือขอรหัสยืนยันของคุณ',
  rechargeTip2: '2. การฝากต้องมีบล็อกเครือข่ายอย่างน้อย 10 บล็อกเพื่อยืนยัน หลังจากการยืนยัน บัญชีของคุณจะได้รับเงินโดยอัตโนมัติ',
  withdrawTip1: '1. ตรวจสอบให้แน่ใจว่าคุณเลือกเครือข่ายเดียวกันสำหรับการถอนเงินนี้กับเครือข่ายด้านเงินฝาก และตรวจสอบว่าแพลตฟอร์มรับรองรับเครือข่าย มิฉะนั้นคุณจะสูญเสียทรัพย์สินของคุณ',
  withdrawTip2: '2.  การบล็อกเครือข่ายจะยืนยันการถอนของคุณหลังจากแพลตฟอร์มอนุมัติการสมัครของคุณ',
  withdrawTip3: '3. การถอนต้องมีบล็อกเครือข่ายอย่างน้อย 10 บล็อกเพื่อยืนยัน หลังจากการยืนยัน บัญชีของคุณจะได้รับเงินโดยอัตโนมัติ',
  retrievePwd: 'รีเซ็ตรหัสผ่าน',
  order: 'คำสั่งซื้อ', 
  registerTitle: 'ลงทะเบียนบัญชี', 
  emailCheck: 'รหัสยืนยันอีเมล', 
  haveAccount: 'มีบัญชีอยู่แล้ว？',  
  userInfo: 'ข้อมูลส่วนบุคคล', 
  userAuth: 'ตรวจสอบบัญชี', 
  footerTip: 'สิทธิ์ในการตีความขั้นสุดท้ายเป็นของแพลตฟอร์ม', 
  marketInfo: 'ตลาด', 
  viewMore: 'คลิกเพื่อค้นหาเพิ่มเติม', 
  tradeText: 'ซื้อขาย', 
  position: 'แฮนดิแคป', 
  level: 'ระดับ', 
  oneBuy: 'ซื้อ', 
  oneSell: 'ขาย', 
  newTrade: 'เรียลไทม์',
  tradePair: 'สัญลักษณ์', 
  historyEntrust: 'ประวัติการสั่งซื้อ', 
  allOrder: 'บันทึกทั้งหมด', 
  total: 'รวม', 
  oneOrder: 'คำสั่งซื้อ', 
  priceTip: 'ราคาอินพุต', 
  numberTip: 'ปริมาณอินพุต', 
  foundInput: 'จำนวนเงินที่ป้อน', 
  foundTip: 'กรุณากรอกจำนวนเงิน', 
  countdown: 'นับถอยหลัง', 
  tradeMoney: 'จำนวน', 
  walletOverview: 'ภาพรวม', 
  ieoBuyAccount: 'IEO บัญชี', 
  selectRechargeAddress: 'เลือกที่อยู่การฝากเงิน', 
  successText: 'ความสำเร็จ', 
  errorText: 'ล้มเหลว', 
  selectWithdrawAddress: 'ใส่ที่อยู่ถอน', 
  withdrawSuText: 'ส่งสำเร็จ', 
  transferMoneyLabel: 'จำนวน', 
  paramsError: 'ข้อผิดพลาดของพารามิเตอร์', 
  reset: 'รีเซ็ต', 
  selectText: 'ค้นหา', 
  emailManage: 'จัดการบัญชี', 
  loginPwdDes: 'แก้ไขรหัสผ่านเข้าสู่ระบบของคุณ', 
  updateText: 'แก้ไข', 
  financePwdDes: 'แก้ไขรหัสผ่านกองทุนของคุณ', 
  emailManageDes: 'รักษาความปลอดภัยของบัญชีของคุณ', 
  oldEmail: 'ที่อยู่อีเมลเดิม', 
  countryAreaTip: 'ประเทศหรือภูมิภาคที่ป้อน',  
  agreeTip: 'กรุณาตรวจสอบ "ข้อตกลงผู้ใช้"',
  volume24h: '24H Vol',
  // 首页
  currencyHomeType: 'สัญลักษณ์',
  whyUs: 'ทำไมต้องเป็นเรา?',
  why1: 'ปลอดภัยและประกัน',
  why1Des: 'เราเก็บ 80% cryptocurrencies ในกระเป๋าเงินเย็นที่ปลอดภัยของเรา ห้องเย็นมีความปลอดภัยและรับประกันโดยบัญชีแยกประเภท',
  why2: 'ธุรกรรมที่รวดเร็ว',
  why2Des: 'เราสามารถจัดการธุรกรรมนับล้านได้ โครงสร้างพื้นฐานระบบของเราสามารถขยายขนาดได้ภายในไม่กี่วินาทีเพื่อให้ตรงกับความต้องการที่เพิ่มขึ้น',
  why3: 'ใช้งานง่ายและสะดวก',
  why3Des: 'การซื้อขายบนแพลตฟอร์มของเราเป็นประสบการณ์ที่รวดเร็วมาก เราได้สร้างเทคโนโลยีที่ใช้โดยคนนับล้าน และประสบการณ์นั้นช่วยให้เราสร้างการแลกเปลี่ยนที่ทรงพลังนี้',
  why4: 'การซื้อขายทันที',
  why4Des: 'ซื้อขายทันที 150+ cryptocurrencies ในคลิกเดียว',
  why5: 'ราคาที่ดีที่สุด',
  why5Des: 'แลกเปลี่ยน cryptocurrencies ทั้งหมดที่มีสภาพคล่องสูง',
  why6: 'เชื่อมั่น',
  why6Des: 'ผู้ใช้มากกว่า 800,000 คนมีความเชื่อและซื้อขายหลายร้อยล้าน',
  homeGift: 'รับฟรี $30 เพื่อเทรดเพื่อรับโบนัสการลงทะเบียน',
  popular1: '$100,000 USD+',
  popular1Des: 'ปริมาณการซื้อขายทั้งหมด',
  popular2: '150+',
  popular2Des: 'รองรับสกุลเงิน',
  popular3: '800K+',
  popular3Des: 'ผู้ใช้ที่ได้รับการยืนยัน',
  globalText: 'ได้รับความไว้วางใจจากผู้ใช้กว่า 800,000 คนทั่วโลกสำหรับการซื้อขายสกุลเงินดิจิทัล',
  startText: 'เริ่มต้นในเวลาเพียง 5 นาที',
  start1: 'ลงชื่อ',
  start1Des: 'เปิดเว็บไซต์หรือดาวน์โหลดแอป แล้วลงทะเบียนโดยใช้อีเมล',
  start2: 'KYC',
  start2Des: 'เก็บหลักฐานประจำตัวของคุณให้สะดวก ป้อนรายละเอียด KYC พื้นฐาน และรับการยืนยันบัญชีของคุณ',
  start3: 'เงินฝาก',
  start3Des: 'ฝากเข้ากระเป๋าเงินดิจิตอลของคุณได้อย่างง่ายดาย',
  start4: 'ซื้อ/ขาย',
  start4Des: 'ซื้อ/ขายและแลกเปลี่ยน Cryptocurrencies ในราคาที่ดีที่สุด',
  appTitle: 'แอปพร้อมใช้งานแล้ว',
  createTeam: 'กองทุนสำรอง',
  angelFund: 'กองทุนเทวดา',
  ecology: 'สถาบันและระบบนิเวศ',
  presale: 'การเปิดตัวก่อนการขาย',
  mechanism: 'กลไกการขุด',
  trading: 'ซื้อขายแลกเปลี่ยน',

  issuePrice: 'ออกราคา',
  minBuyPrice: 'การสมัครสมาชิกขั้นต่ำ',
  buyNum: 'การสมัครสมาชิกต่อวัน',
  buyTotal: 'การสมัครสมาชิกรายวันทั้งหมด',
  Increment: 'การเพิ่มการสมัครรับข้อมูล',
  blockchain: 'บล็อกเชน',

  sellTotal: 'การออกทั้งหมด',
  currentBuy: 'การสมัครสมาชิกปัจจุบัน',
  dowload: 'ศูนย์ดาวน์โหลด',
  lessMinTip: 'ต้องไม่ต่ำกว่าจำนวนเงินขั้นต่ำ',
  appDes: 'รับการรักษาความปลอดภัย การซื้อขาย และความสามารถของแพลตฟอร์มแลกเปลี่ยนสกุลเงินดิจิทัลที่ได้รับการควบคุมที่ดีที่สุดในระดับเดียวกันโดยตรงบนสมาร์ทโฟนของคุณ',
  footerBottomTip: 'ข้อมูลที่ให้ไว้บนเว็บไซต์นี้ไม่ถือเป็นคำแนะนำในการลงทุน คำแนะนำทางการเงิน คำแนะนำในการซื้อขาย หรือคำแนะนำประเภทอื่นใด และคุณไม่ควรปฏิบัติต่อเนื้อหาใด ๆ ของเว็บไซต์ในลักษณะดังกล่าว การลงทุนสกุลเงินดิจิทัลมีความผันผวนและมีความเสี่ยงสูง อย่าลงทุนมากเกินกว่าที่คุณจะเสียได้',
  stakeAccount: 'บัญชีเดิมพัน',
  serviceAccount: 'บัญชีบริการ',
  lock: 'ล็อค',
  lockPrice: 'ล็อคราคา',
  ieoHistory: 'ประวัติ IEO',
  lockHistory: 'ล็อคประวัติ',
  sellHistory: 'ประวัติการขาย',
  stake: 'การปักหลัก',
  service: 'บริการ',
  ieoBuyTip: '*ประกาศ IEO: จำนวน LTJ มีจำกัด เราจะตรวจสอบแอปพลิเคชัน IEO ของผู้ใช้และจับฉลากแบบสุ่มทุกๆ 3 ชั่วโมง ผู้ใช้ที่มีจำนวนค่อนข้างมากจะมีความเป็นไปได้สูงที่จะซื้อ LTJ ผู้ใช้แต่ละคนสามารถซื้อได้สูงสุด 3 ครั้งต่อวัน',
  ieoLockTip: '*ล็อคราคา: ผู้ใช้จำเป็นต้องใช้ USDT เป็นสกุลเงินจำนำเพื่อล็อคราคาของ LTJ และอัตราส่วนจำนำคือ 30% ของมูลค่าของ LTJ หลังจากเปิดตัว LTJ ผู้ใช้สามารถขาย LTJ ในราคาที่ล็อคไว้ในขณะนั้น',
  ieoSellTip: '*การขาย IEO: การขาย LTJ ต้องการ 10% ของมูลค่า LTJ เป็นค่าบริการ หลังจากขาย LTJ แล้ว USDT ที่คุณแลกเปลี่ยนจะถูกยกเลิกการแช่แข็งใน 20 วัน และ USDT ที่จำนำไว้จะถูกยกเลิกการแช่แข็งใน 20 วัน',
  whitePaper: 'กระดาษสีขาว',
  cost: 'ค่าใช้จ่าย',
  currentEstimate: 'การประเมินมูลค่าปัจจุบัน',
  stakeNum: 'จำนวนเงินเดิมพัน',
  estimate: 'การประเมินค่า',
  consume: 'บริโภค',
  
  seedSell: 'รอบเมล็ด',
  privateSell: 'รอบส่วนตัว',
  openSell: 'KOL รอบ',
  ieoAward: 'รอบสาธารณะ',
  ieoGrowUp: 'ทีม',
  partner: 'ที่ปรึกษา',
  consultant: 'การตลาด',
  ieoName: 'สภาพคล่อง',
  conAward: 'AI ที่จะได้รับ',
  staking: 'การปักหลัก',

  release: 'ปล่อย',
  closedTime: 'เวลาปิด',
  remark: 'ข้อสังเกต',

  totalProfit: 'กำไรรวม',
  transferIn: 'โอนเข้า',
  transferOut: 'โอนออก',
  equity: 'รายได้สุทธิ',

  
  homeremind1: 'สกุลเงินดิจิตอลยอดนิยม',
  homeremind2: 'ดูตลาดเพิ่มเติม',
  homeremind3: 'ราคาล่าสุด(USD)',
  homeremind4: 'การเปลี่ยนแปลง 24 ชั่วโมง',
  homeremind5: 'การกระทำ',
  homeremind6: 'ดูเพิ่มเติม',
  homeremind7: "ตัวเลือกยอดนิยมของผู้ใช้",
  homeremind8: 'ปริมาณ 24 ชม',
  homeremind9: 'สินทรัพย์เสมือนชั้นนำ',
  homeremind10: 'ผลิตภัณฑ์หลักของเรา',
  homeremind11: 'มีเครื่องมือการซื้อขายที่หลากหลายให้เลือก',
  homeremind12: 'จุด',
  homeremind13: 'มี cryptos มากกว่า 700 รายการสำหรับการซื้อขาย',
  homeremind14: 'อัปเกรดประสบการณ์ของคุณด้วยการซื้อขายฟิวเจอร์ส',
  homeremind15: 'NYECoins Earn',
  homeremind16: 'APY ที่ดีที่สุดในอุตสาหกรรม',
  homeremind17: 'บริการที่หลากหลายของเรา',
  homeremind18: 'สำรวจความเป็นไปได้ไม่จำกัดด้วยบริการที่หลากหลายของเรา',
  homeremind19: 'การสมัครสมาชิก IEO',
  homeremind20: "สกุลเงินดิจิทัลก่อนการขายที่ดีที่สุด",
  homeremind21: 'บริการลูกค้าวีไอพี',
  homeremind22: '7*24 ชั่วโมง',
  homeremind23: 'ชุมชน',
  homeremind24: 'ค้นพบโอกาสความมั่งคั่งกับเราที่นี่',
  homeremind25: 'อยู่ในการซื้อขาย ทุกที่ทุกเวลา',
  homeremind26: 'ไม่พลาดทุกความเคลื่อนไหวด้วยแอพมือถือและเทอร์มินัลเว็บไซต์ของเรา',
  homeremind27: 'ติดตามเราบนโซเชียลมีเดีย',
  homeremind28: 'ติดตามข่าวสารล่าสุดของเรา',
  homeremind29: 'ซื้อขายได้ทุกที่ทุกเวลา',
  homeremind30: 'ลองใช้ NYECoins กับ iOS, Android หรือ API ของคุณ',

  msb: 'NYECoins ได้รับการควบคุมใน MSB, สหรัฐอเมริกา',
  msb1: 'เชื่อถือได้ ปลอดภัย รวดเร็ว',
  checkNow: 'ตรวจสอบตอนนี้',

  foundingTeam: 'ทีมผู้ก่อตั้ง',
  advisorTeam: 'ทีมที่ปรึกษา',
  community: 'ชุมชน',

  phone: 'หมายเลขโทรศัพท์',
  phoneTip: 'ใส่หมายเลขโทรศัพท์ของคุณ',


  rebateAccount: 'บัญชีคอมมิชชั่น',
  rebateType: 'พิมพ์',
  recommend: 'การอ้างอิง',
  myRebate: 'ค่าคอมมิชชันของฉัน',
  rebateRule: 'กฎของคอมมิชชั่น',
  yes: 'ใช่',
  no: 'เลขที่',
  authName: 'ตรวจสอบ',
  countryName: 'ประเทศ/พื้นที่',
  allCommission: 'ค่าคอมมิชชั่นทั้งหมด',
  myClient: 'ลูกค้าของฉัน',
  inviteUrl1: 'ลิงค์ผู้อ้างอิง',
  inviteCode1: 'รหัสอ้างอิง',
  commissioned: 'คณะกรรมการ',

  swiperTitle2: 'ผู้นำระดับโลกในการซื้อขายสกุลเงินดิจิตอลออนไลน์ ซื้อ ขาย และถือสกุลเงินดิจิทัล',
  swiperSub2: 'เราได้แนะนำผู้ใช้ทั่วโลกหลายล้านคนในการเดินทางสู่สินทรัพย์ดิจิทัล',

  homeremind31: 'พวกเราทำอะไร?',
  homeremind32: 'เราต้องการทำให้เส้นแบ่งระหว่างผู้ค้าสกุลเงินดิจิทัลและผู้บริโภคทั่วไปไม่ชัดเจน เราจะเผยแพร่สกุลเงินดิจิทัลโดยเปิดให้เข้าชมฟรี และช่วยให้ผู้คนเข้าใจและไว้วางใจสิ่งที่เราเชื่อว่าเป็นอนาคตของเงิน',
  homeremind33: 'คุณสมบัติของ NYECOINS',
  homeremind34: 'NYECOINS รวบรวมผู้ขายและผู้ซื้อโดยไม่มีธนาคารกลางหรือผู้ดูแลระบบเพียงคนเดียวเพื่อให้แน่ใจว่าข้อตกลงที่ชัดเจนและยุติธรรม',
  homeremind35: 'ลดต้นทุนการทำธุรกรรม',
  homeremind36: 'แพลตฟอร์มแบบกระจายอำนาจที่มีให้แก่ลูกค้าด้วยการชำระเงินธุรกรรมและค่าใช้จ่ายในการดำเนินงานที่ต่ำกว่า',
  homeremind37: 'ธุรกรรม C2C',
  homeremind38: 'การดำเนินงานของ C2C นั้นง่าย รวดเร็ว และปลอดภัยด้วย NYECOINS ส่ง BTC หรือสกุลเงินที่คุณเลือกให้กับลูกค้าบนแพลตฟอร์ม',
  homeremind39: 'ชุดเครื่องมือระดับพรีเมียมสำหรับแบรนด์',
  homeremind40: 'เราได้เพิ่มประสิทธิภาพการกำหนดเป้าหมายผู้บริโภคโดยมอบเครื่องมือและข้อมูลเชิงลึกแก่บริษัทต่างๆ เพื่อทำความรู้จักกับลูกค้าของตน',
  homeremind41: 'สนับสนุนโดยพันธมิตรระดับโลก',
  homeremind42: 'ทีม NYECOINS',
  homeremind43: 'เราขอแนะนำให้คุณรู้จักกับทีมที่จะทำให้ทุกอย่างเกิดขึ้น',
  homeremind44: 'Mark Nichols',
  homeremind45: 'ผู้ร่วมก่อตั้ง',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'วิศวกรซอฟต์แวร์อาวุโส',
  homeremind48: 'Richard Norman',
  homeremind49: 'ผู้จัดการชุมชน',
  homeremind50: 'Paul Garrett',
  homeremind51: 'นักออกแบบ UI/UX',


  ieoIntro1: 'สิ่งจูงใจด้านสภาพคล่อง',
  ieoIntro2: 'สำรองปักหลัก',
  ieoIntro3: 'พันธมิตรระบบนิเวศ',
  ieoIntro4: 'การขายสาธารณะ',
  ieoIntro5: 'การขายส่วนตัว A',
  ieoIntro6: 'เมล็ดพันธุ์รอบ',
  ieoIntro7: 'ทีมงาน LinkToJoin',
  ieoIntro8: 'สำรองค่าธรรมเนียมองค์กร',
}