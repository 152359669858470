import { createStore } from 'vuex'

import { marketList, getUserInfo, getUserAsset } from '@/apis'
import wssId from '@/util/wssId'
import BigNumber from 'bignumber.js'

export default createStore({
  state: {
    walletShow: true,
    token: '',
    lang: 'en',
    email: '',
    ws: null,
    heartTimer: null,
    wsConnect: false,
    marketKey: [],
    market: {},
    marketInfo: {},
    homeKey: [],
    clientAgent: 0, //判断是否为用户代理
    userId: 0,
    inviteUrl: '',
    inviteCode: '',
    commissionTotal: '0'
  },
  getters: {
  },
  mutations: {
    walletShowChange(state, show) {
      state.walletShow = show
    },
    langChange(state, lang) {
      state.lang = lang
    },
    tokenChange(state, token) {
      state.token = token
    },
    emailChange(state, email) {
      state.email = email
    },
    async wsInit(state) {
      if (state.heartTimer) clearInterval(state.heartTimer)
      try {
        if (state.ws) state.ws.close()
      } finally {
        //
      }
      // 获取所有币种
      const res = await marketList()
      if (res && res.code === 200 && res.data.length > 0) {
        const list = res.data.map(item => {
          const name = item.stock.coin_symbol + item.money.coin_symbol
          state.marketInfo[name] = {
            id: item.market_id, decimal: item.market_decimal, stock: item.stock.coin_symbol,
            name: `${item.stock.coin_symbol}/${item.money.coin_symbol}`, coin: item.money.coin_symbol,
            coinId: item.money.coin_id, icon: process.env.VUE_APP_BASE_API + item.stock.coin_icon, stockId: item.stock.coin_id
          }
          state.market[name] = { deal: '0', high: '0', last: '0', low: '0', open: '0', up: true, volume: '0', growth: '0.00' }
          return name
        })
        state.marketKey = list
        state.homeKey = list.slice(0, 5)
        const wsLoad = () => {
          state.ws = new WebSocket(process.env.VUE_APP_WSS)
          state.ws.onopen = () => {
            state.wsConnect = true
            state.ws.send(JSON.stringify({ id: wssId['today.subscribe'], method: 'today.subscribe', params: list }))
            state.heartTimer = setInterval(() => {
              if (state.wsConnect && state.ws) {
                state.ws.send(1)
              }
            }, 10000)
          }
          state.ws.onmessage = (res) => {
            const data = JSON.parse(res.data)
            if (data.params) {
              const last = Number(data.params[1].last)
              const open = Number(data.params[1].open)
              let growth = '0.00'
              let up = true
              if (last > 0) { 
                growth = (Math.ceil((Math.abs(last - open) / last) * 10000) / 100).toFixed(2)
                if (Number(growth) === 100) {
                  growth = 99.99
                }
              }
              if (open > last) {
                growth = `-${ growth }`
                up = false
              } else {
                growth = `+${ growth }`
              }
              data.params[1]['growth'] = growth
              data.params[1]['up'] = up
              state.market[data.params[0]] = data.params[1]
            }
          }
          state.ws.onclose = () => {
            clearInterval(state.heartTimer)
            state.heartTimer = null
            state.ws = null
            state.wsConnect = false
            setTimeout(() => {
              wsLoad()
            }, 5000)
          }
        }
        wsLoad()
      }
    },
    async getUser(state){
      const res = await getUserInfo()
      if (res.code === 200 && res.data) {
        state.clientAgent = res.data.user_client_agent
        if(state.clientAgent == 0)
        state.clientAgent = res.data.user_client_top_agent
        state.userId = res.data.user_id
        state.inviteCode = res.data.user_invite_code
        state.inviteUrl = `https://nyecoins.com/register?code=${state.inviteCode}`
      }

      //查询返佣账户
      if(state.clientAgent == 1){
        await getUserAsset({ id: state.userId, plate: 'commission' }).then(res => {
          if (res.code === 200 && res.data) {
            const findItem = res.data.find(item => item.coin.coin_symbol === 'USDT')
            if (findItem) {
              const assetTotal = findItem.asset ? Number(findItem.asset.asset_total).toFixed(5) : '0'
              const usd = findItem.coin ? findItem.coin.coin_usd : 0
              const allUsd = BigNumber(assetTotal).multipliedBy(usd).toFixed(5)
              state.commissionTotal = allUsd
            }
          }
        })
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
