import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71f3a7aa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "trade-header"
};
const _hoisted_2 = {
  class: "name"
};
const _hoisted_3 = {
  class: "last"
};
const _hoisted_4 = {
  style: {
    "font-size": "14px",
    "color": "#666"
  }
};
const _hoisted_5 = {
  class: "info-item"
};
const _hoisted_6 = {
  class: "label"
};
const _hoisted_7 = {
  class: "info-item"
};
const _hoisted_8 = {
  class: "label"
};
const _hoisted_9 = {
  class: "value"
};
const _hoisted_10 = {
  class: "info-item"
};
const _hoisted_11 = {
  class: "label"
};
const _hoisted_12 = {
  class: "value"
};
const _hoisted_13 = {
  class: "info-item"
};
const _hoisted_14 = {
  class: "label"
};
const _hoisted_15 = {
  class: "value"
};
import { useI18n } from 'vue-i18n';
export default {
  __name: 'TradeHeader',
  props: {
    name: {
      type: String,
      default: '--'
    },
    last: {
      tyle: String,
      default: '0'
    },
    high: {
      type: String,
      default: '0'
    },
    low: {
      type: String,
      default: '0'
    },
    vol: {
      type: String,
      default: '0'
    },
    up: {
      type: Boolean,
      default: false
    },
    growth: {
      type: String,
      default: '0.00'
    }
  },
  setup(__props) {
    const props = __props;
    const {
      t
    } = useI18n();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(props.name), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "last-num",
        style: _normalizeStyle({
          color: props.up ? '#04AC92' : '#EC2752'
        })
      }, _toDisplayString(props.last), 5), _createElementVNode("span", _hoisted_4, "$" + _toDisplayString(props.last), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('trend')), 1), _createElementVNode("div", {
        class: "value",
        style: _normalizeStyle({
          color: props.up ? '#04AC92' : '#EC2752'
        })
      }, _toDisplayString(props.growth) + "%", 5)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('high24H')), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(props.high), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(_unref(t)('low24H')), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(props.low), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(_unref(t)('num24H')), 1), _createElementVNode("div", _hoisted_15, _toDisplayString(props.vol), 1)])]);
    };
  }
};